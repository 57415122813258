.mega-menu {
    display: none;
}

//desktop
.mega-menu-open {
    .mega-menu {
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 999999;
        top: 0px;
        display: block;
        background: $background-white;
        padding: $body-padding 0;
        left: 0;
        
        .container-padding {
            display: flex;
            flex-flow: row wrap;
            max-height: calc(100vh - #{$body-padding});
            overflow-y: auto;
        
            ul {
                list-style: none;
                padding-left: 0;
                margin: 0;
            }
        }
        &__logo {
            width: 10%;
            a {   
                max-width: 70px;
                width: 100%;
                margin-top: 2px;
                display: block;
                padding-left: 0;
                img {
                    height: auto;
                    width: 100%;
                    display: block;
                }
            }
        }
        &__internal-links {
            width: 60%;
            display: flex;
            flex-flow: row wrap;
            li {
                &.active {
                   > a {
                        color: $text-black;
                        display: inline;
                        &:before {
                            content:"/\2192/";
                            display: inline;
                            margin-right: $line-margin-half;
                            font-family: "NewRailAlphabet-medium";
                            font-weight: normal;
                            opacity: 1;
                            font-size: $font-size-mega-menu;
                        }
                    }
                  
                }
                
            }
            > li {
                margin: 0 $column-margin calc(#{$line-margin} * 5);
                width: calc(33.333% - #{$column-gap});
                @media (max-width: 1345px) {
                    width: calc(50% - #{$column-gap});
                }
                a {
                    font-size: $font-size-mega-menu;
                    display: block;
                    font-family: $font-bold;
                    cursor: pointer;
                    line-height: 1;
                    @include mobiletablet {
                        font-size: $font-size-menu-mob;
                    }
             
               
                    &:hover {
                     color: $text-green;
                     &:before {
                         color: $text-green;
                     }
                    }
                 
                }

                & .mega-menu__header{
                    font-size: $font-size-mega-menu;          
                    color: $text-green !important;
                    font-family: $font-bold;
                    line-height: 1;
                }

                ul {
                    li {
                        margin: $line-margin 0;
                    }
                }
            }
        }
        &__secondary-links {
            width: calc(30% - 80px);
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;
            padding-right: 80px;
            @media (max-width: 1345px) {
                flex-flow: column;
                justify-content: flex-start;
            }

            a {
                font-size: $font-size-menu;
                display: inline-block;
                &.active {
                    font-family: $font-bold;
                }
            }
        }
        &__secondary-column {
  
            &:first-child {
                @media (min-width: 1345px) {
                    margin: 0 calc(#{$column-margin} * 3) 0 0 !important;
                }
        
            }
            ul {
                margin: 0 0 calc(#{$line-margin} * 3) !important;
            }
        }
        &__sublinks {
            padding-left: $column-margin !important;
            li {
                a {
                    font-size: $font-size-menu;
                }
                &.active {
                
                    a {
                        display: inline;
                        color: $text-green;
                    }
                    &:before {
                        content:"/\2192/";
                        display: inline;
                        margin-right: $line-margin-half;
                        font-family: "NewRailAlphabet-medium";
                        font-weight: normal;
                        opacity: 1;
                        color: $text-green;
                    }
                }
            }
        }
    
    }

}

//mobile
.menu-open {
    .mega-menu { 
        margin-top: calc(#{$line-margin} * 2);
        z-index: $header-z-index+2;
        &__logo {
            display: none;
        }
        &__internal-links {
            list-style: none;
            padding-left: 0;
            margin: 0;
            font-family: $font-bold;
     
           ul {
               list-style: none;
           }
                      
           li {
            font-size: $font-size-menu-mob;
                
                > ul {
                    padding-left: 0;
                }
                &.active {
                    color: $text-black;
                        >  a {
                            display: inline;
                            color: $text-black;
                            &:before {
                                content:"/\2192/";
                                display: inline;
                                margin-right: $line-margin-half;
                                font-family: "NewRailAlphabet-medium";
                                font-weight: normal;
                                opacity: 1;
                            }
                        }
                        
                }
            } 
            > li {                
                margin: calc(#{$line-margin} * 2) 0;

                & .mega-menu__header{     
                    color: $text-green;
                }

                > a {
                    color: $text-green !important;
                }
               
            } 
        }
        &__secondary-links {
            ul {
                list-style: none;
                padding-left: 0;
            }
            @include mobiletablet {
                border-top: 1px solid $border-black;
            }
        }
    }
}