.select-organization {
    @include desktop {
        margin-top: 120px;
        margin-bottom: calc(#{$line-margin} * 10);
      }

      &__row {
        top: calc(#{$line-margin} * 8);
        margin-top: 200px;
        @include mobiletablet {
          top: $line-margin;
          margin-top: calc(#{$line-margin} * 5);
        }
      }
      &__column {
        padding-top: $line-margin;
        padding-bottom: $line-margin;
        position: relative;
    
        &--1 {
          display: flex;
          flex-flow: row;
          &:after {
              content: "";
              width: calc(100% - #{$column-gap});
              left: $column-margin;
              display: block;
              border-top: 1px solid $border-black;
              position: absolute;
              @include mobiletablet {
                  display: none;
              }
          }

          @include mobiletablet {
              flex-flow: row wrap;
          }
   
        }
        &--2 {
            @include mobiletablet {
                display: none;
            }
        }
        &--4 {
            @include mobiletablet {
                padding-top: 0;
            }
        }
      }

      &__label {
        width: calc(50% - #{$column-margin});
        margin: 0 $column-margin 0 0;
        @include mobiletablet {
            width: 100%;
            margin: 0;
        }
        label {
            display: block;
            padding: $line-margin 0; 
        }
      }
      .form-group.select{
        border-bottom: 0;
        width: calc(50% - #{$column-margin});
        margin: 0 0 0 $column-margin;
        @include mobiletablet {
            width: 100%;
            margin: 0;
        }
        select {
            font-family: "NewRailAlphabet-light";
        }
      }

/*organization selection*/
.nav{
  padding: 0;
  margin: 0;
}
.nav li {
  list-style: none;
  margin-bottom: 5px;
}

.nav ul {
  white-space: nowrap;
  z-index: 1;
  left: 0;
  list-style: none;
  padding: 10px;
  margin:0;
  border: 1px solid #000;
  li{
    position: relative;
    

    ul{
      display: none;
      padding-top:5px;
      padding-bottom:5px;
      border: none;
    }

    &:hover{
      ul {
        display: block;
      }
    }
  }
}


.nav > li > ul {
  display:none;
}

.nav > li:hover > ul {
    display:block;
}

.form-group.select.organization-selector:after {
  //content: "⯆";
  position: absolute;
  right: 0;
  font-size: 10px;
  bottom: unset;
  top:15px;
  pointer-events: none;
}

.tapwell-autocomplete
{
  .autocomplete__box
  {
    border: none;
    border-bottom: 1px solid black;
    border-radius:0;
    padding: 5px 0;
  }

  .autocomplete__results
  {
    margin:2px 0;
    border:1px solid black;
    
    .autocomplete__results__item{
      font-size: 14px;
    }
  }

  .autocomplete--clear{
    position: absolute; 
    right:-2px;
    top:1px;
  }
}
}

