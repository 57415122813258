.product {
  margin: calc(#{$line-margin} * 2) 0 calc(#{$line-margin} * 8);
  @include mobiletablet {
    margin: $line-margin 0 calc(#{$line-margin} * 8);
  }
  .banner {
    @include mobiletablet {
      padding-right: 40px;
      position: relative;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  .sticky {
    display: flex;
    flex-flow: column;
    @include desktop {
      position: sticky;
      bottom: $column-gap;
      width: calc(33.333% - #{$column-gap}) !important;
      max-width: 545px;
    }
  }
  &__top-section {
    align-items: flex-end;

    &--rightTall {
      position: relative;
      .product {
        &__img {
          overflow: hidden;
          max-height: calc(130vh - #{$header-height} - #{$body-padding} - 100px);
          max-height: none;
          height: auto;

          @include mobiletablet {
            max-height: calc(80vh) !important;
            min-height: 350px;
            height: 85vh;
            img {
              height: 100%;
            }
          }

          > div {
            display: flex;
            justify-content: flex-end;

            @include mobiletablet {
              justify-content: center;
              display: flex;
              justify-content: center;
              box-sizing: border-box;
              height: 100%;
              width: 100%;
            }
          }
        }       
      }

      img {
        display: block;
        max-width: 70%;
        min-height: 100%;
        height: auto;
      }
    }
  }

  &__img {
    padding-left: calc(12.4% + #{$column-margin});
    height: 100vh;
    @include mobiletablet {
      padding: 0;
      margin-left: -$column-margin;
      margin-right: -$column-margin;
      width: calc(100% + (#{$column-margin} * 2));
      flex-basis: auto;
      max-width: none;
      height: calc(100vh - #{$line-margin} - #{$line-margin} - 175px - 40px);
    }

    @include desktop {
      margin-top: $line-margin;
      max-height: 720px;
    }
    > div {
      width: calc(100% - (68px * 2));
      height: calc(100% - (68px * 2));
      padding: 68px;
      background-color: $background-light-grey;
      position: relative;
      @include mobiletablet {
        padding: $column-gap;
        width: calc(100% - (#{$column-gap} * 2));
        height: calc(100% - (#{$column-gap} * 2));
      }
      > div {
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 100%;
        width: auto;
        z-index: 0;
        pointer-events: none;
        &.center {
          background-position: center;
        }
        &.right {
          background-position: right;
        }
      }
    }
    &__placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
    }
  }

  &__description-container {
    display: flex;
    flex-flow: row wrap;
    border-top: 1px solid $border-black;
    padding: $line-margin 0 calc(#{$line-margin} * 2);
    @include mobiletablet {
      padding: $line-margin 0;
    }
  }
  &__description {
    width: calc(62% - #{$column-margin});
    margin-right: $column-margin;

    @include mobiletablet {
      width: 100%;
      margin-right: 0;
      padding-bottom: $line-margin;
    }

    p {
      margin: 0;
      @include mobiletablet {
        font-size: $font-size-default-mob;
      }
    }
  }
  &__description-text {
    &::v-deep p {
      margin: 0;
      font-size: $font-size-default !important;
      font-family: "NewRailAlphabet-light" !important;
    }
    &::v-deep span {
      margin: 0;
      font-size: $font-size-default !important;
      font-family: "NewRailAlphabet-light" !important;
    }
    &::v-deep a {
      margin: 0;
      font-size: $font-size-default !important;
      font-family: "NewRailAlphabet-light" !important;
      color: $text-green !important;
    }
    &::v-deep div {
      width: 100% !important;
    }
  }
  &__back {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: 0;
    a {
      font-family: $font-icon;
      font-size: $font-size-heading-mob;
    }
  }
  &__art-nr {
    font-family: $font-bold;
    @include mobiletablet {
      font-size: $font-size-default-mob;
    }
  }
  &__documents {
    width: calc(28% - #{$column-margin});
    margin-left: $column-margin;
    @include mobiletablet {
      width: 100%;
      margin-left: 0;
      border-top: 1px solid $border-black;
      padding-top: $line-margin;
    }
    ul {
      margin: 0 0 $line-margin;
      li {
        line-height: $line-height;
        display: none;
        &:nth-of-type(-n + 3) {
          display: block;
        }

        a {
          cursor: pointer;
          white-space: nowrap;
          @include mobiletablet {
            font-size: $font-size-default-mob;
          }
        }
      }
      &.show-all {
        li {
          display: block;
        }
      }
    }
  }
  &__documents-showmore {
    cursor: pointer;
    line-height: 1.4;
    white-space: nowrap;
    position: relative;

    &:after {
      content: "\2192";
      display: block;
      position: absolute;
      right: -25px;
      top: -3px;
      font-size: 16px;
      font-family: $font-icon;
    }
  }

  &__stock {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-end;
    width: calc(62% - #{$line-margin-half});
    margin-right: $line-margin-half;
    padding: $line-margin 0;
    border-top: 1px solid $border-black;
    @include mobiletablet {
      width: 100%;
      margin-right: 0;
    }
  }
  &__stock-status {
    width: calc(100% - 20px);
    padding-right: 0;
    p {
      margin: 0;
      @include mobiletablet {
        font-size: $font-size-default-mob;
      }
    }
  }
  &__stockstatus-icon {
    width: 20px;
    padding-top: $line-margin;
    @include mobiletablet {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      padding-top: 0;
    }

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 50%;
      background-color: $text-red;
      @include mobiletablet {
        margin-top: $line-margin;
      }
    }
    &--true {
      &:before {
        background-color: $text-green;
      }
    }
  }
  &__calculatedbox-label {
    display: block;
    color:$text-grey;
    padding-left: $line-margin;
    @include mobiletablet {
      border-top: 0;
    }
  }

 &__box-label {
    padding-bottom: $line-margin;
    display: block;
    line-height: $line-height;
    color: $text-grey;

    @include mobiletablet {
      border-top: 0;
    }
  }
  &__amount {
    width: calc(62% - #{$line-margin-half});
    margin-right: $line-margin-half;
    padding: $line-margin 0;
    border-top: 1px solid $border-black;
    @include mobiletablet {
      width: 100%;
      margin-right: 0;
    }
  }
  &__price {
    width: calc(62% - #{$line-margin-half});
    margin-right: $line-margin-half;
    padding: $line-margin 0 0;
    border-top: 1px solid $border-black;
    text-align: right;
    @include mobiletablet {
      width: 100%;
      margin-right: 0;
    }

    p {
      margin: 0;
      @include mobiletablet {
        text-align: right;
        font-size: $font-size-default-mob;
      }
    }
  }
  &__clear {
    font-family: $font-bold;
  }
  &__buy-btn {
    margin-top: calc(#{$line-margin} * 3);
    @include mobiletablet {
      margin-top: calc(#{$line-margin} * 2);
    }
  }

  &__info {
    font-size: $font-size-menu;
    margin-bottom: calc(#{$line-margin} * 2);
    @include mobiletablet {
      font-size: $font-size-menu-mob;
      margin: $line-margin 0 $line-margin;
    }

    h2 {
      margin: 0;
      font-size: $font-size-menu;
      @include mobiletablet {
        font-size: $font-size-menu-mob;
      }
    }
    ul {
      margin: 0 0 $line-margin-half;
      @include mobiletablet {
        margin: 0 0 $line-margin;
      }
    }
  }
}
