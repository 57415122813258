@import "~@/styles/constants.scss";


























































@import "./scss/mixins";

::v-deep * {
    --color-green: #38d430;
    // --color-gray: #828282;
    --color-gray: #d2d0d0;
    --color-light-gray: #ededed;
    --color-black: #000000;
    --color-white: #ffffff;
    --font-family-bold: "NewRailAlphabet-bold";
    --font-family-medium: "NewRailAlphabet-medium";

    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.image-bank {
    max-width: 1600px;
    margin: auto;
    &__loader {
        &-container {
            position: fixed;
            width: 100%;
            height: 100vh;
            z-index: 999;
            display: flex;
            align-items: center;
            background-color: rgb(255 255 255 / 75%);
            top: 0;
            left: 0;
        }
    }
    &__title-wrapper {
        margin-bottom: 10px;
        word-break: break-word;
        z-index: 5;

        @include desktop {
            position: absolute;
            top: -90px;
            left: calc(12.4% + 30px);
        }
    }
    &__title {
        font-size: 31px;
        color: var(--color-green);
        margin: 0;
        font-family: var(--font-family-bold);
        line-height: 1;

        @include desktop {
            font-size: 45px;
        }
    }

    &__images {
        @include tabletdesktop {
            display: grid;
            grid-gap: 35px 30px;
            grid-template-columns: repeat(8, 1fr);
        }
    }

    &__result {
        margin-bottom: 10px;
    }

    &__selectAllImages {
        cursor: pointer;
        display: flex;
    }
    &__selectAllImages-text {
        height: 60px;
        line-height: 45px;
        margin-right: 10px;
    }
}
