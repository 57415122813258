// Breakpoints
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
    @media (min-width: 0px) and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

// @mixin tablet {
//     @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
//         @content;
//     }
// }
// @mixin mobiletablet {
//     @media (min-width: 0) and (max-width: #{$desktop-width - 1px}) {
//         @content;
//     }
// }

@mixin tabletdesktop {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin custom-scrollbar {
    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0px;
        border-radius: 0px;
        background: black;
        border: #fff solid;
        border-width: 3px 6px;
    }
}

@mixin icon--trashcan {
    content: url("../../assets/originalIcons/trashcan.svg");
}

@mixin icon--hide {
    content: url("../../assets/originalIcons/hide.svg");
}

@mixin icon--show {
    content: url("../../assets/originalIcons/show.svg");
}

@mixin icon--bricmate {
    content: url("../../assets/originalIcons/bricmate_orange_logo.svg");
}

@mixin icon--haven {
    content: url("../../assets/originalIcons/haven_logo.svg");
}

@mixin icon--tapwell {
    content: url("../../assets/originalIcons/tapwell_logo.svg");
}