.checkout {

  > div {
    &:last-child {
      margin-bottom: calc(#{$line-margin} * 10);
      clear: both;
      @include mobiletablet {
        margin-bottom: calc(#{$line-margin} * 4);
      }
    }
  }
  .banner {
    margin-bottom: calc(#{$line-margin} * 13);
    margin-top: -90px;

    @include mobiletablet {
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
    }

    &__text {
      position: relative;
      top: 0;
   
      h1 {
        height: $font-size-heading;
        @include mobiletablet {
          padding-right: 35px;
          height: $font-size-heading-mob;
          margin-left: $column-margin;
        }
      }
      @include desktop {
        left: auto;
        * {
          margin-left: calc(-35% + 15px) !important;
        }
       
      }
      @include mobiletablet {
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        max-width: 470px;
      }
    }
  }

  &__list {
    top: calc(#{$line-margin} * 8);
    @include desktop {
      margin-top: 120px;
    } 

    @include mobiletablet {
      top: $line-margin;
      margin-top: calc(#{$line-margin} * 5);
    }
    > div {

      &:nth-child(2) {

        .row {
          .checkout {
            &__variant {
              &:first-child {
                &:before {
                  border-top: 1px solid $border-black;
                  width: 100%;
                  @include mobiletablet {
                    border-top: 0;
                  }
                }
                h3 {
                  font-size: $font-size-default;
                }
              }
              &--info {
                @include mobiletablet {
                  display: block;
                }
              }
              
            }
          }
        }
      }
      &:last-of-type {
        .row {
          .checkout {

            &__variant {
              position: relative;
                &:after {
                  content:"";
                  border-bottom: 1px solid $border-black;
                  width: calc(100%);
                  left: 0;
                  bottom: 0;
                  position: absolute;
                  @include mobiletablet {
                    border-bottom: 0;
                  }
              }
              &:first-child {
                &:after {
                  width: calc(50% - #{$column-margin});
                  left: auto;
                  right: 0;
                }
              }
              &:last-child {
                &:after {
                  width: calc(100% - #{$column-margin});
                }
              }
            }
          }
        }
      }
    }
    &__header {
      h3 {
        margin: 0;
        font-size: $font-size-default;
        font-family: "NewRailAlphabet-light";
        font-weight: normal;
      }
      .checkout {
        &__variant {
          &:before {
            display: none;
          }
        }
      }
    }
  }
 
  &__variant {
    padding-top: $line-margin;
    padding-bottom: $line-margin;
    position: relative;
    &-description {
      li {
        &.hide-desktop {
          @include mobiletablet {
            display: flex;
            flex-flow: row wrap;
          }
          ul {
            padding-left: 5px;
            list-style: none;
            span {
              font-family: "NewRailAlphabet-light";
              &.strike {
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }

    &-calculatedbox-label {
      font-family: 'NewRailAlphabet-light'!important;
      display: inline !important;
      line-height: $line-height;
      color:$text-grey;
      padding-left: $line-margin;
      @include mobiletablet {
        border-top: 0;
       
      }
    }
  
   &-box-label {
      font-family: 'NewRailAlphabet-light'!important;
      padding-top: $line-margin;
      display: block;
      line-height: $line-height;
      color: $text-grey;
  
      @include mobiletablet {
        border-top: 0;
      }
    }

    p {
      margin: 0;
      font-family: "NewRailAlphabet-light";
      display: flex;
      flex-flow: row;   
      
    }

    &--info {
      display: flex;
      flex-flow: row;
      @include mobiletablet {
        display: none;
      }
    }
    &--about {
      @include mobiletablet {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        > div {
          &:first-child {
            margin-right: $column-margin;
            width: calc(50% - #{$column-margin});
          }
        }
      }
    }
    &--stock {
      @include mobiletablet {
      &:before {
        border-top: 0 !important;
      }
      .checkout__stock {
        &--zero {
          flex-flow: wrap;
        }
 
      }
    }

  }
  &--a-price {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    > * {
      width: 28%;
    }
    span {
      &.strike {
        text-decoration: line-through;
      }
     
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        white-space: nowrap;
      }
    }
  }

    
    &--price {
      text-align: right;
      p {
        font-size: $font-size-menu;
        font-family: $font-bold;
        justify-content: flex-end;
        @include mobiletablet {
          font-size: $font-size-menu-mob;
          text-align: right;
        }
      }
      @include mobiletablet {
        padding-bottom: calc(#{$line-margin} * 2);
      }
    }
    &:before {
      content: "";
      border-top: 1px solid $border-black;
      display: block;
      width: 100%;
      left: $column-margin;
      top: 0;
      position: absolute;
      @include mobiletablet {
        width: calc(100% - #{$column-gap}) !important;
        margin-bottom: $column-margin;
      }
    }
    &:first-child {
      &:before {
        width: 50%;
        left: auto;
        right: -15px;
        @include mobiletablet {
          right: 15px;
          width: calc(100% - #{$column-gap}) !important;
        }
      }
    }
    &:last-child {
      &:before {
        width: calc(100% - #{$column-gap});
        @include mobiletablet {
          border-top: 0;
        }
      }
    }
  }
  &__img-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }

  &__info {
    width: calc(50% + #{$column-margin});
    margin: 0 $column-margin 0 0;
    @include mobiletablet {
      width: calc(60% - #{$column-margin});
    }
    h3 {
      font-family: "NewRailAlphabet-light";
      margin: 0;
      font-weight: normal;
      font-size: 0;
    }
  }
  &__variant-name {
    margin: 0;
    font-size: $font-size-default;
    font-family: $font-bold;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    p {
      font-weight: normal;
    }
    @include mobiletablet {
      font-size: $font-size-default-mob;
      flex-flow: column;
    }
  }
  &__img {
    background-color:  $background-light-grey;
    width: calc(50% - #{$column-margin});
    height: 150px;
    margin-right: 0;
    margin-left: 15px;
    padding: 5px 1em;
    @include mobile {
      width: calc(40% - #{$column-margin});
      height: 124px;
      display: block;
    }
    @include tablet {
      width: calc(20% - #{$column-margin});
      height: 124px;
      display: block;
    }
    > div {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 100%;
      width: auto;
      

    }
  }
  &__stock {
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    border-bottom: 1px solid $border-black;
    padding: $line-margin 0;
    @include mobiletablet {
      border-bottom: 0;
      padding: 0;
      flex-flow: row;
      justify-content: space-between;
    }
    &--zero {
      flex-flow: column;
    }
  }
  &__stock-text {
    width: 100%;
    @include mobiletablet {
      width: 90%;
      padding-right: 15px;
    }
  }
  &__status-icon {
    position: absolute;
    top: calc(-#{$line-margin} * 3);
    right: 0;
    @include mobiletablet {
      display: flex;
      justify-content: flex-end;
      border: 0;
      padding-top: 0;
      top: 0;
    }

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 50%;
      background-color: $text-red;
    }
    &--true {
      position: relative;
      top: 0;
      &:before {
        background-color: $text-green;
      }
    }
  }
  &__stock-zero {
    color: $text-red;
    width: 100%;
    @include mobiletablet {
      margin-right: 20px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    .radio-container {
      line-height: $line-height;
      display: block;
      position: relative;
      padding-left: $column-gap;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
   
      input:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark:after {
        content: "X";
        top: 1px;
        left: 8px;
        width: 8px;
        height: 8px;
      }

    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      padding: 0px 0px 0px calc(#{$column-margin} + 4px);
      &:before {
        content: "[";
        position: absolute;
        left: 0;
      }
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }

  &__edit {
    padding: $line-margin 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    @include mobiletablet {
      padding: 0;
      width: 100%;
    }
  }
  &__change {
    cursor: pointer;
    position: absolute;
    left: 0;
    i {
      font-family: $font-icon;
      font-style: normal;
    }
  }
  &__remove {
    position: relative;
    cursor: pointer;
    margin-top: -$line-margin-half;
    .line {
      background-color: $border-black;
      height: 1px;
      width: 15px;
      display: block;
      margin: 5px 0;
      &:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
      }
      &:nth-child(2) {
        transform: translateY(-6px) rotate(-45deg);
        margin-top: 11px;
      }
    }
  }
  &__amount-picker {
    display: flex;
    flex-flow: column;
    p {
      width: 100%;
    }
  }

  &__total {
    padding-left: 0;
    list-style: none;
    padding: $line-margin 0;
    margin: 0;
    @include mobiletablet {
      border-top: 1px solid $border-black;

    }
    li {
      ul {
        padding-left: 0;
        margin: 0;
        list-style: none;
        @include desktop {
          margin-left: 35%;
          width: calc(100% - 35%);

        }
        li {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          &.bold {
            margin-top: $line-margin;
            font-size: $font-size-menu;
            @include mobiletablet {
              font-size: $font-size-menu-mob;
        
            }
          }
          .fade {
            opacity: 0.5;
          }
        }

      }
      &.col-md-6 {
        
        @include desktop {
          padding-right: 0;
        }
        @include mobiletablet {
          padding: 0;
        }
      }

    }
    span{
      &.strike {
        text-decoration: line-through;
        margin-right: 10px;
      }
    }
  }

  &__variant-description {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding-bottom: $line-margin-half;

    li {
      margin: 0 $line-margin-half 0 0;
      line-height: $line-height;
      width: 100%;
      &.active {
        font-family: $font-bold;
      }
      a {
        white-space: nowrap;
        cursor: pointer;
      }
      span {
        font-family: $font-bold;
      }
    }

    .checkout__amount{
      input[type=text]{
        border:none;
        max-width: 40px;
        text-align: center;
        border:1px solid $border-black;
        font-family: $font-bold;
        line-height: 1.2;
        outline: none;
        padding: 2px 0;
      }
    }
  }
  &__confirmation-list {
      > div:nth-child(2) .row .checkout__variant--info {
        @include mobiletablet {
          display: block;
        }
        &:first-child {
          &:before {
            width: 100%;
          }
        }
   
        &:before {
          @include mobiletablet {
            display: none;
          }
       
        }
        h3 {
          font-size: $font-size-default;
        }
    }
    .checkout__status-icon {
      top: 0;
    }
    .checkout__stock {
      border: 0;
    }
    > div:last-of-type .row .checkout__variant:after {
      content: "";
      border-bottom: 1px solid $border-black;
      width: 100%;
      left: 0;
      bottom: 0;
      position: absolute;
      @include mobiletablet {
        display: none;
      }
    }
     > div:last-of-type .row .checkout__variant:last-child:after {
      width: calc(100% - #{$column-margin});
     }
     > div:last-of-type .row .checkout__variant:first-child:after {
      width: calc(50% - #{$column-margin});
      left: auto;
      right: 0;
  }
  }
  .delivery__column--3 {
    border-top: 1px solid $border-black;
    @include mobiletablet {
      border-top: 0;
    }
  }
  .delivery .form-group span {
    color: $text-black;
  }

  &__voucher{
    display: flex;
    flex-direction: row-reverse;

    .form-group{
      margin-bottom: 5px;
      label{
        span{
          color: #38d430;
        }
      }
      span{
        color: red;
      }
    }
  }

  .excel-import{
    flex-direction: row-reverse;

    .select-excel{
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include mobiletablet {
        flex-direction: column;
      }

      .imported-filename{
        margin-right: 10px;
        font-family: $font-bold;

        @include mobiletablet {
          margin-right: 0;
          width: 100%;
        }

      }

      .btn{
        box-sizing: border-box;
      }
    }

    .download-template{
      margin-top: 15px;
      text-align: right;
      cursor: pointer;
    }
  }
}
