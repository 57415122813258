@import "~@/styles/constants.scss";


































































.image-tile {
    $self: &;
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 88px;
    column-gap: 20px;
    grid-template-areas:
        "image image"
        "name name"
        "download-low select"
        "download-high select";

    &__figure {
        background: var(--color-light-gray);
        grid-area: image;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1 / 1;
        aspect-ratio: 1;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
        transition: ease all 0.3s;

        //To avoid image jump when adding border
        &.selected:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            pointer-events: none;
            border: 1px solid var(--color-green);
        }

        &:hover {
            background: var(--color-gray);
            #{$self}__enlarge-icon {
                display: block;
            }
        }
    }

    &__enlarge-icon {
        display: none;
        position: absolute;
        color: #fff;
        bottom: 22px;
        right: 22px;
        width: 70px;
        height: 70px;
    }

    &__image {
        max-height: 100%;
        position: absolute;
    }

    &__hgroup {
        grid-area: name;
        padding-bottom: 10px;
        border-bottom: solid 1px #000;
    }

    &__title {
        font-family: var(--font-family-bold);
        font-size: 14px;
    }

    &__horizontal-list {
        display: inline-block;
    }
    
    &__subtitle {
        font-size: 12px;
    }

    &__space{
        margin-right: 5px;
    }

    &__download--low,
    &__download--high {
        font-family: var(--font-family-bold);
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__download--low {
        grid-area: download-low;
        border-bottom: 1px solid #000;
    }

    &__download--high {
        grid-area: download-high;
    }

    &__image-size {
        font-family: "NewRailAlphabet";
    }

    &__checkbox {
        grid-area: select;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-left: 1px solid var(--color-black);
        margin: 20px auto;
    }
}

svg {
    height: 14px;
    margin-left: 10px;
    margin-top: 1px;
    width: 14px;
}
