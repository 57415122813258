@import "~@/styles/constants.scss";


















.select {
    &__label {
        font-weight: 700;
    }
}

#select {
    margin: 10px 0;
    position: relative;
    width: 100%;
    height: 2.3125rem;

    #selected {
        border-bottom: 1px solid var(--color-black);
        cursor: pointer;
        user-select: none;

        &::after {
            border: 5px solid transparent;
            border-color: var(--color-black) transparent transparent transparent;
            content: "";
            height: 0;
            position: absolute;
            right: 1em;
            width: 0;
        }
    }

    #items {
        background: var(--color-white);
        border: 1px solid var(--color-black);
        color: var(--color-black);
        left: 0;
        margin-top: 4px;
        overflow: hidden;
        position: absolute;
        right: 0;
        z-index: 1;

        div {
            color: var(--color-black);
            cursor: pointer;
            padding: 5px 10px;
            user-select: none;

            &:hover {
                background-color: var(--color-light-gray);
            }
        }
    }
}
