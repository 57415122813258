.delivery {
  @include desktop {
    margin-top: 120px;
    margin-bottom: calc(#{$line-margin} * 10);
  }
  .spinner {
    position: fixed !important;
  }

  > div {
    &:last-child {
      margin-bottom: calc(#{$line-margin} * 10);
    }
  }
  &__column {
    &--2 {
      @include mobiletablet {
      h5 {
        font-family: "NewRailAlphabet-light";
        font-size: $font-size-default-mob;
        font-weight: normal;
        margin: 0;
        padding-bottom: $line-margin;
      }
    }
    }
  }

  &__list {
    top: calc(#{$line-margin} * 8);
    @include mobiletablet {
      top: $line-margin;
      margin-top: calc(#{$line-margin} * 3);

     
    }
    &--submit {
      @include mobiletablet {
      margin-top: 0;
      }
      p {
        color: $text-red;
      }
    }
    > div {
      &:first-child {
        .row {
          .delivery {
            &__column {
              &:first-child {
                h3 {
                  font-size: $font-size-default;
                  margin: 0;
                  font-family: "NewRailAlphabet-light";
                  font-weight: normal;
                }
              }
              &--1 {
                @include mobiletablet {
                  display: block;
                  order: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  &__column {
    padding-top: $line-margin;
    padding-bottom: $line-margin;
    position: relative;
    p {
      font-family: "NewRailAlphabet-light";
    }

    &--1 {
      display: flex;
      flex-flow: row;
      max-width: calc(12% + 10px);
      @include mobiletablet {
        display: none !important;
      }
    }
    &--2 {
      
      > div {
        position: relative;
      }
      @include mobiletablet {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        order: 3;
        > div {
          &:first-child {
            margin-right: $column-margin;
            width: calc(50% - #{$column-margin});
            @include mobiletablet {
              width: 100%;
              margin: 0;
            }
          }
        }
        .delivery {
          &__content-1 {
           
          }
        }
      }
      .form-group {
        &:first-of-type {
          padding-top: 0;
          @include mobiletablet {
            padding-top: $line-margin;
          }
        }
        &:last-child {
        }
  
        label {
          span {
            color: $text-green;
            &.checkmark {
              color: #000;
            }
          }
        }
      }
 
    }
    &--3{
      max-width: 60.7%;
      flex-basis: unset;
      min-width: 60.7%;
      padding-left: 30%;
      &:after {
        width: 100% !important;
        left: 0 !important;
      }
      h5 {
        width: 100%;
      }
      @include mobiletablet {
        order: 2;
        padding-top: 0;
        padding-bottom: 0;
        &:before {
          border-top: 0 !important;
        }
      }
    }

    &--4 {
      padding-top: 0;
      @include mobiletablet {
        order: 4;
      }
      .btn {
        &:disabled, &[disabled] {
            opacity: 0.2;
            cursor: default;
        }
      }
    }
    &--alternative {
      margin-left: 29%;
      @include mobiletablet {
        margin-left: 0;
      }
    }
    &--unifaun {
      padding-left: 0;
      display: flex;
      justify-content: space-between;
      padding-right: 0;
      flex-flow: row;
      max-width: 61.8%;
      min-width: 61.8%;
      margin-top: 0;
      &.delivery__column--3 {
        padding-left: 50px;
      }
      @include mobiletablet {
        max-width: 100%;
        min-width: 100%;
        padding-right: 17px;
        order: 1;
        flex-flow: column;
        margin-left: 0;
        padding-left: $column-margin;
      }
      h5 {
        width: auto;
        @include mobiletablet {
          padding-bottom: $line-margin;
          border-bottom: 1px solid $border-black;
        }
      
      }
      > div {
        width: 100%;
        margin-top: -8px;
      }
    }

    &:first-child {
      &:before {
        width: 50%;
        left: auto;
        right: -15px;
        @include mobiletablet {
          right: 15px;
          width: calc(100% - #{$column-gap}) !important;
        }
      }
    }
    &:last-child {
      &:before {
        width: calc(100% - #{$column-gap});
        @include mobiletablet {
          border-top: 0;
        }
      }
    }
  }
  &__row {
    &--2 {
      h5 {
        @include mobiletablet {
          font-size: $font-size-default-mob;
        }
      }
      .delivery {
        &__content-1 {
          font-family: $font-bold !important;

       /*   &:after {
            content: "";
            width: 50%;
            border-top: 1px solid $border-black;
            display: block;
            position: absolute;
            top: calc(-#{$line-margin} - 1px);
          } */
        }
        &__column {
          padding-top: 0;

          &--1 {
           &:before {
            content: "";
            border-top: 1px solid black;
            width: calc(50% - 0px);
            margin-top: -11px;
            right: -15px;
            position: absolute;
            @include mobiletablet {
              display: none;
            }
           }
          }
              
        }
      }
      .form-group {
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  &__required-message {
    margin: $line-margin 0;
    font-family: $font-bold !important;
    color: $text-green;
    @include mobiletablet {
      margin: 0;
      text-align: right;
    }
  }
  &__btn {
    margin-top: -$line-margin;
  }
  &__img-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  &__content-1 {
    width: 100%;
    margin: 0 0 calc(#{$line-margin * 2});
    @include mobiletablet {
      width: 100%;
      margin: 0;
      padding: $line-margin 0 $line-margin;
    }
    &.disabled {
      opacity: 0.5;
    }
    &--times {
      width: 100%;
      @include mobiletablet {
        padding: 0;
      }
      table {
        border: 0;
        width: 100% !important;
        td {
          border: 0;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  &__label {
    width: calc(50% - #{$column-margin});
    margin: 0 $column-margin 0 0;
    @include mobiletablet {
      width: 100%;
      border-bottom: 1px solid $border-black;
      padding-bottom: $line-margin;
    }
    h3 {
      font-family: "NewRailAlphabet-light";
      margin: 0;
      font-weight: normal;
      font-size: 0;
    }
  }
  &__list-heading {
    margin-top: calc(#{$line-margin} * 6);
    .delivery {
      &__column {
        &--2 {
          padding-right: 0;
        }

        &:before {
          display: none;
        }
        &:after {
          content: "";
          width: calc(100% - #{$column-margin});
          border-top: 1px solid $border-black;
          display: block;
          left: $column-margin;
          bottom: -1px;
          position: absolute;
        }
        &--1 {
          &:after {
            width: 100%;
          }
        }
      }
      &__column-label {
        &--1 {
          font-family: $font-bold;
          font-size: $font-size-default;
        }
        &--2 {
          margin-left: - 55px;
        }
      }
    }
  }
  &__column-label {
    &--1 {
      width: calc(50% - #{$column-margin});
      margin: 0;
      @include mobiletablet {
        width: 100%;
        margin: 0;
        font-family:"NewRailAlphabet-light";
        font-weight: normal;
        display: block;
        padding-top: $line-margin;
        padding-bottom: $line-margin;
        border-bottom: 1px solid $border-black;
        font-size: $font-size-default-mob;
      }
    }
    &--2 {
      margin: 0;
      font-size: $font-size-default;
      font-family: $font-bold;
      @include mobiletablet {
        font-size: $font-size-default-mob;
        padding-bottom: $line-margin;
        font-family:"NewRailAlphabet-light";
        font-weight: normal;
      }
    }
  }
  &__alternativeaddress {
    @include mobiletablet { 
      border-top: 0;
    }
  }
  .form-group {
    &.last {
      padding: $line-margin 0 calc(#{$line-margin} * 2);  
    }
    span {
      color: $text-red;
    }
    &.date {
      @include mobiletablet {
        border-bottom: 0;
      }
    }
  }
  #placeOrderBtn {
    max-width: 300px;
    margin-right: -10px;
    margin-top: 50px;
    @include mobiletablet {
      max-width: 100%;
      margin-right: 0;
    }
  }
  .vdp-datepicker {
    input {
      cursor: pointer;
    }
    
      &__calendar {
        border: 1px solid $border-black;
        header {
          font-family: $font-bold;
          color: $text-green;
          .prev:not(.disabled), .next:not(.disabled), .up:not(.disabled), span {
            font-family: $font-bold;
            color: $text-green;
          }
          .prev:not(.disabled):hover, .next:not(.disabled):hover, .up:not(.disabled):hover {
            background: transparent;
          }
          .next {
            text-indent: inherit;
            font-size: 0;
              &:after {
                content: "\2192";
                font-family: "NewRailAlphabet-medium" !important;
                font-weight: normal !important;
                border: 0;
                font-size: 16px;
                color: $text-black;
                margin-top: -2px;
              }
              &.disabled {
                &:after {
                 border: 0;
                 opacity: 0.3;
                }
              }
          }
        
          .prev {
            text-indent: inherit;
            font-size: 0;
              &:after {
                content: "\2190";
                font-family: "NewRailAlphabet-medium" !important;
                font-weight: normal !important;
                border: 0;
                font-size: 16px;
                color: $text-black;
                margin-top: -2px;
              }
              &.disabled {
                &:after {
                 border: 0;
                 opacity: 0.3;
                }
              }
          }
        } 
        
        .cell{
          color:  $text-black;
          &:not(.blank):not(.disabled).day:hover, &:not(.blank):not(.disabled).month:hover, &:not(.blank):not(.disabled).year:hover{
            border: 1px solid rgba($border-black, 0.3);
          }
          
          &.disabled {
            opacity: 0.3;
          }
          &.selected {
            background: white;
            border: 1px solid $border-black;
            font-family: $font-bold;
            color: $text-green;
            &:hover {
              background: white;
              border: 1px solid $border-black;
            }
          }
        
        }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 20px;
      width: 18px;
      background-image: url("~@/assets/tapwell/img/datepicker.png");
      background-repeat: no-repeat;
      background-size: contain;
      pointer-events: none;
      display: none;
    }
  }
  .unifaun-container {
    
    .unifaun-checkout-disabled {
      visibility: hidden;
      transition: ease-in 1s;
    }

    padding: 0;
    border-bottom:0;
    @include desktop {
      width: 100%;
    }
    @include mobiletablet {
      border-top: 0;
    }
    .unifaun-checkout-text-input.unifaun-checkout-invalid .unifaun-checkout-text-input-label-message, .unifaun-checkout-text-input.unifaun-checkout-invalid .unifaun-checkout-list-input-label-message {
      background: transparent;
      color: $text-red;
      font-size: $font-size-default;
      border: 0;
      left: 0;
      left: -5px;
      margin-bottom: -80px;
    }
    .unifaun-checkout-text-input-label {
      padding-left: 0;
      top: 15px;
    }
    .unifaun-checkout-active {
      .unifaun-checkout-text-input-label {
        top: 15px;
        height: auto;
        margin-top: -0.8em;
      }
 

    }
    .unifaun-checkout-text-input-label-message {
      border-bottom: 1px solid $border-black;
      border-radius: 0;
    }
    .unifaun-checkout-disabled .unifaun-checkout-option0, .unifaun-checkout-disabled .unifaun-checkout-option0.unifaun-checkout-selected0, .unifaun-checkout-disabled .unifaun-checkout-option1, .unifaun-checkout-disabled .unifaun-checkout-option1.unifaun-checkout-selected1 {
      color: $text-black;
     
      @include desktop {
        padding-left: 50px;
      }
    }
    .unifaun-checkout-option-fields-panel .unifaun-checkout-addon-description {
      cursor: pointer;
      padding: 10px;
      text-align: center;
      border: 1px solid $border-black;
      display: block;
      width: 200px;
    }
    .unifaun-checkout-addon-error {
      background: transparent;
      color: $text-red !important;
    }
    .unifaun-checkout-active .unifaun-checkout-text-input-label {
      margin-bottom: 10px;
      font-size: $font-size-default;
      font-family: $font-bold;
    }
    .unifaun-checkout-option0 {
      border: 0;
      position: relative;
      cursor: pointer;
      &:before {
        content: "[";
        position: absolute;
        left: 20px;
        top: $line-margin;
        font-size: $font-size-default;
        font-family: $font-bold;
        @include mobiletablet {
          left: auto;
          right: 21px;
        }
      }
      &:after {
        content: "]";
        position: absolute;
        left: 38px;
        top: $line-margin;
        font-size: $font-size-default;
        font-family: $font-bold;
        @include mobiletablet {
          left: auto;
          right: 0;
        }
      }
      &.unifaun-checkout-selected0 {
        .unifaun-checkout-option-header0 {
          &:before {
            content: "X";
            position: absolute;
            left: 27px;
            top: $line-margin;
            font-size: $font-size-default;
            font-family: $font-bold;
            @include mobiletablet {
              left: auto;
              right: 9px;
            }
          }
        }
      }
    }
    .unifaun-checkout-option-column1 {
      @include mobiletablet {
        width: 100%;
      }
    }
    .unifaun-checkout-option-columns {
      @include mobiletablet {
        flex-flow: column;
        width: 100%;
      }
    }
    .unifaun-checkout-option-header0 {
      border: 0;
      padding: 0;
      @include mobiletablet {
        flex-flow: column;
      }
    }
    .unifaun-checkout-option0, .unifaun-checkout-option1 {
      background: transparent;
      padding: 0;
      margin: 0;
      border: 0;  
      border-radius: 0;   
      @include desktop {
        padding-left: 50px;
      }
     
    
    } 
    .unifaun-checkout-option-header0 {
      &:after {
        content: "";
        border-bottom: 1px solid $border-black;
        position: absolute;
        width: 100%;
        width: calc(100% - 50px);
        bottom: 0;
        @include mobiletablet {
          width: 100%;
        }
      }
    }

    select {
      height:33px;
      margin-top: - $line-margin-half;
      font-family: $font-bold;
      font-size: $font-size-default;

      &:disabled {
        color: $text-black;
      }
    }
    unifaun-checkout-invalid > label[id*='eb7d28cf-59db-4a9b-ba1f-83cbbdb60ad0$sms$Lbl']:before {
      content: "|";
      margin-top: 18px;
      margin-left: -1px;
      display: block;
      position: absolute;
  }
    .unifaun-checkout-text-input {
      padding: 0;
      border: 0;
      background: transparent;
      margin: 10px 0 30px 0;
      width: 100%;
    }
    .unifaun-checkout-option-agents-wrapper {
      margin-top: 0;
      position: relative;
      &:after {
        content: "\2BC6";
        position: absolute;
        right: -20px;
        font-size: 10px;
        bottom: 15px;
        @include mobiletablet {
          right: 0;
        }

      }
    }
    span {
      color: $text-black;
    }
    .unifaun-checkout-option-radio input {
      @include mobiletablet {
        display: inline;
        margin: 0;
      }
    }
    .unifaun-checkout-option0.unifaun-checkout-selected0 .unifaun-checkout-option-sub-options {
      border-color: $border-black;
      border-bottom: 0;
      border-top: 0;
      background: transparent;
      padding: 0;
      margin: 0;
      @include mobiletablet {
        padding-top: 0;
      }
      }
    .unifaun-checkout-option-radio {
      width: 0;
    }
    .unifaun-checkout-option-no-icons-spacer {
      width: 0;
    }
    .unifaun-checkout-option0 .unifaun-checkout-option-agent-panel {
      background: transparent;
      padding: 0;
    }
    .unifaun-checkout-option-sub-column1, .unifaun-checkout-option-sub-column2 {
      flex: none;
    }
    .unifaun-checkout-option-agent-info-onoff-link {
    margin: 0 0 $line-margin;
    display: block;
    }
    .unifaun-checkout-option-agent-info {
      border: 0;
      padding: 0;
      margin: 0;
    }
    .unifaun-checkout-option0 .unifaun-checkout-option-sub-columns {
      padding: 0;
      display: flex;
      flex-flow: column;
      padding-bottom: 10px;
      .unifaun-checkout-option-sub-column1 {
        order: 2;
        width: 100%;
      }
      .unifaun-checkout-option-sub-column2 {
        order: 1;
      }
    }
    .unifaun-checkout-text-input-input {
      border-bottom: 1px solid $border-black;
      padding: $line-margin 0;
      margin-top: $line-margin;
      background: transparent;
      width: 220px;
      margin-bottom: 50px;
    }
    .unifaun-checkout-option-fields-panel .unifaun-checkout-addon-description {
      position: relative;
      cursor: pointer;

    }
  
    .unifaun-checkout-option-fields-panel {
      padding: 0 !important;
    }
    .unifaun-checkout-addon-label {
      font-family: "NewRailAlphabet-light";
      span {
      color: $text-black;
      display: block;
      padding: 5px 0 0;
      }
    }
    .unifaun-checkout-option-column1-text label {
      padding: $line-margin 0 0;
      display: block;
      pointer-events: none;
      cursor: pointer;
    }
    .unifaun-checkout-option0 .unifaun-checkout-option-price {
      margin-top: - $line-margin-half;

      @include mobiletablet {
        margin-top: 0;
        margin-bottom: $line-margin;
        width: 100%;
      }
    }
    .unifaun-checkout-option-column1, .unifaun-checkout-option-column2 {
      @include mobiletablet {
        width: 100%;
      }
    }
    .unifaun-checkout-option-column2 {
      padding-left: $column-gap;
      @include mobiletablet {
        padding-left: 0;
      }
    }

  }
}
