.autocomplete {
	position: relative;
	font-size: larger;
    &__box{
        border: none !important;
    }

}
div.autocomplete__box
{
    border: none !important;
}
.tapwell-autocomplete
{
    border: none !important;
    .autocomplete__box{
    border: none !important;
    }
}
