.product-page {
    margin-bottom: calc(#{$line-margin} * 12) !important;
    &__periodic-variants {
        position: absolute;
        z-index: 999;
        bottom: 0;
        right: 0;
        list-style: none;
        display: grid;
        grid-template-rows: 55px 55px 55px 55px;
        grid-template-columns: 55px 55px 55px 55px 55px;
        grid-auto-columns: 55px;
        grid-auto-rows: 55px;
        grid-auto-flow: dense;
        direction: rtl;
        text-align: left;

        li {
            padding: 8px;
            color: $text-green;
            font-size: 14px;
            font-weight: 700;
            box-sizing: border-box;
            grid-row: 3;
            cursor: pointer;
            transition: all 200ms ease;
            outline: 2px solid $text-green;
            margin-top: 2px;
            margin-left: 2px;
            position: relative;

            @include mobiletablet {
                &:not(.selected-large) {
                    display: none;
                }
            }
            &:hover {
                z-index: 1;
            }
            &:nth-child(1) {
                grid-row: 4;
            }
            &:nth-child(2) {
                grid-row: 4;
            }
            &:nth-child(3) {
                grid-row: 5;
            }
            &:nth-child(4) {
                grid-row: 6;
            }
            &:nth-child(5) {
                grid-row: 3;
            }
            &:nth-child(6) {
                grid-row: 6;
            }
            &:nth-child(7) {
                grid-row: 2;
            }
            &:nth-child(8) {
                grid-row: 1;
            }
            &:nth-child(9) {
                grid-row: 6;
            }
            &:nth-child(10) {
                grid-row: 5;
            }
            &:nth-child(11) {
                grid-row: 3;
            }
            &.active {
                overflow: hidden;
                transition-duration: 0.5s;
                transition-property: color, background-color;
                animation-name: pulse;
                animation-duration: 1s;
                animation-delay: 0.5s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                background-color: $text-green;
                color: white;

                // &:after {
                //     content: "";
                //     position: absolute;
                //     display: block;
                //     background: #5dda56;
                //     height: 10px;
                //     width: 10px;
                //     bottom: 0;
                //     right: 0;
                //     overflow: hidden;
                //     transition-duration: 0.5s;
                //     transition-property: color, background-color;
                //     animation-name: pulse;
                //     animation-duration: 1s;
                //     animation-delay: 0.5s;
                //     animation-timing-function: linear;
                //     animation-iteration-count: infinite;
                //     background-color: $text-green;
                // }

                @keyframes pulse {
                    50% {
                        background-color: rgba(56, 212, 48, 0.75);
                    }
                }
            }

            &.selected-large {
                cursor: default;
                font-size: 30px;
                grid-row: -1 / span 2;
                grid-column: 1 / span 2;
                // color: white;
                // background-color: $text-green;
            }
        }
    }

    &__banner {
        height: calc(100vh - #{$header-height} - 260px);
        min-height: 500px;
        max-height: 1300px;
        background-color: $background-light-grey;
        margin: calc(#{$line-margin} * 2) 0 calc(#{$line-margin} * 1);
        padding: calc(#{$line-margin} * 6) 0 calc(#{$line-margin} * 2);
        display: flex;
        flex-flow: row wrap;
        position: relative;

        @include mobile {
            height: 100%;
            padding: $line-margin-half 0;
        }
        @include tablet{
            height: unset;
        }

        &--flex {
            flex: 1 1 65%;
        }
        &__img {
            height: 100%;
            float: right;
            width: calc(40% - 80px);
            padding-right: 80px;
            position: relative;            
            @include mobiletablet {
                order: 1;
                width: 100%;
                padding-right: 0;
                height: 250px;
                margin-bottom: $line-margin;
            }
            > .product-image {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                height: 100%;
                width: auto;
                z-index: 0;
                pointer-events: none;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 1;
            }

            & .shower-configure__icon{
                
                top: 0;
                z-index: 2;
                
                pointer-events: unset;
                width: 29px;
                height: 29px;
                right: 20px;
                top: 10px;
                
                @include tablet{
                    width: 35px;
                    height: 35px;
                    right: 30px;
                    top: -15px;
                    
                }

                @include desktop{
                    right: 44px;
                    height: 43px;
                    width: 43px;            
                }

                &--configure{
                    
                    background-size: 29px;

                    @include tablet{
                        background-size: 35px;
                    }

                    @include desktop{
                        background-size: 43px;                        
                    }
                }
            }
        }
        &__toniton-blue{
            background-color: $background-toniton-blue;
        }
        &__toniton-yellow{
            background-color: $background-toniton-yellow;
        }
        &__toniton-creme{
            background-color: $background-toniton-creme;
        }
        &__toniton-peach{
            background-color: $background-toniton-peach;
        }
        &__toniton-black{
            background-color: $background-toniton-black;
        }
        &__toniton-green{
            background-color: $background-toniton-green;
        }
        &__description {
            &::v-deep p {
                margin: 0;
                font-size: $font-size-default !important;
                font-family: "NewRailAlphabet-light" !important;
            }
            &::v-deep span {
                margin: 0;
                font-size: $font-size-default !important;
                font-family: "NewRailAlphabet-light" !important;
            }
            &::v-deep a {
                margin: 0;
                font-size: $font-size-default !important;
                font-family: "NewRailAlphabet-light" !important;
                color: $text-green !important;
            }
            &::v-deep div {
                width: 100% !important;
            }
        }
        &__description-extra {
            margin-top: 10px;
        }
        &__about {
            width: calc(60% - 12.4% -  #{$column-margin});
            padding-left: calc(12.1% + #{$column-margin});
            display: flex;
            align-items: flex-end;
            @include mobiletablet {
                width: 100%;
                order: 3;
                padding-left: $column-margin;
                position: relative;
                padding-top: 85px;

                & .product-inspirational-images {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            @include tablet{
                padding-top: 70px;
            }

            & .product-inspirational-images {
                z-index: $header-z-index - 2;
                
                &.product-inspirational-images--open{
                    z-index: $header-z-index + 1;
                }
            }
        }
        &__info {
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            z-index: 1;
        }
        h1 {
            font-size: $font-size-heading;
            font-family: $font-bold;
            margin: 0;
            span {
                font-family: "NewRailAlphabet-medium";
            }
            @include mobile {
                font-size: $font-size-heading-mob;
            }
        }
        &__container {
            border-top: 1px solid $border-black;
            display: flex;
            flex-flow: row;
            width: 100%;
            @include mobile {
                flex-flow: row wrap;
            }
            p {
                margin: 0;
            }
        }
        &__column {
            padding: $line-margin 0;
            &--1 {
                width: 65%;
                @include mobile {
                    width: 100%;
                }
            }
            &--2 {
                width: 35%;
                @include mobile {
                    width: 100%;
                    border-top: 1px solid $border-black;
                }
            }
            .discount-price {
                p { color:rgb(238, 3, 3); }
            }
        }
        &__variants {
            border-top: 1px solid $border-black;
            padding: 10px 0;
            margin-top: 14px;
        }
        &__documents {
            list-style: none;
            padding-left: $column-margin;
            display: flex;
            flex-flow: wrap;
            margin: 0 0 $line-margin;
            @include mobile {
                padding-left: 0;
            }
            li {
                width: 100%;
                line-height: $line-height;
                display: none;
                &:nth-of-type(-n + 3) {
                    display: block;
                }
            }
            a {
                cursor: pointer;
            }
            &.show-all {
                li {
                    display: block;
                }
            }
        }
        &__documents-showmore {
            cursor: pointer;
            line-height: 1.4;
            white-space: nowrap;
            position: relative;
            margin-left: $column-margin;
            @include mobile {
                margin-left: 0;
            }

            &:after {
                content: "\2192";
                display: block;
                position: absolute;
                right: -25px;
                top: -3px;
                font-size: 16px;
                font-family: $font-icon;
            }
        }
        &__social {
            @include mobile {
                border-top: 0;
                padding: 0;
            }
        }
        &__social-media {
            list-style: none;
            padding-left: $column-margin;
            display: flex;
            flex-flow: row wrap;
            margin-top: 0;
            @include mobile {
                padding-left: 0;
            }
            li {
                margin-right: $line-margin;
                width: 30px;
            }
            a {
                cursor: pointer;

                &.pintrest {
                    width: 30px;
                    height: 30px;
                    display: block;
                    background: url("~@/assets/tapwell/img/pintrest_transp_smaller.svg") no-repeat left top;
                }

                &.youtube {
                    width: 37px;
                    height: 36px;
                    display: block;
                    background: url("~@/assets/tapwell/img/youtube_transp_smaller.svg") no-repeat left top;
                    background-size: cover;
                    margin-top: -4px;
                }

                img,
                svg {
                    max-height: 30px;
                    width: 100%;
                }
            }
        }
        &__add {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: baseline;
            margin: calc(#{$line-margin} * 2) 0;
            @media (max-width: 1275px) {
                flex-flow: column;
            }

            .btn {
                width: auto;
                background: transparent;
                margin-bottom: $line-margin;
                margin-right: $column-margin;
                margin-left: $column-margin;
                margin-top: $line-margin;
                @media (max-width: 1275px) {
                    order: 2;
                    width: 100%;
                    margin: 0;
                }
                &:hover {
                    background: $background-black;
                }
            }

            .msg{
                color: red;
            }
        }

        &-config{
            display: flex;
            flex-direction: column;

            @include desktop{
                flex-direction: row;
            }
            
            & button:first-of-type{                    
                margin-bottom: 11px;
                @include desktop{
                        margin-bottom: 0;
                        margin-right: 5px;
                    }

                }

            & .btn__config-variant{
                @include btn-default($background-black, $text-white);
                position: relative;
                
                &-text{
                    padding-right: 5px;
                    &:after{
                        content: url(~@/assets/tapwell/img/config.svg);
                        height: 18px;
                        width: 18px;                        
                        display: block;
                        position: absolute;
                        right: 16px;
                        top: 50%;
                        transform: translateY(-50%);             
                    }
                }
            }

            & .btn__config-variant{
                    text-align: left;
            }

            & .btn__config-fetch-pdf{
                @include btn-default($background-light-grey, $text-black);
                text-align: left;

                &--saved-variant{
                    background-color: $text-green;
                    border-color: $text-green;
                    color: $text-white;
                }
            }

        }

    }
    &__description {
        margin-top: $line-margin;
        &__text {
            margin-left: calc(12.1% - #{$column-margin});
            margin-bottom: 10px;
            padding-left: $column-gap;

            @include mobiletablet {
                margin-left: 0;
                width: 100%;
                max-width: 500px;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: $line-margin;
                word-break: break-word;
            }
            &::v-deep {
                h1 {
                    color: $text-green;
                    &.green {
                        color: $text-green;
                    }
                    &.black {
                        color: $text-black;
                    }
                }
                h1,
                h2 {
                    margin: 0;
                    font-size: $font-size-heading;
                    font-family: $font-bold;
                    line-height: 1;
                    @include mobiletablet {
                        font-size: $font-size-heading-mob;
                    }
                }
                h3,
                h4,
                h5,
                h6 {
                    margin: 0;
                    line-height: 1;
                    font-family: $font-bold;
                }
                p {
                    line-height: 1.2;
                    margin: 0;
                    font-size: $font-size-menu;
                    @include mobiletablet {
                        font-size: $font-size-menu-mob;
                    }
                }
                a {
                    color: $text-green;
                    text-decoration: none;
                }
                .font-icon {
                    font-family: $font-icon !important;
                    font-weight: normal !important;
                }
                .font-light {
                    font-family: "NewRailAlphabet-light" !important;
                    font-weight: normal !important;
                }

                .arrow-right:after {
                    content: "\2192";
                    font-family: "NewRailAlphabet-medium" !important;
                    font-weight: normal !important;
                }

                .arrow-left:before {
                    content: "\2190";
                    font-family: "NewRailAlphabet-medium" !important;
                    font-weight: normal !important;
                }
                .white {
                    color: white !important;
                }
                .black {
                    color: black !important;
                }
            }
        }
    }

     &__amount-picker {
        margin-top: 10px;
     }

    &__related-products {
        padding-left: calc(12.1% + #{$column-margin});

        @include mobiletablet {
            margin: 0 0 calc(#{$line-margin} * 8);
            padding-left: $column-margin;
        }

        &__heading {
            @include mobiletablet {
                word-break: break-all;
            }
            h3 {
                font-size: $font-size-heading;
                color: $text-black;
                font-family: $font-bold;
                margin: 0;
                line-height: 1;
                margin-bottom: calc(#{$line-margin} * 4);
                @include mobiletablet {
                    font-size: $font-size-heading-mob;
                }
            }
        }
        &__list {
            justify-content: flex-start;
        }
    }

    &__product-list {
        margin-top: calc(#{$line-margin} * 1);
        border-top: 1px solid $border-black;
        padding-top: $column-gap;

        > .row {
            grid-gap: calc(#{$column-gap} + 5px) $column-gap;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-auto-rows: minmax(315px, auto);
            margin-left: 0;
            margin-right: 0;
            @include mobiletablet {
                grid-template-columns: repeat(1, 1fr);
            }
            @include tablet {
                grid-template-columns: repeat(2, 1fr);
            }
            @include mobile {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: calc(#{$column-gap} + 5px) 0;
            }
        }
        @include mobile {
            margin-top: calc(#{$line-margin} * 2);
        }

        &__column {
            padding-top: $column-margin;
            padding-bottom: $column-margin;

            grid-column: auto/span 2;
            padding-bottom: 0;
            padding-top: 0;
            position: relative;
            ul {
                list-style: none;
                padding: 0;
                
                &.fits {
                    margin: 0;

                    li {
                        &:after {
                            font-family: "NewRailAlphabet-light";
                            content: "|";
                            margin-left: $line-margin-half;
                            font-weight: normal;
                            font-size: $font-size-default;
                          }
                          &:last-of-type {
                            &:after {
                              content: "";
                            }
                          }
                    }
                }
            }
            > div {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            & .product-tile-shower-configure__icon{
                width: 25px;
                height: 25px;
                right: 15px;
                top: 15px;

                @include desktop{
                    right: 21px;
                    top: 21px;
                }

                &--configure{
                    background-size: 25px;
                }
            }
            .selected-label {
                position: absolute;
                top: 5px;
                right: 5px;
                border: 1px solid $background-black;
                color:$text-white;
                background-color: $background-black;
                padding: 3px 16px;
            }
            .select-label {
                position: absolute;
                top: 5px;
                right: 5px;
                border: 1px solid $background-black;
                padding: 3px 16px;
            }
            &.Content {
                grid-row: auto;
                grid-column: auto/span 2;
                @include tablet {
                    grid-row: auto/1 span;
                    grid-column: auto/span 2;
                }
                @include mobile {
                    grid-row: auto/1 span;
                    grid-column: auto/span 2;
                }
                > div {
                    height: 100%;
                }
                .product-page {
                    &__product {
                        &__img {
                            height: 100%;
                            position: relative;
                            padding: 0;
                            @include mobiletablet {
                            }
                            div {
                                background-size: cover;
                                min-height: 100%;
                                @include mobiletablet {
                                    min-height: auto;
                                }
                            }
                            &__content {
                                &--mobile {
                                    @include desktop {
                                        display: none;
                                    }
                                }
                            }
                            &__regular {
                                display: none;
                            }
                        }
                        &__heading {
                            top: 0;
                            left: 0;
                            position: absolute;
                            margin: $column-gap;
                            display: block;
                            height: calc(100% - (#{$column-gap} * 2));
                            @include mobiletablet {
                                margin: $line-margin;
                                height: calc(100% - (#{$line-margin} * 2));
                            }
                            &::v-deep {
                                h1 {
                                    color: $text-green;
                                    &.green {
                                        color: $text-green;
                                    }
                                    &.black {
                                        color: $text-black;
                                    }
                                }
                                h1,
                                h2 {
                                    margin: 0;
                                    font-size: $font-size-heading;
                                    font-family: $font-bold;
                                    line-height: 1;
                                    @include mobiletablet {
                                        font-size: $font-size-heading-mob;
                                    }
                                }
                                h3,
                                h4,
                                h5,
                                h6 {
                                    margin: 0;
                                    line-height: 1;
                                    font-family: $font-bold;
                                }
                                p {
                                    line-height: 1;
                                    margin: 0;
                                }
                                a {
                                    color: $text-green;
                                    text-decoration: none;
                                }
                                .font-icon {
                                    font-family: $font-icon !important;
                                    font-weight: normal !important;
                                }
                                .font-light {
                                    font-family: "NewRailAlphabet-light" !important;
                                    font-weight: normal !important;
                                }

                                .arrow-right:after {
                                    content: "\2192";
                                    font-family: "NewRailAlphabet-medium" !important;
                                    font-weight: normal !important;
                                }

                                .arrow-left:before {
                                    content: "\2190";
                                    font-family: "NewRailAlphabet-medium" !important;
                                    font-weight: normal !important;
                                }
                                .white {
                                    color: white !important;
                                }
                                .black {
                                    color: black !important;
                                }
                            }
                        }
                        &__product-info {
                            display: none;
                        }
                    }
                }
            }
            &.Wide {
                grid-row: auto;
                grid-column: auto/span 4;
                @include tablet {
                    grid-row: auto/1 span;
                    grid-column: auto/span 2;
                }
                @include mobile {
                    grid-row: auto/1 span;
                    grid-column: auto/span 2;
                }
            }
            &.Highlight {
                grid-row: auto/2 span;
                grid-column: auto/span 4;
                @include tablet {
                    grid-row: auto/1 span;
                    grid-column: auto/span 2;
                }
                @include mobile {
                    grid-row: auto/1 span;
                    grid-column: auto/span 2;
                }
                > div {
                    height: 100%;
                    display: flex;
                    flex-flow: column;
                    .product-page__product__img {
                        height: 100%;
                        @include mobiletablet {
                        }
                        div {
                            @include mobiletablet {
                                min-height: auto;
                            }
                        }
                        &--highlight {
                            height: 100%;
                            position: relative;
                            padding: 0;
                            @include mobiletablet {
                            }
                            div {
                                background-size: cover;
                                @include mobiletablet {
                                    min-height: auto;
                                }
                            }
                            .product-page__product__img__regular {
                                display: none;
                            }
                        }
                        &__highlight {
                            &--mobile {
                                @include desktop {
                                    display: none;
                                }
                            }
                        }
                    }
                    .product-page__product__product-info {
                        height: auto;
                    }
                }
            }
            &.Tall {
                grid-row: auto/2 span;
                grid-column: auto/span 2;
                @include tablet {
                    grid-row: auto/1 span;
                }
                @include mobile {
                    grid-row: auto/1 span;
                }
                > div {
                    height: 100%;
                    display: flex;
                    flex-flow: column;
                    .product-page__product__img {
                        height: 100%;
                        @include mobiletablet {
                        }
                        div {
                            @include mobiletablet {
                                min-height: auto;
                            }
                        }
                    }
                    .product-page__product__product-info {
                        height: auto;
                    }
                }
            }
        }
    }
    &__sibling-series-category__list {
        margin-left: calc(12.1% - #{$column-margin});
        margin-top: 20px;
        padding-left: $column-gap;

        @include mobiletablet {
            width: 100%;
            order: 2;
            margin-left: $column-margin;
            padding-left: 0;
        }

        ul {
            padding: 0;
            list-style-type: none;

            > li {
                display: inline-block;

                @include mobiletablet {
                    // width: 65px;
                    // height: 40px;
                }

                > a {
                    color: $text-black;
                    text-decoration: none;
                    font-weight: bolder;
                    margin: 0 30px 20px 0;
                    display: inline-block;

                    &.selected {
                        color: $text-green;
                    }
                }
            }
        }
    }

    &__product {
        margin: $column-gap 0 calc(#{$column-gap + 8px});
        @include mobiletablet {
            margin: $column-gap 0 0;
        }
        > div {
            position: relative;
        }

        &__img {
            height: 250px;
            display: block;
            background-color: $background-light-grey;
            padding: $line-margin-half 3em;
            cursor: pointer;
            div {
                background-size: contain;
                height: 100%;
                width: auto;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        &__product-info {
            margin: $line-margin 0 0;
            position:relative;

            > div {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: space-between;
                > div {
                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                }
            }

            .discount-price { 
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                margin-top: 10px;
                margin-right: 10px;
                p { 
                    color:rgb(238, 3, 3); 
                }
            }

            h5 {
                margin: 0;
                font-size: $font-size-default;
                font-family: "NewRailAlphabet-light";
                font-weight: normal;
                @include mobiletablet {
                    font-size: $font-size-default-mob;
                }
            }
            h4 {
                margin: 0;
                font-size: $font-size-default;
                font-family: "NewRailAlphabet-light";
                font-weight: normal;
                cursor: pointer;
                @include mobiletablet {
                    font-size: $font-size-default-mob;
                }
            }
            p {
                font-size: $font-size-default;
                margin: 0;
                @include mobiletablet {
                    font-size: $font-size-default-mob;
                }
            }
        }
        &__price {
            padding-top: $line-margin;
            margin-top: $line-margin;
            border-top: 1px solid $border-black;
        }
        &__category {
            font-family: $font-bold !important;
        }
        &__fits, &__select-variant {
            font-family: $font-bold !important;
            padding-top:10px;
        }
        &__add-btn {
            display: block;
            cursor: pointer;
            margin-left: $column-margin;
            font-family: $font-bold;
            margin-top: 10px;
        }
    }
    &__filter {
        //  border-top: 1px solid $border-black;
        padding: $line-margin 0;
        &__row {
            padding-left: calc(12.4% + #{$column-margin});
            display: flex;
            flex-flow: row wrap;
        }

        &__column {
            ul {
                list-style: none;
                padding-left: 0;
                margin: 0;
            }
            > ul {
                > li {
                    > a {
                        font-family: $font-bold;
                    }
                }
                li {
                    font-size: $font-size-menu;
                    a {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.shower-configure__icon{
    position: absolute;
    cursor: pointer;

    &--configure{
        background-image: url('~@/assets/tapwell/img/settings.svg');
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        width: auto;
    }
}

.shower-configurator-leave-active{
    @include desktop{
        animation: opacity 0.5s reverse;
    }
}

@keyframes opacity {
    from {opacity: 0;}
    to {opacity: 1;}
}