
.shower-configurator{
    position:absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $header-z-index;
    overflow: hidden;    

    background-color: $background-white;

    &__container{
        position: relative;
        width: 100%;
        height: 100%;
             

        @include desktop{
            display: grid;
            grid-template-columns: 1fr min(368px);
            gap: 0 64px;
        }

    }

    &__close{
        &-container{
            text-align: right;
            padding: 10px 7px 0 0;
            @include desktop{
                padding: 0 10px 10px 0;
            }

            &--stand-alone{
                padding: 0;
            }
        }
        &-btn{
            background-color: transparent;
            border: none;
            color: $text-green;
            font-weight: 700;          
            z-index: 1;           
        }
    }
    
   

    &__loader-container{        
        position: absolute;
        z-index: 1;
        right: 50%;
        top: 50%;
        transform: translate(50%,-50%);

        >.loader{
            width: 105px;
            height: 105px;
        }
    }

    .preview{
        &-container{
            display: none;
            @include desktop{
                display:block;
                z-index: 1;
                
            }

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-color: $background-light-grey;
        }
        
    }

    .settings{
        &-container{            
            background-color: $background-white;            
            height: 100%;
            overflow: hidden;
            display: grid;
            grid-template-rows: auto 1fr auto;

            &__summary{
                &-container{
                    & span{
                        display: block;
                    }
                }
                &-price{
                    font-weight: 700;
                    font-family: "NewRailAlphabet-bold";
                    font-size: $font-size-large;
                    margin-bottom: 1rem;
                }

               

            }

            & .variant-options{
                flex: 1;
                position: relative;
                max-height: 450px;
                padding-bottom: 0.5rem;

                @include desktop{
                    overflow-y: auto;
                    max-height: 100%;
                    padding-right: 1px;
                    padding-left: 1px;
                }

               &__content{
                padding: 0 13px 0 1px;
                position: relative;

                &-header{
                    font-family: "NewRailAlphabet-bold";
                    margin-bottom: 0.5rem;
                }

                & .custom-dropdown{
                    margin-bottom: 1.5rem;
                }
               }
                
                &__shadow-container{
                    
                    //Styling overrides for scrollShadow
                    & .scroll-shadow__shadow-bottom,
                    & .scroll-shadow__shadow-top{
                        border-radius: 0;
                        height: 7rem;
                        background-image: linear-gradient(rgba(255, 255, 255, 1.1) 0%, rgba(255, 255, 255, 0) 100%);
                    }

                    //Stand alone when scrolling isn't enabled
                    &.scroll-shadow--disabled{
                        @include mobiletablet{
                            max-height: unset;
                        }
                    }
                    
                }

                &--no-scroll{
                    @include mobiletablet {                    
                        max-height: unset;
                    }
                }


            }

            &__mode--stand-alone{

                :last-child{
                    margin-top: 5px;
                }
                @include desktop{
                    display: flex;
                    :last-child{
                        margin-top: 0px;
                        margin-left: 6px
                    }
                }
            }
        }         
    }

    &__btn{
        @include btn-default($background-black, $background-white);
        text-align: left;
        padding: 11px 10px 11px 18px;

        &--secondary{
            @include btn-default($text-green, $background-white);
            text-align: left;
            padding: 11px 10px 11px 18px;
        }
    }

}

.configurator-types {
    list-style: none;
    padding: 0;
    width: 100%;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(2,1fr);
    margin-top: 0;
    margin-bottom: 1.5rem;

    li {
        display: flex;
        flex-direction: column;
        padding: 12px;
        background: $background-light-grey;
        cursor: pointer;
        min-width: 70px;
        
        &.selected {            
            box-shadow: 0 0 0 1px $border-black;
            cursor: default;
            margin: 0 1px;
        }
    }
    

    &__details{
        &-header{
            color: $text-black;
            display: block;
            

            &--no-block{
                display: inline;
            }
        }
        
        &-container{
            display: flex;
            flex-wrap: wrap;
        }

        &-text{
            color: $text-dark-grey;
            font-size: $font-size-default-mob - 1;
            white-space: nowrap;
            padding-right: 6px;
            
        }
        &-price{
            color: $text-green;            
            font-size: 12px;
            white-space: nowrap;
        }
    }

    &__image{
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 60px;
        margin-top: 10px;

        >img{
            width: 100%;
            object-fit: contain;
            max-height: 100%;
        }
    }
}



// Animations
.preview-container-enter-active{
    @include desktop{
        animation: slideIn 0.4s;
    }
}

.settings-opacity-enter-active{
    @include desktop{
        animation: opacity 1.5s;
    }
}

@keyframes slideIn {
    from {transform: translateX(100%);}
    to {transform: translateX(0%);}
}

@keyframes opacity {
    from {opacity: 0;}
    to {opacity: 1;}
}

//Edge case for product banner
.product-page__banner.show-shower-configurator{
    @include mobiletablet{
        min-height: 580px !important;
        height: 100% !important;
    }
    .product-page__banner__about{
        display: none;

        @include desktop {
            display: block;
        }
    }
}

// Corner cases when shower config is open
body.shower-config--open{

    @include mobiletablet{
        .product-page__banner.show-shower-configurator{
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: $header-z-index + 1;  
            margin: 0;
            padding: 0;
            
            & .shower-configurator{
                padding: 20px 30px;
                
                & .settings-container{

                    
                    & .variant-options{
                        max-height: unset;

                        & .variant-options__shadow-container{                            
                            max-height: calc(100vh - 145px);
                            @include tablet{
                                max-height: calc(100vh - 150px);
                            }
                        }
                    }
                }
            }
        }
    }
        
    .b2c-header__nested-links{
        display: none;
    }
}