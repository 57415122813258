
.product-inspirational-images{
    .thumb-nail{
        &__list{
            list-style: none;
            display: flex;
            flex-direction: row;
            padding-left: 15px;            
        }

        &__item{            
            border: 1px solid $background-black;            
            height: 55px;
            width: 55px;
            margin: 0 5px;
            display: flex;            
            justify-content: center;

            &:hover{
                cursor: pointer;
            }

            @include desktop{
                width: 60px;           
                height: 60px;    
            }

            >img{
                object-fit: contain;
                width: 100%;
                padding: 6px;
            }
            &-no-border{
                height: calc(#{$thumb-image-size--mobile} + 2px);
                width: calc(#{$thumb-image-size--mobile} + 2px);
                border: none;
                @include desktop{
                    width: calc(#{$thumb-image-size--desktop} + 2px);           
                    height: calc(#{$thumb-image-size--desktop} + 2px);    
                }
                >img{
                    object-fit: cover;
                    width: 100%;
                    padding: 0px;
                }

            }
            &-show-more{
                align-items: center;
                background-color: $background-light-grey2;
                font-size: 12px;
                height: calc(#{$thumb-image-size--mobile} + 2px);
                width: calc(#{$thumb-image-size--mobile} + 2px);
                border: none;
                text-align: center;

                @include desktop{
                    width: calc(#{$thumb-image-size--desktop} + 2px);           
                    height: calc(#{$thumb-image-size--desktop} + 2px);    
                }
            }       
            &:first-child{
                margin-left: 0;
            }     
        }       
              
    }
    

    //Vuelightbox styling
    .vel-img-modal.vel-modal{
        background-color: rgba(255,255,255,1);
        z-index: 999999 ;

        & .vel-img{
            background-color: transparent;  
            -webkit-box-shadow: unset; 
            box-shadow: unset;
            max-width: 75vw;
            max-height: 75vh;

            @include desktop{
                max-width: 65vw;
                max-height: 65vh;
            }
        }

        & .vel-icon{
            fill: $background-black;
        }

        & .vel-toolbar{
            display: none;
        }
    }
    
}