@import "~@/styles/constants.scss";







.imagelisting {

    &__images {
        @include tabletdesktop {
            display: grid;
            grid-gap: 35px 30px;
            grid-template-columns: repeat(8, 1fr);
        }
    }
}
