.b2c-header {
    height: $header-height;
    top: 0;
    padding-top:  0;
    position: fixed;
    width: auto;
    max-width: none;
    z-index: $header-z-index;
    margin: 0 auto;
    display: block;
    left: 0;
    right: 0;
    
    @include mobiletablet {
      height: 12px;
      top: calc(#{$line-margin} * 2);
      width: 100%;
      padding: 0;
    }
    &:not(.scroll-up), &:not(.scroll-down) {
      height: 0;
      .b2c-header__row {
        
        height: 0;
        @include desktop{
          top: 25px;
        }
        > div {
          height: 0;
        }
      }
    }

    &.scroll-up, &.stick {
      top: 0px;
      box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 0px 7px 0 rgba(0, 0, 0, 0.1);
      padding: calc(#{$line-margin} * 2) 0;
      left: 0;
      width: calc(100%);
      height: auto;
      transition: top 0.5s;
      max-width: none;
      background: $background-white;
      z-index: $header-z-index+1;
      &:not(.open) {
        .b2c-header__heading {
          @include mobiletablet {
            opacity: 0;
          }
        }
      }
   
      .b2c-header {
       
        &__row {
          position: relative;
          top: unset;


          @include desktop {
            height: auto;
            > div {
              height: auto;
            }
          }
          @media (min-width: 1600px) {
            max-width: calc(#{$body-width} + #{$column-gap});
            margin: 0 auto;
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            
          }
        }
        &__heading {
          ul {
            li {
              @include desktop {
                font-size: $font-size-menu;
              }
            }
          }
        }
   
      }
  
      @include mobiletablet {
        height: 12px;
        width: 100%;
        height: 12px;
        padding: $line-margin 0;
        left: auto;
  
        .hamburger {
          right: $column-gap;
          top: -0.5px;
        }
        &.open {
          top: 10px;
          transition: 0;
          .hamburger  {
            right: 60px;
            top: calc(#{$line-margin} * 2); 
          }
        }
      }
  
    }
    &.scroll-down {
      top: -90px;
      transition: top 0.5s;
      padding-top: 0;
      &:not(.open) {
        .b2c-header__heading {
          @include mobiletablet {
            opacity: 0;
          }
        }
      }
   
      @include mobiletablet {
        top: -50px;
        left: 0;
        width: 100%;
        padding: $line-margin 0;
        .hamburger  {
          right: $column-gap;
          top: $line-margin;
        }
        &.open {
          top: 0;
          transition: 0;
          .b2c-header__row {
            margin-top: 25px;
          }
          .hamburger {
            right: 60px;
            top: 30px;
          }
  
        }
      }
   
      .b2c-header {
        &__heading {
          @include desktop {
            top: - 100vh;
          }
        }
      }
  
    }
  
  
    &__row {
      height: $header-height;
      position: relative;
      max-width: calc(1600px + #{$body-padding} + #{$body-padding}) !important;    

      @include mobiletablet {
        height: 12px;
      }
  
      > div {
        height: $header-height;
        position: relative;
        @include desktop {
            justify-content: flex-end;
        }
        
        @include mobiletablet {
          height: 12px;
        }
  
        &:last-child {
          text-align: right;
        }
      }
    }
    &__logotype {

      padding-left: 0;
      @include mobiletablet {
        z-index: 999;
        height: 16px !important;
        width: auto;
        max-width: none;
        flex-basis: auto;
        padding: 0;
        max-width: 62px;
      }
    }
    &__search-close {
    }
    &__search {
      display: block;
      svg {
        display: none;
      }
      @include mobiletablet {
        font-size: 0;
        position: relative;
        margin-right: 30px;
        svg {
          display: block;
          position: absolute;
          width: 15px;
          top: -2px;
    
          .cls-1 {
            stroke: $text-black;
            fill: none;
            stroke-width: 2px;
            stroke-miterlimit:10;
          }
        }
      }
    }
    &__heading {
      >  ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: calc(-12.4% + #{$column-gap});
        margin-left: calc(-35% + #{$column-margin});
   
        li {
          margin: 0;
          font-size: clamp(30px, 4vh, 45px);
          font-family: NewRailAlphabet-bold;
          line-height: 1;
          a {
            color: $text-black;
  
            &:focus, &:hover, &:active {
              outline: 0;
            }
            &:hover {
              color: $text-green;
            }
          }
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
          }
      
          &.active {
            > a {
              &:before {
                content: "/\2192/";
                display: inline;
                margin-right: 5px;
                font-family: "NewRailAlphabet-medium";
                font-weight: normal;
                opacity: 1;
            }
          
          }
        }
  
        }
        > li {
          > span {
            color: $text-green;
          }
          > span.selected > a {
            color: $text-green;
          }
        }
      }
  
    }
  
    &__logo {
      width: 100%;
      max-width: 97px;
      display: block;
      @include desktop {
        max-width: 70px;
        margin-top: 2px;
      }
      @include mobiletablet {
        height: 100%;
        width: auto;
      }
      &--light {
        display: none;
      }
    }
    &__links {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      @include mobiletablet {
        display: none;
      }
    }
    &__link-list {
      width: calc(100% - #{$column-margin});
      margin-right: $column-margin;
      @include desktop {
        width: auto;
      }
  
      ul {
        padding-left: 0;
        list-style: none;
        margin: 0;
        @include desktop {
            margin-right: 50px;
        }
        li {
          a {
            white-space: nowrap;
            font-size: $font-size-menu;
            @include mobiletablet {
              font-size: $font-size-menu-mob;
            }
          }
        }
      }
    }
    &__countrypicker{
      position: absolute !important;
      right: 60px;
      top: -13px;
      @include desktop{
        position: relative !important;
        margin-top: 8px;
        display: flex;
        justify-content: flex-start !important;
      }
    }

    &__internal {
      width: calc(100% - #{$column-margin});
      margin-left: $column-margin;
      z-index: 1;
      @include desktop {
          width: auto;
          // position: absolute;
          right:0;
          margin-right: $column-margin;
      }
      @include mobiletablet {
        display: none;
      }
      ul {
        padding-left: 0;
        list-style: none;
        margin: 0;
      }
      a {
        font-size: $font-size-menu;
        white-space: nowrap;
        display: block;
        @include mobiletablet {
          font-size: $font-size-menu-mob;
        }
      }
    }
    &__cart {
        font-size: $font-size-menu;
        min-width: 120px;
        @include desktop {
            flex-flow: row;
            padding-left: 40px;
            min-width: unset;
        }


        @include mobiletablet {
          font-size: $font-size-menu-mob;
          display: flex;
          flex-flow: row;
          position: absolute !important;
          top: -1px;
          right: 0;
        }
        > div {
            justify-content: flex-start;
            flex-flow: column;
        }

        a {
          white-space: nowrap;
          cursor: pointer;
          &.header__cart-label {
            svg {
              display: none;
            }
            @include mobiletablet {
              font-size: 0;
              svg {
                display: block;
                height: 15px;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                path {
                  stroke: $text-black;
                  stroke-width: 0.5px;
                }
       
              }
            }
          }
        }
     
   
    }
    
    .header {
      &__cart-title {
        display: inline;
        position: relative;
   
        @include mobiletablet {
          margin-right: calc(#{$column-margin} * 4);
          margin-top: -3px;
          display: flex;
        }
      }
      &__cart-amount {
        position: absolute;
        top: 19px;
        left: 0;
        @include mobiletablet {
          position: static;
          margin-left: 6px;
        }
      }

    }

    img {
      width: 100%;
      display: block;
      @include mobiletablet {
        height: 100%;
        width: auto;
      }
    }
  
    .active {
      font-family: $font-bold;
    }
    .hamburger {
      background-color: transparent;
      position: absolute;
      border: 0;
      height: 18px;
      width: 20px;
      z-index: 999;
      right: 0;
      top: -3px;
      padding: 0;
      cursor: pointer;

      @include desktop {
          width: 35px;
          top: auto;
          right: $body-padding;
      }
      
      @include mobiletablet {
        right: $column-gap;
    }
    
      .line {
        background-color: $border-black;
        height: 1px;
        width: 20px;
        display: block;
        margin: 5px 0;
        @include desktop {
            width: 100%;
            margin: 8px 0;
        }
      }
      &.open {
        .line {
          &:nth-child(1) {
            transform: translateY(6px) rotate(45deg);
          }
          &:nth-child(2) {
            transform: translateY(-6px) rotate(-45deg);
            margin-top: 11px;
          }
        }
      }
      &:focus,
      &:hover,
      &:active {
        outline: 0;
      }
    }
  
    &.open {
      width: 100vw;
      background: $background-white;
      margin-top: -$column-margin;
      margin-left: -$column-gap;
      height: 100%;
      position: fixed;
      z-index: $header-z-index+1;
  
      @include mobiletablet {
        left: auto;
        margin-top: calc(-#{$line-margin} * 2);
        padding: calc(#{$line-margin} * 2) 0;
        overflow-y: auto;
        max-height: calc(100vh - (#{$line-margin} * 4));
      }
      .b2c-header {
        &__row {
          margin-top: $column-margin;
          margin-left: $column-margin;
          margin-right: $column-margin;
          height: auto;    
          display: flex;
          flex-flow: column;
        }
        &__heading {
          display: block;
          order: 3;
          @include mobiletablet {
            order: 2;
            padding: 0;
            margin-top: calc(#{$line-margin} * 2);
            display: none;
          }
          > ul {
            @include mobiletablet {
              padding-top: $line-margin;
            }
          }
          ul {
            margin-left: 0;
            margin-bottom: calc(#{$line-margin} * 5);
            border-top: 1px solid $border-black;
            padding-top: $line-margin;
            @include mobiletablet {
              border-top: 0;
              margin-bottom: 0;
              padding-top: 0;
            }
            li {
              font-size: $font-size-menu-mob;
              font-family: "NewRailAlphabet-light";
              @include mobiletablet {
                font-family: $font-bold;
                font-size: $font-size-heading-mob;
              }
              a {
                color: $text-black;
              }
              &.active {
                font-family: $font-bold;
                > a {
                  color: $text-green !important;
                }
              }
            }
          }
  
        }
        &__logotype {
          margin-bottom: $line-margin;
          order: 1;
        }
        &__links {
          display: flex;
          flex-flow: wrap;
          padding: 0;
          padding-top: $line-margin;
          padding-bottom: $line-margin;
          margin: 0 $column-margin;
          width: calc(100% - #{$column-gap});
          flex-basis: auto;
          overflow: auto;
          max-height: calc(100vh - 260px);
          order: 2;
          .b2c-header__internal {
            display: none;
          }
          @include mobiletablet {
            order: 3;
            margin: 0;
            display: none;
          }
        }
        &__link-list {
          width: 100%;
          margin-right: 0;
          @include mobiletablet {
            display: none;
          }
        
          ul {
            display: flex;
            flex-flow: wrap;
            li {
              width: 100%;
              
              a {
                line-height: $line-height;
                font-family: $font-bold;
              font-size: $font-size-heading-mob;
              line-height: 1;
              }
              &.active {
               
                a {
                  color: $text-green;
                }
              }
            }
          }
        }
        &__internal {
          display: flex;
          flex-flow: wrap;
          width: 100%;
          margin: 0;
          padding: $line-margin 0 0;
          border-top: 1px solid $border-black;    
          width: calc(100% - 30px);
          margin: 0 15px;
          order: 5;
  
          a {
            width: 100%;
            display: block;
            line-height: $line-height;
          }
        }
        &__cart {
          display: flex;
          border-top: 1px solid $border-black;
          padding: $line-margin 0;
          margin: 0 $column-margin;
          flex-basis: auto;
          width: calc(100% - #{$column-gap});
          order: 4;
          height: 85px;
          flex-flow: column;
          display: none;
        }
        &__cart-title {
            margin-top: 10px;
        }
      }
      .hamburger {
        right: $column-gap;
        top: 10px;
        @include mobiletablet {
          right: $column-gap;
          top: - $line-margin-half;
        }
  
      }
      .mega-menu {
        display: block;
      }
    }
    &.logged-out {
      @include mobiletablet {
        &.open .b2c-header__links {
          border-top: 0;
        }
      }
    }
    &.lightmode {
      .hamburger {
        .line {
          background-color: $text-white;
        }
      }
      .b2c-header__cart .header__cart-label {
        svg {
          @include mobiletablet {
            path {
              stroke: $text-white;
            }
          }
        }

      }
      .b2c-header__search svg .cls-1 {
        @include mobiletablet {
          stroke: $text-white;
        }
      }
      .b2c-header__heading ul li a {
        color: $text-white;
      }
      .b2c-header {
        &__logo {
          &--light {
            display: block;
          }
          &--dark {
            display: none;
          }
        }
      }
      a {
        color: $text-white;
      }
      &.scroll-up, &.stick {
        .hamburger {
          .line {
            background-color: $text-black;
          }
        }
        .b2c-header__heading ul li a {
          color: $text-black;
        }
        a {
          color: $text-black;
        }
        .b2c-header {
          &__logo {
            &--light {
              display: none;
            }
            &--dark {
              display: block;
            }
          }
        }
        .b2c-header__cart .header__cart-label {
          svg {
            @include mobiletablet {
              path {
                stroke: $text-black;
              }
         
            }
          }
  
        }
        .b2c-header__search svg .cls-1 {
          @include mobiletablet {
            stroke: $text-black;
          }
        }
        
      }
      &.open {
        .hamburger {
          .line {
            background-color: $text-black;
          }
        }
        .b2c-header__heading ul li a {
          color: $text-black;
        }
        a {
          color: $text-black;
        }
        .b2c-header {
          &__logo {
            &--light {
              display: none;
            }
            &--dark {
              display: block;
            }
          }
        }
       
      }
      .mega-menu {
        a {
          color: $text-black;
        }
        .hamburger {
          .line {
            background-color: $text-black;
          }
        }
      }
    }
    &.mega-menu-open {
      z-index: $header-z-index+2;
      .hamburger {
        z-index: 9999999;
        right: $body-padding;
      }
      &.lightmode {
        .hamburger {
          .line {
            background-color: $text-black;
          }
        }
      }
   
    }   
  &:not(.open) {
    .b2c-header__heading {
      @include mobiletablet {
        display: block;
        padding: 0;
        margin-top: calc(#{$line-margin} * 2);
        > ul {
          margin-left: -$column-margin;
        }
        > ul li {
          font-size: $font-size-default-mob;
          display: flex;
          flex-flow: row;
          margin: 0 $column-margin;
          > a {
            display: flex;
          }
          &.active {
            display: flex;
          }
          ul {
            display: flex;
            flex-flow: row wrap;

            li {
              display: none;
            } 
          }
        }
      }
    }
  }
}
  
/* Country picker*/

.vat-country-dropdown {
  position: relative; 
  width: 40px;
  margin: 0 auto;
  padding: 10px 15px;
  background-color: transparent;
  color: $text-white;
  cursor: pointer;
  outline: none;

  &.active{
    background-color: $text-white;
    color: $text-black;
    &:after{
      border-color: $text-black transparent;
    }
  }
}

.header.scroll-up{
  & .vat-country-dropdown{
    color: black;
    margin-top: 2.5px;

    &:after{
      border-color: $text-black transparent;
    }
  }

  .b2c-header__cart{
    @include mobiletablet{
      top: 2.1px;
    }
  }
}

.vat-country-dropdown:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 16px;
    top: 50%;
    margin-top: -3px;
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: $text-white transparent;
}

.vat-country-dropdown .dropdown {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 23px;
    background: white;
    transition: all 0.3s ease-out;
    list-style: none;
    max-height: 80vh;
    overflow-y: scroll;
    /* Hiding */
    opacity: 0;
    pointer-events: none;
    padding:0;
}

.vat-country-dropdown .dropdown li a {
    display: block;
    text-decoration: none;
    color: #333;
    padding: 10px;
    transition: all 0.3s ease-out;
}

.vat-country-dropdown .dropdown li:hover a {
    color: grey;
}

.vat-country-dropdown .dropdown li a.selected:after {
  content:'✓';
  color: #333;
  font-weight: bolder;
  padding-left: 10px;
}

.vat-country-dropdown.active:after {
    border-width: 0 6px 6px 6px;
}

.vat-country-dropdown.active .dropdown {
    opacity: 1;
    pointer-events: auto;
}


// Corner case for the country picker
.product-category-page{
  .vat-country-dropdown{
    color: $text-black;
    &:after{
      border-color: $text-black transparent;
    }
  }
}

//corner case for search modal
.search-modal-open {
  & .header.b2c-header{
    z-index: $header-z-index+1;
  }
}

// Corner case when country picker is visible

.country-picker--visible{
  .b2c-header__cart{
    @include mobiletablet{
      right: 60px;
    }
  }
}

// Corner case for the header when we implemented the newsletter banner
#app {
  .signup-banner--visible{
    & .b2c-header__row.container-padding{
      top: 25px;
  
      @include desktop{
        top: 55px;   
      }
    }
  }
 

  .header.b2c-header{
    &.scroll-down{
      
      >.b2c-header__row.container-padding {
        top: 17px;
        @include desktop{
          top: 25px;
        }        
      } 
    }

    &.scroll-up{
      >.b2c-header__row.container-padding {
        top: unset;
      }       
    }
  }
}
