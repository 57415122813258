.category-navigation {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    ul {
      list-style: none;
      padding-left: 0;
   
      a {
        cursor: pointer;
        width: 100%;
        display: block;
        font-size: $font-size-menu;
        @include mobiletablet {
          font-size: $font-size-menu-mob;
        }
      }
    }
    &__row {
      margin-top: $line-margin;
      margin-bottom: calc(#{$line-margin} * 7);
      position: relative;
      @include mobiletablet {
        margin-bottom: $line-margin;
      }
    }
    &__show-navigation {
      display: inline;
      cursor: pointer;
      @include mobiletablet {
        font-size: $font-size-menu-mob;
      }
      &:after {
        content: "\2192";
        font-family: "NewRailAlphabet-medium";
        margin-left: 3px;
      }
      &.hide {
        display: none;
      }
    }
    &__nav {
      width: calc(50% - #{$column-margin});
      margin-left: $column-margin;
      position: relative;
      @include mobiletablet {
        margin-left: 0;
        width: 100%;
      }
    }
  
    &__offcet {
      width: calc(50% - #{$column-margin});
      margin-right: $column-margin;
      @include mobiletablet {
        display: none;
      }
    }
    &__child-list {
      display: none;
      li {
        margin-bottom: $line-margin-half;
        &.selected {
          cursor: default;
          a {
            color: var(--bannerHeadingColor);
          }
        }
      }
      @include mobiletablet {
        padding-left: $column-margin !important;
      }
    }
    &__hide-navigation {
      display: none;
      @include mobiletablet {
        &.open {
          display: flex;
          flex-flow: row;
          font-size: $font-size-menu-mob;
          cursor: pointer;
          p {
            margin: 0;
            display: inline;
            line-height: 1;
          }
           .line {
            background-color: $border-black;
            height: 1px;
            width: 16px;
            display: block;
            margin: 1px 3px;
            &:nth-child(1) {
              transform: translateY(6px) rotate(45deg);
            }
            &:nth-child(2) {
              transform: translateY(-6px) rotate(-45deg);
              margin-top: 11px;
            }
          }
        }
      }
      
    }
    &__list {
      display: flex;
      flex-flow: row;
      margin: 0;
      width: 39vw;
  
      @include mobiletablet {
        width: 100%;
        flex-flow: wrap;
        display: none;
        margin: $line-margin 0 0;
      }
      &.open {
        display: flex;
      }
      > li {
        width: 30%;
        @include mobiletablet {
          width: 100%;
          margin-bottom: $line-margin-half;
        }
        > a {
          font-family: $font-bold;
          &.selected {
            + .category-navigation__child-list {
              display: block;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  