.newsletter-signup {
    background: $background-light-grey;
    padding: calc(#{$line-margin} * 5) 0 calc(#{$line-margin} * 9) 0;
    @include mobiletablet {
        padding: calc(#{$line-margin} * 1) $column-margin calc(#{$line-margin} * 4) $column-margin;
    }
    &__form {
        min-width: 55%;
        flex-basis: auto;
        padding: 0 $column-margin;
        @include mobiletablet {
            min-width: 100%;
        }

        h2 {
            font-family: $font-bold;
            font-size: $font-size-heading;
            line-height: 1;
            @include mobiletablet {
                font-size: $font-size-heading-mob;
            }
        }

        .form-group {
            border-top: 0;
            border-bottom: 1px solid $border-black;
            margin-top: calc(#{$line-margin} * 3);
            input {
                background: transparent;
            }
            @include mobiletablet {
                margin-top: calc(#{$line-margin} * 2);
            }
        }
    }
    &__white-space {
        max-width: 12.6%;
        @include mobiletablet {
            display: none;
        }
    }
    &__btn {
        width: 20%;
        margin-left: $column-margin;
        display: flex;
        flex-flow: column;
        align-self: flex-end;

        @include mobiletablet {
            width: 100%;
            margin: calc(#{$line-margin} * 2) $column-margin 0 $column-margin;
        }

    }
}