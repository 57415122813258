@import "~@/styles/constants.scss";



































































































.filter {
    padding-top: 60px;
    margin-bottom: 60px;
    @include desktop {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 0 30px;
        border-top: solid 1px var(--color-black);
        margin-bottom: 110px;
        padding-top: 110px;
    }

    &__left-section {
        @include tabletdesktop {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 30px 30px;
        }

        @include desktop {
            grid-area: 1/1/2/6;
            border-right: solid 1px;
        }
    }

    &__brands-select {
        cursor: pointer;

        margin: 10px 0px 10px 0px;
        min-width: 70px;
        min-height: 20px;

        &--static-logo {
            width: 70px;
            height: 20px;
            background-size: 100% 100%;
        }

        @include desktop {
            margin: 0px 40px 0px 0px;
        }
    }
    &__bricmate {
        background-image: url("../../assets/originalIcons/bricmate_orange_logo.svg");
    }

    &__haven {
        background-image: url("../../assets/originalIcons/haven_logo.svg");
    }

    &__tapwell {
        background-image: url("../../assets/originalIcons/tapwell_logo.svg");
    }

    &__brands {
        display: grid;
        grid-area: 3/1/4/6;
        &-logo {
            height: 20px;
        }
        @include desktop {
            display: flex;
        }
    }

    &__right-section {
        grid-area: 1 / 6 / 3 / 9;
    }

    &__description {
        grid-area: 1 / 1 / 2 / 6;
        margin-bottom: 24px;
    }

    &__search {
        grid-area: 2 / 1 / 3 / 6;
        width: 100%;
        margin-right: 2rem;
        height: 2.3125rem;
        margin-bottom: 10px;
        @include tabletdesktop {
            margin: 0;
        }
        @include desktop {
            grid-area: 2 / 1 / 3 / 4;
        }
    }

    &__search-button {
        @include tabletdesktop {
            grid-area: 2 / 6 / 3 / 7;
        }

        @include desktop {
            grid-area: 2 / 4 / 3 / 5;
        }
    }

    &__select-wrapper {
        @include tabletdesktop {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
        }

        @include desktop {
            grid-area: 4 / 1 / 4 / 6;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
        }
        grid-gap: 35px 30px;
    }

    &__active-filters {
        column-count: 1;
        column-gap: 30px;
        margin-bottom: 8px;

        @include desktop {
            column-count: 2;
        }
    }

    &__active-filter {
        text-transform: uppercase;
        font-family: var(--font-family-bold);
        cursor: pointer;
        display: flex;
        grid-gap: 8px;
        align-items: center;
        margin-bottom: 4px;
        font-weight: 700;
        margin-left: 10px;
    }

    &__selected-filters {
        display: flex;
        margin-bottom: 4px;
        justify-content: space-between;
        break-inside: avoid-column;
        word-break: break-word;
    }

    &__checkbox {
        font-weight: 700;
        margin-left: 10px;

        display: inline-flex;
    }

    &__delete-filter {
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        border: none;
        background: none;
        align-self: flex-start;
        padding: 0 2px;
    }
    input {
        background: transparent
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
            no-repeat 5px center;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 15px;
        border: none;
        border-bottom: 1px solid var(--color-black);
        outline: none;
        padding: 10px 10px 5px 30px;

        &::placeholder {
            color: var(--color-gray);
        }
    }
}
