.inspiration-block {
    display: flex;
    flex-flow: row wrap;
    margin-top: calc(#{$line-margin} * 1) !important;
    &__column {
        width: calc(50% - #{$line-margin});
        margin: $line-margin 0;
   
        @include mobiletablet {
        width: 100%;
      
        }

        &:nth-child(odd){
            @include mobiletablet {
               margin: calc(#{$line-margin} * 1) 0;
            }
        }
        &:nth-child(even) {
            @include mobiletablet {
                margin: calc(#{$line-margin} * 1) 0;
             }
        }
    }
    &__product {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 60vh;
        max-height: 600px;
        @include mobiletablet {
            height: 40vh;
        }
        > a {
            display: block;
            height: 100%;
        }
    }
    &__product-text {
        padding: calc(#{$line-margin} * 2);

        h1,h2,h3,h4,h5,h6,p {
            margin-top: 0;
            margin-bottom: $line-margin;
            line-height: 1;
        }
        h1, h2, h3, h4, h5, h6 {
            font-size: $font-size-heading;
            font-family: "NewRailAlphabet-bold";
            @include mobiletablet {
                font-size: $font-size-heading-mob;
            }
      
            a {
                color: $text-green;
                text-decoration: none;
            }
            .font-icon {
                font-family: $font-icon !important;
                font-weight: normal !important;
            }
            .font-light {
                font-family: "NewRailAlphabet-light" !important;
                font-weight: normal !important;
            }      
            .arrow-right:after {
                content: "\2192";
                font-family: "NewRailAlphabet-medium" !important;
                font-weight: normal !important;
            }        
            .arrow-left:before {
                content: "\2190";
                font-family: "NewRailAlphabet-medium" !important;
                font-weight: normal !important;
            }
            .white {
                color: white !important;
            }
            .black  {
                color: black !important;
            }
        }
        p {
            .white {
                color: white !important;
            }
            .black  {
                color: black !important;
            }
        }
    }
    &__categories {
        ul {
            list-style: none;
            margin: calc(#{$line-margin} * 2) 0 0;
            padding: 0;
            li {
                a {
                    line-height: $line-height;
                    font-family: $font-bold;
                    color: $text-black;
                    font-size: $font-size-menu;
                    width: 100%;
                    display: block;
                    @include mobiletablet {
                        font-size: $font-size-menu-mob;
                    }
                }
            }
        }
    }
}