@import "~@/styles/constants.scss";







button {
    background: none;
    border: 1px solid var(--color-black);
    color: var(--color-black);
    min-width: 200px;
    padding: 10px;
    text-align: left;
    cursor: pointer;

    &.secondary {
        background: var(--color-green);
        border-color: var(--color-green);
    }

    &.expanded {
        width: 100%;
    }

    &:hover {
        background: var(--color-black);
        border: 1px solid var(--color-black);
        color: var(--color-white);
    }

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
}
