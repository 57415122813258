.category {
    &__description {
        margin-top: $line-margin;
        &__text {
            margin-left: calc(13% - #{$column-margin});

            @include mobiletablet {
                margin-left: 0;
                width: 100%;
                max-width: 500px;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: $line-margin;
                word-break: break-word;
            }

            h1 {
                color: $text-green;
                &.green {
                    color: $text-green;
                }
                &.black {
                    color: $text-black;
                }
            }
            h1,
            h2 {
                margin: 0;
                font-size: $font-size-heading;
                font-family: $font-bold;
                line-height: 1;
                @include mobiletablet {
                    font-size: $font-size-heading-mob;
                }
            }
            h3,
            h4,
            h5,
            h6 {
                margin: 0;
                line-height: 1;
                font-family: $font-bold;
            }
            p {
                line-height: 1.2;
                margin: 0;
                font-size: $font-size-menu;
                @include mobiletablet {
                    font-size: $font-size-menu-mob;
                }
            }
            a {
                color: $text-green;
                text-decoration: none;
            }
            .font-icon {
                font-family: $font-icon !important;
                font-weight: normal !important;
            }
            .font-light {
                font-family: "NewRailAlphabet-light" !important;
                font-weight: normal !important;
            }

            .arrow-right:after {
                content: "\2192";
                font-family: "NewRailAlphabet-medium" !important;
                font-weight: normal !important;
            }

            .arrow-left:before {
                content: "\2190";
                font-family: "NewRailAlphabet-medium" !important;
                font-weight: normal !important;
            }
            .white {
                color: white !important;
            }
            .black {
                color: black !important;
            }
        }
    }
    &__loadmore {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .btn {
            text-align: center;
            padding: 10px;
            @include desktop {
                max-width: 377.48px;
            }
            @include mobiletablet {
                margin-top: calc(#{$line-margin} * 3);
            }
        }
    }
    &__block {
        display: flex;
        flex-flow: row wrap;
        margin-top: calc(#{$line-margin} * 7) !important;
        margin-bottom: calc(#{$line-margin} * 7);

        &:before {
            content: "";
            border-top: 1px solid $border-black;
            width: 100%;
            margin-top: -22px;
        }
        &__column {
            height: 30vh;
            max-height: 600px;
            width: calc(25% - #{$line-margin});

            .category-block__title h2 {
                font-size: 18px;
                &:after {
                    margin-top: -5px;
                }
            }

            &:nth-child(-n + 4) {
                width: calc(50% - #{$line-margin});
                height: 60vh;
                @include mobiletablet {
                    height: 40vh;
                }
                .category-block__title h2 {
                    font-size: 31px;
                    &:after {
                        margin-top: -12px;
                    }
                }
            }
            &:nth-child(1n + 5) {
                @include mobiletablet {
                    width: calc(50% - #{$line-margin});
                }
                &.category-block {
                    &__column {
                        &:nth-child(1n-5) {
                            @include mobiletablet {
                                width: calc(50% - #{$line-margin});
                                padding: $line-margin $line-margin $line-margin 0 !important;
                            }
                        }
                        &:nth-child(4n-4) {
                            @include mobiletablet {
                                width: calc(50% - #{$line-margin});
                                padding: $line-margin 0 $line-margin $line-margin !important;
                            }
                        }

                        &:nth-child(4n-2) {
                            background-color: #fff;
                            width: calc(25% - (#{$line-margin} * 2));
                            padding: $line-margin $line-margin;
                            @include mobiletablet {
                                width: calc(50% - #{$line-margin});
                                padding: $line-margin 0 $line-margin $line-margin !important;
                            }
                        }
                        &:nth-child(4n-5) {
                            width: calc(25% - (#{$line-margin} * 2));
                            padding: $line-margin $line-margin;
                            @include mobiletablet {
                                width: calc(50% - #{$line-margin});
                                padding: $line-margin $line-margin $line-margin 0 !important;
                            }
                        }
                    }
                }
            }
            &:nth-child(odd) {
                padding: $line-margin $line-margin $line-margin 0;
                @include mobiletablet {
                    padding: $line-margin 0;
                }
            }
            &:nth-child(even) {
                padding: $line-margin $line-margin $line-margin 0;
                @include mobiletablet {
                    padding: $line-margin 0;
                }
            }
            &:nth-child(n + 5) > a {
                background-color: $background-light-grey;
            }
            @include mobiletablet {
                height: 20vh;
                width: 100%;
                &:nth-child(-n + 4) {
                    width: 100%;
                }
            }
            > a {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                height: 100%;
                width: 100%;
                display: block;
            }
            &.Series {
                .category__block__title {
                    padding: calc(#{$line-margin} * 1);
                    h2 {
                        font-size: $font-size-menu;
                        @include mobiletablet {
                            font-size: $font-size-menu-mob;
                        }
                        &:after {
                            margin-top: -5px;
                        }
                    }
                }
            }
        }
        &__title {
            padding: calc(#{$line-margin} * 2);

            h2 {
                margin: 0;
                font-family: $font-bold;
                line-height: $line-height;
                font-size: $font-size-heading;
                color: $text-green;
                display: inline;
                @include mobiletablet {
                    font-size: $font-size-heading-mob;
                }
                &:after {
                    content: ""; //"\2192";
                    font-family: "NewRailAlphabet-medium" !important;
                    font-weight: normal !important;
                    display: block;
                    margin-top: -12px;
                }
            }
        }
    }
}
