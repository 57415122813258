.footer {
  margin-top: calc(#{$line-margin} * 5);
  padding-top: $line-margin;
  padding-bottom: $line-margin;
  border-top: 1px solid $border-grey;

  @include mobiletablet {
    padding-top: calc(#{$line-margin} * 2);
    padding-bottom: $line-margin-half;
  }
  ul {
    padding-left: 0;
    list-style: none;
    margin: 0;
    li {
     line-height: $line-height;
      a {
        width: 100%;
      }
    }
  }
  &__external-links {
    margin: 0 0 0 $column-margin !important;
    display: flex;
    flex-flow: column;

    p {
      margin: 0;
    }
    a {
      line-height: $line-height;
    }
    @include tablet {
      margin: 0 0 $line-margin $column-margin !important;
      width: calc(50% - #{$column-margin});
    }
    @include mobile {
      margin: 0 0 $line-margin !important;
      width: 100%;
    }
  }
  &__about-links {
    margin: 0 0 0 $column-margin !important;
    width: calc(50% - #{$column-margin});
    @include mobiletablet {
      margin: 0 0 $line-margin !important;
      width: 100%;
    }
  }
  &__product-links {
    font-family: $font-bold;
    margin: 0 $column-margin 0 0 !important;
    width: calc(60% - 10px);
    @include tablet {
      margin: 0 $column-margin $line-margin 0 !important;
      width: calc(50% - #{$column-margin});
    }
    @include mobile {
      margin: 0 0 $line-margin !important;
      width: 100%;
    }
  }
  &__title-text-container{
    width:50%;
  }
  &__additional-text{
    margin: 0 0 $line-margin 0  !important;
    p{
      margin:0 !important;
    }
    @include mobiletablet {
      margin: 0 0 $line-margin !important;
      width: 100%;
    }
  }
  &__section {
    &--sitename {
      display: flex;
      flex-flow: row wrap;
      p {
         margin: 0 $column-margin $line-margin 0;
        // width: calc(50% - #{$column-margin});
        @include tablet {
          margin: 0 0 $line-margin;
          line-height: 1;
          width: 100%;
        }
        @include mobile {
          margin: 0 0 calc(#{$line-margin} * 2);
          width: 100%;
        }
      }
    }
    &--links {
      display: flex;
      flex-flow: row;
      > div {
        width: 100%;
        display: flex;
        flex-flow: row;
        @include tablet {
          padding-top: calc(#{$line-margin} * 3);
        }
        @include mobiletablet {
          width: 100%;
          flex-flow: row wrap;
        }
      }
    }
    &--about {
      color: $text-grey;
      p {
        margin: 0;
        font-family: $font-bold;
        @include mobiletablet {
          margin: 0;
          max-width: 470px;
        }
      }
      @include mobiletablet {
        order: 4;
        margin-top: $line-margin;
      }
    }
    &--sociallinks {
      display: flex;
      flex-flow: column;

      a {
        line-height: $line-height;
        float: right;
      }
      p {
        margin: 0;
        float: right;
      }
      @include tablet {
        padding-top: calc(#{$line-margin} * 3);
      }
      @include mobiletablet {
        justify-content: flex-start;
        margin: 0 0 $column-margin;
  
      }
      @include mobile {
        p {
          float: none;
        }
        a {
          float: none;
        }
      }
    }
  }
}
