.search-b2c {
  &__search {
    display: flex;
    align-items: flex-end;
    max-width: 500px;
    margin: 0 auto;
    input {
      width: 100%;
      padding: 0 5px 0 30px;
      box-sizing: border-box;
      border: 0;
      border-radius: 0;
      transition: width 0.4s ease-out;
      text-align: left;
      font-size: 18px;
      line-height: 30px;
      font-family: "NewRailAlphabet-light" !important;
      border-bottom: 1px solid #000;

      background-image: url("~@/assets/tapwell/img/search.svg");
      background-repeat: no-repeat;
      background-size: 17px;
      background-position: 5px;

      &:focus {
        // width: 100%;
        outline: 0;
      }
    }

    .btn {
      margin-left: 10px;
      max-width: 130px;
      @include mobile {
        max-width: 90px;
      }
    }
  }
  &__result {
    position: relative;
  }
  &__result-column {
    @include mobiletablet {
      border-top: none;
      padding-top: 0;    }
  }
  &__loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    background-color: rgba(255,255,255,0.5);
  }
}
