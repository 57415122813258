.spare-part-shop {
  &__heading {
    position: absolute;
    top: -90px;
    left: calc(12.4% + 30px);
    @include mobiletablet {
      position: static;
    }

    h1 {
      color: $text-green;
      margin: 0;
      font-size: $font-size-heading;
      font-family: $font-bold;
      line-height: 1;
      @include mobiletablet {
        font-size: 14px;
      }
    }
  }

  &__introduction-container {
    background-color: $background-light-grey2;
    padding: 45px 0px;
    @include mobiletablet {
      margin: 0;
    }
  }
  
  &__filter-header{
    margin: 10px 0 15px 0;
    font-size: 25px;
    font-family: NewRailAlphabet-bold;
    line-height: 1;
  }
  &__spare-search-button-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;

    li{
      display: inline-block;
      margin-right: 4px;
      margin-bottom:8px;
      background-color:transparent;
      border: 2px solid $background-dark-grey;
      padding: 10px 50px;

      @include mobile{
        box-sizing: border-box;
        margin-right:0px;
        margin-bottom:10px;
        width: 100%;
        padding:10px;
        text-align: center;
      }
    }

    li.selected{
      background-color:$background-black;
      border-color: $background-black;
      a{
        color:$text-white;
      }
    }
  }
  &__spare-type-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 20px;

    li{
      display: inline-block;
      margin-right: 4px;
      margin-bottom:8px;
      background-color:$background-light-grey;
      padding: 5px;
    }

    li.selected{
      background-color:$background-black;
      a{
      color:$text-white;
    }
    }
  }

  &__common-problem-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 20px;

    li{
      display: inline-block;
      margin-right: 4px;
      margin-bottom:8px;
      background-color:$background-light-grey;
      padding: 5px;
      position:relative;
    }

    .info { 
      display: inline-block;
      content: '';
      color:$background-light-grey;
      -webkit-border-radius: 1.375rem;
      border-radius: 1.375rem;
      height: 1rem;
      width: 1rem;
      margin-left: 0.5rem;
      background-color: $background-black;
      text-align: center;
    }

    li.selected{
      background-color:$background-black;
        a{
        color:$text-white;
      }

      .info {
        color:$background-black;
        background-color: $background-light-grey;
      }
    }
 
  .tool-tip {
    display: none;
    position: absolute;
    background-color: $background-black;
    color: $text-white;
    padding: 5px;
    right: 14px;
    padding: 5px;
    list-style: none;
    margin: 10px 0 0;
    z-index:1;
    border-radius:3px 0 3px 3px;
    width:150px;
    @include mobile{
      left:0;
      right:unset;
    }
    
    &:after {
      @include mobile{
        display: none;
      }
      content: "";
      display: block;
      border-top: 12px solid transparent;
      border-right: 12px solid $background-black;
      position: absolute;
      top: -12px;
      right: 0;
    }
    li {
      font-size: 13px;
      line-height: 150%;
      background-color: $background-black;
      margin:0;
    }
  }
  
  }

  &__introduction-text {
    //margin-bottom: $column-gap;
    max-width: calc(#{$column-width-proc} * 3);

    @include tablet {
      max-width: calc(#{$column-width-proc} * 4);
    }
    @include mobile {
      max-width: calc(#{$column-width-proc} * 8);
    }
  
  }

  &__introduction-text-strong {
    margin-bottom: $column-gap;
    max-width: calc(#{$column-width-proc} * 3);

    @include tablet {
      max-width: calc(#{$column-width-proc} * 4);
    }
    @include mobile {
      max-width: calc(#{$column-width-proc} * 8);
    }
  
  }
  &__introduction {
    margin-left: calc(#{$column-width-proc} - #{$column-margin});

    @include mobiletablet {
      margin-left: 0;
    }
  }
  &__search-wrapper {
    display: flex;
    .btn {
      background: transparent;
      text-align: left;
      &:hover {
        background-color: $text-black;
      }
      // min-width: 130px;
    }
  }
  &__input-container {
    margin-bottom: 30px;
    .search-input-div{
      @include desktop {
        border-bottom: 1px solid black;
      }
      @include mobile {
        margin-bottom: 10px;
        border-bottom: 1px solid black;
      }
      @include tablet {
        border-bottom: 1px solid black;
      }
      flex: 1;

    }
   
    .search-input {
      border: none;
      margin-top: 5px;    
      @include mobile {
        border-bottom: 1px solid black;
      }
      @include desktop  {
          position: relative;          
     
      }
      @include tablet  {
        position: relative; 
       
      }

      width: 100%;
      padding: 5px;
      background-color: transparent;
      &:focus {
        outline: none;
      }
      // &--clear {
      //   display: flex;
      //   align-items: center;
      //   width: 30px;
      //   margin-left: -30px;
      //   justify-content: center;
      // }
    }
  }

  .product-page__product-list {
    border-top: none !important;
    h2 {
      margin: 0 0 $column-gap 0;
      @include desktop {
        font-size: 45px;
      }      
      font-family: $font-bold;
      line-height: 1;
    }
    .return-link {
      align-self: center;
      position: relative;
      display: flex;
      white-space: nowrap;
      &:after {
        content: "\2192";
        padding-left: 8px;
        display: block;
        margin-top: -2px;
        font-size: 16px;
        font-family: "NewRailAlphabet-medium";
      }
    }
  }

  &__search-container {
    > ul {
      margin: $column-gap 0;
      padding: 0;
      list-style: none;
      // margin-left: calc(-12.4% + #{$column-gap});
      // margin-left: calc(-35% + #{$column-margin});

      li {
        margin: 0;
        font-size: 45px;
        font-family: $font-bold;
        line-height: 1;
        a {
          color: $text-black;

          &:focus,
          &:hover,
          &:active {
            outline: 0;
          }
          &:hover {
            color: $text-green;
          }
        }

        &.active {
          > a {
            &:before {
              content: "/\2192/";
              display: inline;
              margin-right: 5px;
              font-family: $font-icon;
              font-weight: normal;
              opacity: 1;
            }
          }
        }
      }
      > li {
        > span {
          color: $text-green;
        }
      }
    }
  }
}
