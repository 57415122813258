.customer-service{
    max-width: 700px;
    margin: 0 auto;
}
.button-type
{
    padding: 20px 20%;
    margin: 0 0 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    @media screen and (max-width:700px){
        padding: 20px;
    }
    
}

.button-happy
{
    float: left;
    background:  url("~@/../../Pdf/images/smiling-face.png") no-repeat left top;
    display: flow-root;
    width: 50px;
    height: 50px;
    background-size: cover;
    border: none;
}

.button-sad
{
    float: Right;
    background: url("~@/../../Pdf/images/sad-face.png") no-repeat left top;
    display: flow-root;
    background-size: cover;
    width: 50px;
    height: 50px;
    border: none;
}
.date-outer{
    flex-direction: row-reverse;

    .report-dates{
        max-width: 200px;
        margin-left: auto;

        .download-report{
            color: #38d430;
            margin-top: 20px;
            display: block;
            text-align: right;
            cursor: pointer;
        }
    }
}
