$end-column: 84px;
.environmentalmarking {
  margin-bottom: 160px;
  @include mobile {
    margin-bottom: 100px;
  }
  button {
    line-height: $line-height;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    text-align: left;
    padding-top: 3px;
    &:after {
      content: "\2192";
      font-family: $font-icon;
      margin-left: 3px;
    }
    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }
  }
  a {
    line-height: $line-height;
    cursor: pointer;
    &:after {
      content: "\2192";
      font-family: $font-icon;
      margin-left: 3px;
    }
    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }
  }

  .banner {
    margin-bottom: calc(#{$line-margin} * 13);
    margin-top: -50px;

    @include mobiletablet {
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
    }

    &__text {
      position: relative;
      top: 0;

      h1 {
        color: $text-black;
      }
      @include desktop {
        left: auto;
        * {
          margin-left: calc(-35% + 15px) !important;
        }
      }
      @include mobiletablet {
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        max-width: 470px;
      }
    }
  }
  &__table {
    @include mobiletablet {
      margin-top: calc(#{$line-margin} * 2);
    }
  }

  &__search {
    padding: 0;
  }

  .form-group {
    border-bottom: none;
  }

  &__search-form {
    cursor: pointer;

    input {
      padding-bottom: 3px;
      padding-top: 3px;
      background-image: url("~@/assets/tapwell/img/search.svg");
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: left center;
      padding-left: 20px;
      width: calc(100% - 20px);
      border-bottom: 1px solid #000;
    }
  }

  &__intro {
    max-width: 470px;
    margin-left: 12.4%;
    margin-bottom: calc(#{$line-margin} * 4);
    padding-left: 7px;
    font-size: $font-size-menu;
    position: relative;
    @include mobiletablet {
      margin-left: 0;
      font-size: $font-size-menu-mob;
      padding: 0;
    }

    p {
      margin: 0;
    }
  }

  &__section {
    display: flex;
    flex-flow: row wrap;
    padding: $line-margin $column-margin;
    position: relative;
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 15px;
      border-bottom: 1px solid $border-black;

      @include mobiletablet {
        display: none;
      }
    }

    &--2 {
      padding: $line-margin $column-margin;
      flex: 1 1 0px;
    }
    &--3 {
      @include mobiletablet {
        border-bottom: 0;
      }
    }
    &--4 {
      justify-content: center;
      div {
        width: 100%;
        max-width: $end-column;
        @include mobiletablet {
          max-width: none;
        }
      }
      p {
        margin: 0;
        width: 100%;
      }
      a {
        width: 100%;
        @include mobiletablet {
          width: auto;
        }
      }
      &:after {
        left: 0;
        width: calc(100% - 15px);
      }
      flex: 1 1 0px;
    }
  }
  &__row {
    &--heading {
      font-family: $font-bold;
      .environmentalmarking {
        &__section {
          &--1 {
            width: calc(50% - #{$column-margin});
            padding-left: calc(12.4% + #{$column-margin});
            p {
              margin: 0 $column-margin 0 0;
            }
          }
        }
      }
    }
    &--body {
      .environmentalmarking {
        &__section {
          &--1 {
            border-bottom: 0;
            &:after {
              left: auto;
              width: 50%;
              right: -15px;
            }
            .environmentalmarking {
              &__date {
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  bottom: -$line-margin;
                  border-bottom: 1px solid $border-black;
                  width: 100%;
                  left: $column-margin;

                  @include mobiletablet {
                    left: 0;
                  }
                }
              }
            }
          }
          &--2 {
            @include mobiletablet {
              padding: $line-margin 0;
              margin: 0 $column-margin;
              max-width: none;
              flex-basis: auto;
              width: calc(100% - #{$column-gap});
            }
          }
          &--3 {
            display: flex;
            flex-flow: row;
            @include mobiletablet {
              padding: $line-margin 0;
              margin: 0 $column-margin;
              max-width: none;
              flex-basis: auto;
              width: calc(100% - #{$column-gap});
              justify-content: flex-end;
            }
          }
          &--4 {
            @include mobiletablet {
              padding: 0 0 calc(#{$line-margin} * 2) 0;
              margin: 0 $column-margin;
              max-width: none;
              flex-basis: auto;
              width: calc(100% - #{$column-gap});
            }
          }
        }
      }
      ~ .environmentalmarking__row--body {
        .environmentalmarking__label {
          font-size: 0;
        }
      }
    }
    @include mobiletablet {
      position: relative;
      &:after {
        content: "";
        width: calc(100% - #{$column-gap});
        position: absolute;
        top: 0;
        left: $column-margin;
        border-top: 1px solid $border-black;
      }
    }
    &--head {
      &:after {
        display: none;
      }
    }

    &:first-of-type {
      &:after {
        display: none;
      }
    }
    &.hidden-orderinfo {
      @include mobiletablet {
        display: none;
      }
      > div {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &__item {
    ~ .environmentalmarking__item {
      @include mobiletablet {
        position: relative;       

        &:after {
          content: "";
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-top: 1px solid $border-black;
        }
      }
    }
    &.open {
      .hidden-environmentalmarkinginfo {
        display: flex;
      }
      .environmentalmarking__row--showmore {
        display: none;
      }
    }
  }
  &__label {
    margin: 0 $column-margin 0 0;
    width: calc(50% - #{$column-margin});
    p {
      margin: 0;
    }
    @include mobiletablet {
      width: 100%;
      margin: 0;
    }
  }
  &__value {
    margin: 0;
    width: 98%;
    p {
      margin: 0;
    }
    span{
      width: 20px;
      height: 20px;
      display: block;
      background: url("~@/assets/tapwell/img/tick.png") no-repeat left top;
      background-size: cover;
      margin-top: 0px;
    }
  }
  &__value-long {
    margin: 0;
    width: 60%;
    p {
      margin: 0;
    }
    span{
      width: 20px;
      height: 20px;
      display: block;
      background: url("~@/assets/tapwell/img/tick.png") no-repeat left top;
      background-size: cover;
      margin-top: 0px;
    }
  }
  &__value-short {
    margin: 0;
    width: 40%;
    p {
      margin: 0;
    }
    span{
      width: 20px;
      height: 20px;
      display: block;
      background: url("~@/assets/tapwell/img/tick.png") no-repeat left top;
      background-size: cover;
      margin-top: 0px;
    }
  }
  &__status-kolli {
    margin: 0 0 0 20px;
    p {
      margin: 0;
    }
    @include mobiletablet {
      margin: 0;
    }
  }
  &__date {
    width: calc(50% - #{$column-margin});
    p {
      margin: 0 0 0 $column-margin;
      @include mobiletablet {
        margin: 0;
      }
    }
    @include mobiletablet {
      width: 100%;
    }
  }
  &__action {
    float: right;
  }
  &__marking {
    margin: 0 $column-margin 0 0;
    width: calc(60% - 10px);
    word-break: break-word;
    p {
      margin: 0;
      padding-left: 5px;
    }
    span{
      width: 20px;
      height: 20px;
      display: block;
      background: url("~@/assets/tapwell/img/tick.png") no-repeat left top;
      background-size: cover;
      margin-top: 0px;
    }

    @include mobiletablet {
      width: 100%;
      margin: 0;
    }
  }
  &__nr {
    margin: 0 0 0 $column-margin;
    max-width: calc(40% - 25px);
    @include mobiletablet {
      max-width: none;
    }
    p {
      margin: 0;
      @include mobiletablet {
        width: 100%;
      }
    }
    a {
      word-break: break-word;
    }
    @include mobiletablet {
      width: 100%;
      margin: 0;
    }
  }

  &__btn {
    @include mobiletablet {
      padding: $line-margin;
      text-align: right;
      background: $background-white;
      color: $text-black;
      border: 1px solid $border-black;
      display: block;
      width: 100%;
      margin-bottom: $line-margin;
      &:after {
        display: none;
      }
      &:hover {
        background: $background-black;
        color: $text-white;
        border: 1px solid $background-black;
        outline: 0;
      }
      &:focus,
      &:active {
        outline: 0;
      }
    }
  }  
}
