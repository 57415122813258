.company-information {
    > div {
        &:last-child {
            margin-bottom: calc(#{$line-margin} * 10);
        }
    }
  &__header {
    max-width: calc(26.5% - 7px);
    margin-left: 12.4%;
    padding-left: 7px;
    margin-top: calc(150px + #{$header-height});
    font-size: $font-size-menu;
    position: relative;

    @include mobiletablet {
      margin-top: 0;
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      font-size: $font-size-menu-mob;
      max-width: 100%;
    }
  }
  &__body {
  
      &--1 {
        margin-top: calc(#{$line-margin} * 5);
      }
      &--2 {
        margin-top: calc(#{$line-margin} * 1);
        @include mobiletablet {
          margin-top: calc(#{$line-margin} * 3);
        }
      }
      &--3 {
        margin-top: calc(#{$line-margin} * 1);
      }
  }
  &__header-section {
    &--top {
      padding-bottom: $line-margin;
      border-bottom: 1px solid $border-black;
    }
    &--bottom {
      padding-top: $line-margin;
    }
    p {
      margin: 0;
      width: 100%;
    }
  }

  &__section {
    &--1 {
      max-width: 12.6%;
      padding-top: $line-margin;
      padding-bottom: $line-margin;
      position: relative;

      @include mobiletablet {
        max-width: 100%;
        width: 100%;
      }
      &:before {
        content: "";
        border-top: 1px solid $border-black;
        top: 0;
        width: calc(100% - #{$column-margin});
        left: $column-margin;
        position: absolute;

        @include mobiletablet {
          display: none;
        }
      }
    }
    &--2 {
      flex-flow: row wrap;
      justify-content: space-between;
      display: flex;
      max-width: none;
      min-width: 55%;
      max-width: 55%;
      flex-basis: auto;
      position: relative;
      &:before {
        content: "";
        border-top: 1px solid $border-black;
        top: 0;
        width: calc(100% - #{$column-margin});
        left: 0;
        position: absolute;
        @include mobiletablet {
            width: calc(100% - #{$column-gap});
            left: $column-margin;
          }
      }
      @include mobiletablet {
        min-width: 100%;
        width: 100%;
        overflow: hidden;
      }
      &--other-users {
        padding-bottom: calc(#{$line-margin} * 11);
        @include mobiletablet {
          padding-bottom: 0;
        }
      }
      &--add {
        flex-flow: column;
      }
    }

    &--3 {
      max-width: none;
      flex-basis: auto;
      margin-left: 8%;
      width: 24.4%;
      flex-basis: auto;

      @include mobiletablet {
        width: 100%;
        margin-left: 0;
      }
      .btn {
        @include mobiletablet {
            margin-top: $line-margin;
          }
        
      }
    }
  }

  &__label {
    p {
      margin: 0;
    }
  }
  &__remove {
    position: relative;
    cursor: pointer;
    margin-top: -$line-margin-half;
    .line {
      background-color: $border-black;
      height: 1px;
      width: 15px;
      display: block;
      margin: 5px 0;
      &:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
      }
      &:nth-child(2) {
        transform: translateY(-6px) rotate(-45deg);
        margin-top: 11px;
      }
    }
  }
  &__btn {
    width: calc(50% - #{$column-margin});
    margin: calc(#{$line-margin} * 3) 0 calc(#{$line-margin} * 5);
    @include mobiletablet {
      width: 100%;
      margin: calc(#{$line-margin} * 1) 0 calc(#{$line-margin} * 1);
    }
    &--other-users {
      position: absolute;
      bottom: 0px;
      width: calc(50% - #{$line-margin} * 3);
      @include mobiletablet {
        position: relative;
        width: 100%;
      }
    }
  }
  &__required-message {
    margin: 0 0 0 -$column-margin;
    color: $text-green;
    position: absolute;
    right: -135px;
    top: 0px;
    padding: $line-margin $column-margin;
  }
  &__validation {
    color: $text-red;
  }
  &__form {
    width: calc(50% - #{$column-margin});
    @include mobiletablet {
      width: 100%;
    }
    &:first-child {
      margin-right: $column-margin;
      @include mobiletablet {
        margin-right: 0;
      }
    }
    &:last-child {
    //  margin-left: $column-margin;
      @include mobiletablet {
        margin-left: 0;
      }
    }
    &--address {
      &:nth-child(2) {
        margin-left: $column-margin;
        @include mobiletablet {
          margin-left: 0;
        }
      }
        &:last-child {
          margin-right: $column-margin;
          margin-left: 0;
          @include mobiletablet {
            margin-right: 0;
            margin-left: 0;
          }
        
      }
    }

  }
  .form-group {
    padding: $line-margin 0;
    border-bottom: 1px solid $border-black;
    @include mobiletablet {
        border-bottom: 0;
        border-top: 1px solid $border-black;

    }
    span {
      color: $text-green;
      &.checkmark {
        color: $text-black;
      }
      &.company-information__validation {
        span {
          color: $text-red;
        }
      }
    }
    label {
      display: inline;
      font-family: $font-bold;
      line-height: $line-height;
      color: $text-black;
    }
    input {
      width: 100%;
      padding: 0;
      line-height: $line-height;
      border: 0;
      font-family: "NewRailAlphabet-light";
      font-size: $font-size-default;    

      &:focus,
      &:hover,
      &:active {
        outline: 0;
      }
    }
    select {
      font-family: "NewRailAlphabet-light";
    }
    &.remove {
      border-bottom: 0;
      label {
        font-family: "NewRailAlphabet-light";
      }
        @include mobiletablet {
            border-top: 0;
            padding-top: 0;
            margin-bottom: $line-margin;
        }
    }
  }
}
