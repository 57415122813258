html {
  scroll-behavior: smooth;
  &.menu-open {
    overflow: hidden;
    position: fixed;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.mega-menu-open {
    overflow: hidden;
    position: fixed;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.search-modal-open {
    overflow: hidden;
    position: fixed;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .b2c-header {
      height: 0;
      > div {
        height: 0;
      }
    }
  }
}
body {
  background: $background-white;
  font-family: "NewRailAlphabet-light";
  margin: 0;
  position: relative;
  font-size: $font-size-default;

  &.menu-open {
    overflow: hidden;
    position: fixed;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.mega-menu-open {
    overflow: hidden;
    position: fixed;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.search-modal-open {
    overflow: hidden;
    position: fixed;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.mobile-prevent-scroll{
    @include mobiletablet{
      height: 100vh;
      overflow-y: hidden;
    }
  }

  @include mobiletablet {
    font-size: $font-size-default-mob;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: NewRailAlphabet-thin;
  line-height: $line-height;
}
p {
  line-height: $line-height;
}

a {
  color: $text-black;
  text-decoration: none;
  cursor: pointer;
}
img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
  &:disabled {
    background: transparent;
    color: $text-black;
    opacity: 0.5;
  }
}

.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loader {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 5px solid $background-light-grey;
  border-top-color: #cccccc;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}


.sparepart-loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sparepart-loader {
  width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: transparent;
    border: 25px solid #f2f2f2;
    border-top-color: #f7f7f7;
    border-left-color: #f7f7f7;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}


.form-group {
  padding: $line-margin 0;
  border-bottom: 1px solid $border-black;
  @include mobiletablet {
    border-bottom: 0;
    border-top: 1px solid $border-black;
  }
  label {
    display: block;
    font-family: $font-bold;
    line-height: $line-height;
  }
  input {
    width: 100%;
    padding: 0;
    line-height: $line-height;
    border: 0;
    font-family: "NewRailAlphabet-light";
    font-size: $font-size-default;

    &:focus,
    &:hover,
    &:active {
      outline: 0;
    }
  }
}
select {
  -webkit-appearance: none;
  border-radius: 0;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }
  option {
    background: $background-white;
  }
  &::-ms-expand {
    display: none;
  }
}
.form-group {
  &.select {
    position: relative;
    &:after {
      content: "\2BC6";
      position: absolute;
      right: 0;
      font-size: 10px;
      bottom: 15px;
    }
  }
}
.radio-container {
  line-height: $line-height;
  display: block;
  position: relative;
  padding-left: $column-gap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: "X";
    top: 1px;
    left: 8px;
    width: 8px;
    height: 8px;
  }
}
#productList {
  min-height: 300px;
}

.noresult {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.emptysearch {
  min-height: 300px !important;
}
[data-tooltip] {
  position: relative;
  &:before {
    position: absolute;
    content: attr(data-tooltip);
    opacity: 0;
    display: none;
    pointer-events: none;
    background: #1f1c17;
    color: #ffffff;
    padding: 5px 10px;
    bottom: calc(100% + 3px);
    font-family: "NewRailAlphabet-light";
    transform: translateX(-50%);
    left: 50%;
    text-transform: uppercase;
    font-size: 10px;
  }
  &:after {
    content: "";
    opacity: 0;
    pointer-events: none;
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #1f1c17;
    bottom: 100%;
    transform: translateX(-50%);
    left: 50%;
  }
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after  {
  opacity: 1;
  display: block;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  padding: 0px 0px 0px calc(#{$column-margin} + 4px);
  &:before {
    content: "[";
    position: absolute;
    left: 0;
  }
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}
#app {
  max-width: $body-width;
  margin: 0 auto;
  padding: 0 $body-padding $body-padding;
  position: relative;

  @include mobiletablet {
    padding: 0 $column-gap $column-margin $column-gap;
  }
  &.b2c {
    position: static;
    padding: 0 0 $body-padding;
    max-width: none;
    margin: auto;
    @include mobiletablet {
      padding: 0 0 $column-gap;
    }

    .container-padding {
      padding: 0 $body-padding;
      max-width: $body-width;
      margin: 0 auto;
      position: relative;
      @include mobiletablet {
        padding: 0 $column-gap;
      }

      .container-padding {
        padding: 0;
      }
    }

    .checkout {
      max-width: 1600px;
      margin: 0 auto;
      padding: 0 55px 55px;
    }
  }
}
.main-content {
  margin-top: calc(#{$header-height} + #{$body-padding});
  min-height: 50vh;
  &.scroll-down {
    // margin-top: 0;
  }

  @include mobiletablet {
    margin-top: calc(#{$line-margin} * 7);
  }
}
.bold {
  font-family: $font-bold !important;
}

.btn {
  @include btn-default($background, $text);

  &:hover {
    @include btn-default($background-black, $text-white);
    border-color: $background-black;
    outline: 0;
  }
  &:focus,
  &:active {
    outline: 0;
  }
}
.btn--black {
  @include btn-default($text, $background);
  &:hover {
    @include btn-default($text-white, $background-black);
  }
}
.hide {
  display: none;
}

@include mobiletablet {
  .hide-mobile {
    display: none !important;
  }
}
@include desktop {
  .hide-desktop {
    display: none !important;
  }
}

input {
  &::-webkit-input-placeholder {
    font-family: "NewRailAlphabet-light" !important;
    color: $text-black;
    opacity: 1;
  }
  &:-moz-placeholder {
    font-family: "NewRailAlphabet-light" !important;
    color: $text-black;
    opacity: 1;
  }
  &::-moz-placeholder {
    font-family: "NewRailAlphabet-light" !important;
    color: $text-black;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    font-family: "NewRailAlphabet-light" !important;
    color: $text-black;
    opacity: 1;
  }

  &::placeholder {
    font-family: "NewRailAlphabet-light" !important;
    color: $text-black;
    opacity: 1;
  }
}
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
  font-size: $font-size-menu;
  margin: calc(#{$line-margin} * 3) 0 $line-margin;
  justify-content: flex-end;

  @include mobiletablet {
    font-size: $font-size-menu-mob;
    justify-content: flex-start;
  }
  li {
    padding: $line-margin 0 $line-margin $line-margin;

    &:after {
      content: "|";
      padding-left: $line-margin;
    }
    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
  a {
    font-size: $font-size-menu;

    @include mobiletablet {
      font-size: $font-size-menu-mob;
    }
    &:after {
      display: none;
    }
  }
  &__link {
    &--current {
      font-family: $font-bold;

      &:after {
        font-family: NewRailAlphabet-light;
      }
    }
    &--disabled {
      opacity: 0.4;
      &:after {
        opacity: 1;
      }
    }
  }
}

.product-page__banner__add .msg > a{
  color:red;
  text-decoration: underline;
}


