@font-face {
  font-family: "NewRailAlphabet-light";
  src: url( "~@/assets/tapwell/fonts/NewRailAlphabet-LightWEB.eot");
  src: url("~@/assets/tapwell/fonts/NewRailAlphabetWeb-Light.woff") format("woff"),
  url("~@/assets/tapwell/fonts/NewRailAlphabetWeb-Light.woff2") format("woff2"),
    url("~@/assets/tapwell/fonts/NewRailAlphabet-LightWEB.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NewRailAlphabet-thin";
  src: url("~@/assets/tapwell/fonts/NewRailAlphabet-OffWhiteWEB.eot");
  src: url("~@/assets/tapwell/fonts/NewRailAlphabetWeb-OffWhite.woff") format("woff"),
     url("~@/assets/tapwell/fonts/NewRailAlphabetWeb-OffWhite.woff2") format("woff2"),
    url("~@/assets/tapwell/fonts/NewRailAlphabet-OffWhiteWEB.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NewRailAlphabet-bold";
  src: url("~@/assets/tapwell/fonts/NewRailAlphabet-BoldWEB.eot");
  src: url("~@/assets/tapwell/fonts/NewRailAlphabetWeb-Bold.woff") format("woff"),
  url("~@/assets/tapwell/fonts/NewRailAlphabetWeb-Bold.woff2") format("woff2"),
    url("~@/assets/tapwell/fonts/NewRailAlphabet-BoldWEB.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NewRailAlphabet-medium";
  src: url("~@/assets/tapwell/fonts/NewRailAlphabet-WhiteWEB.eot");
  src: url("~@/assets/tapwell/fonts/NewRailAlphabetWeb-White.woff") format("woff"),
  url("~@/assets/tapwell/fonts/NewRailAlphabetWeb-White.woff2") format("woff2"),
    url("~@/assets/tapwell/fonts/NewRailAlphabet-WhiteWEB.svg") format("svg");
  font-weight: normal;
  font-style: normal; 
}

