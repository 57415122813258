.product-offer {
  margin: 0 0 calc(#{$line-margin} * 20);
  @include mobiletablet {
    margin: 0 0 calc(#{$line-margin} * 8);
  }
  &__heading {
    h3 {
      font-size: $font-size-heading;
      color: $text-green;
      font-family: $font-bold;
      margin: 0;
      line-height: 1;
      @include mobiletablet {
        font-size: $font-size-heading-mob;
      }
    }
  }
  &__intro {
    padding-left: calc(12.4% + #{$column-margin});
    @include mobiletablet {
      padding-left: $column-margin;
      word-break: break-all;
    }
    h3 {
      font-size: $font-size-heading;
      color: $text-black;
      font-family: $font-bold;
      margin: 0;
      line-height: 1;
      @include mobiletablet {
        font-size: $font-size-heading-mob;
      }
    }
  }
  &__product-list {
    justify-content: flex-start;
  }
  &__product {
    margin: $column-gap 0 calc(#{$column-gap + 8px});
    @include mobiletablet {
      margin: $column-gap 0 0;
    }
    > div {
      height: 100%;
      position: relative;
    }
  }
  &__img {
    height: 250px;
    display: block;
    background-color: $background-light-grey;
    padding: $line-margin-half 3em;
    div {
      background-size: contain;
      height: 100%;
      width: auto;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &__product-info {
    margin: $line-margin 0 0;
    height: calc(100% - 40vh - #{$line-margin});
    h5 {
      margin: 0;
      font-size: $font-size-default;
      font-family: "NewRailAlphabet-light";
      font-weight: normal;
      @include mobiletablet {
        font-size: $font-size-default-mob;
      }
    }
    h4 {
      margin: 0;
      font-size: $font-size-default;
      font-family: "NewRailAlphabet-light";
      font-weight: normal;
      @include mobiletablet {
        font-size: $font-size-default-mob;
      }
    }
    p {
      font-size: $font-size-default;
      margin: 0;
      @include mobiletablet {
        font-size: $font-size-default-mob;
      }
    }
  }
  &__showmore {
    font-size: $font-size-default;
    position: relative;
    cursor: pointer;
    line-height: 1.4;

    &:after {
      content: "\2192";
      display: block;
      position: absolute;
      right: -25px;
      top: -3px;
      font-size: 16px;
      font-family: $font-icon;
    }
    @include mobiletablet {
      font-size: $font-size-default-mob;
    }
  }
  &__price {
    padding-top: $line-margin;
    margin-top: $line-margin;
    border-top: 1px solid $border-black;
  }
  &__clear {
    font-family: $font-bold;
  }
  &__variants {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding-top: $line-margin;
    padding-bottom: calc(#{$line-margin} * 2);

    li {
      margin: 0 $line-margin-half 0 0;
      line-height: $line-height;
      &.active {
        font-family: $font-bold;
      }
      a {
        white-space: nowrap;
        cursor: pointer;
        &.selected {
          font-family: $font-bold;
        }
      }
      &:after {
        content: "|";
        margin-left: $line-margin-half;
        font-weight: normal;
      }
      &:last-of-type {
        &:after {
          content: "";
        }
      }
    }
  }
  &__buy-btn {
    position: absolute;
    bottom: calc(-#{$line-margin} * 4);
    @include mobiletablet {
      position: relative;
      bottom: 0;
    }
  }
}
