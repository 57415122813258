@import "~@/styles/constants.scss";

























#modal {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    overflow: hidden;

    .modal {
        &-overlay {
            background: var(--color-black);
            bottom: 0;
            left: 0;
            opacity: 0.3;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 1;
        }

        &-dialog {
            background-color: var(--color-white);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            margin: 50px auto;
            position: relative;
            width: fit-content;
            z-index: 2;
        }

        &-header {
            align-items: flex-start;
            display: flex;
            justify-content: flex-end;
            padding: 20px 20px 10px;

            .close {
                background: transparent;
                cursor: pointer;
                border: none;
                height: 30px;
                width: 30px;
            }
        }

        &-body {
            align-items: stretch;
            display: flex;
            flex-direction: column;
            overflow: auto;
            padding: 10px 20px 10px;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
