@import "~@/styles/constants.scss";





































































































































@import "./ProductDetail.scss";

.product__info {
    ul {
        margin: 5px 0 0 0;
    }
}
