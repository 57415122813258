.newsletter-banner{
    background-color: $background-black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-green;
    min-height: 30px;
    
    &__text{
        cursor: pointer;
        text-transform: uppercase;
        font-size: 12px;
        @include tabletdesktop{
            font-size: 14px;
        }
    }
}