.parallaxvideo-block {
  height: 100vh;
  position: relative;
  margin-top: calc(-#{$header-height} - #{$body-padding});
  overflow: hidden;
  pointer-events: none;
  @include mobiletablet {
    margin-top: -70px;
  }
  .pre-load-image{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .banner {
    max-width: $body-width;
    margin: 0 auto;
    padding: 0 $body-padding $body-padding;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: ease-out 5s;

    @include mobiletablet {
      top: 0px;
    }
    &__text {
      top: -90px;
      @include mobiletablet {
        top: -20px;
      }
      a {
        pointer-events: all;
      }
    }
  }

  .active:not(.page-moveDown-enter-active) {
    .banner {
      top: 145px;
      transition: ease-out 1s;
      opacity: 1;
      @include mobiletablet {
        top: 90px;
      }
    }
  }


  .embed-responsive {
    width: 100%;
    height: 100vh;
    left: 0;
    position: relative;
    display: block;
    padding: 0;
  }
  .embed-responsive .embed-responsive-item,
  .embed-responsive embed,
  .embed-responsive iframe,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  &__container {
    position: absolute;
    left: 0;
    right: 0;
    // margin-top: -110px;
  }
  &__video {
    position: absolute;
    left: 0;
    right: 0;
  }

  &__videofile {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: -1;

    @supports (-ms-ime-align: auto) {
      object-fit: none;
      height: auto !important;
      width: auto !important;
      min-width: 100vw;
      min-height: 100vh;
      position: relative !important;
    }

    @supports (-ms-ime-align: auto) and (-webkit-text-stroke: initial) {
      object-fit: none;
      height: auto !important;
      width: auto !important;
      min-width: 100vw;
      min-height: 100vh;
      position: relative !important;
    }

    @media screen and (-ms-high-contrast: none) {
      object-fit: none;
      height: auto !important;
      width: auto !important;
      min-width: 100vw;
      min-height: 100vh;
      position: relative !important;
    }
  }
  &__img {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .page-moveUp-enter-active {
    animation: page-moveUp-enter 2.5s ease both !important;
  }

  .page-moveUp-leave-active {
    animation: page-moveUp-leave 2.5s ease both !important;
  }

  .page-moveDown-enter-active {
    animation: page-moveDown-enter 2.5s ease both !important;
  }

  .page-moveDown-leave-active {
    animation: page-moveDown-leave 2.5s ease both !important;
  }
}
