
.configurator-page{
    padding-top: 10px;

    @include desktop{
        padding-top: 0;
    }

    &__steps-container{
        border-top: 1px solid $border-black;
    }
    
    &__step-header{
        font-family: "NewRailAlphabet-bold";
        @include mobiletablet{
            font-weight: 700;
            position: sticky;
            top: -1px;
            padding-top: 1px;         
            z-index: $header-z-index+1;
            &.isSticky{
                padding-top: 6px;
                padding-bottom: 2px;
                background-color: $background-white;
            }
        }

        @include desktop{
            font-size: 44px;        
        }
    }
    

    & .shower-configure__icon {
        z-index: 1;
    }

    & .product-page__product-list{
        border-top: none;
    }

    &__image-container{
        width: 100%;
        max-width: 1600px;
        margin: auto;
        position: relative;

        >img{
            width: 100%;
            object-fit: cover;
            max-height: 80vh;
            @include desktop{
                max-height: 500px;
            }
        }

        & .configurator-page__title{        
            position: absolute;  
            top: 0;          
            left: 30px;
            @include tablet{
                max-width: 75%;
            }
            @include desktop{
                left: 49px;
                max-width: 50%;
            }
            h1,h2{
                font-family: "NewRailAlphabet-bold";                
            }
        }
    }

    &__text{
        margin: 24px 0;
        font-family: "NewRailAlphabet-light";
        @include desktop{
            max-width: 47%;
            margin: 26px 5px 44px 200px;        
        }
    }

    &__step{

        &-1{
            > .configurator-page__step-header{
                margin-bottom: 0;
                @include desktop{
                    margin-bottom: 6px;
                }
            }
        }

        &-2{        
            
            > .configurator-page__step-header{
                margin-top: 60px;
                margin-bottom: 140px;
                @include desktop{
                    margin-top: 80px;
                    margin-bottom: 170px;
                }
                opacity: 0.1;
            }

            &--open{
                min-height: 150px;
                > .configurator-page__step-header{
                    opacity: 1;
                    margin-bottom:  1rem;

                    @include desktop{
                        margin-bottom:  36.5px;
                    }
                }
                & .configurator-page__shower-config-container{                    
                    position: relative;
                    
                    // shower-configurator overrides for configurator-page
                    & .shower-configurator{
                        position: unset;  
                        @include desktop{
                            height: 850px;
                        }

                        &__loader-container{
                            @include mobiletablet{
                                top: 0;
                                right: 50%;
                                transform: translateX(50%);
                            }
                        }
                    }
                }
            }
        }
    }

    

    &__tile{
        &-img{
            position: relative;
                
            &.product-page__product__img{
                cursor: default;    
            }

            & .blur{
                position: absolute; 
                    top: 0;
                    left: 0;                
                    width: 100%;
                    height: 40%;                
                    background-color: $background-light-grey;
                    opacity: 0.8;
            }
        }

        &-description{
            display: flex;
            margin-top: 8px;
            justify-content: space-between;            
            align-items: center;
            gap: 8px;
            
            @include desktop{
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }

        &-category{
            font-family: "NewRailAlphabet-bold";
            display: block;
            font-weight: 700;
        }

        &-btn{
            display: block;
            @include btn-default($text-white, $background-black);
            padding: 6px 30px;
            width: unset;
            
            &--selected{
                background-color: $text-black;
                color: $text-white;
            }
        }        
    }
}

// Header z-index fix when menu is open

body.menu-open{
    @include mobile{
        & .configurator-page__step-header{
            z-index: $header-z-index - 1;
        }
    }
}