.newsletter-modal {
    background-color: #eff2f7;
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: calc(#{$header-z-index} + 1); /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    font-size: 12px;

    @include desktop {
        font-size: $font-size-default;
    }

    &--open {
        display: block;
    }

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        transform: translate(-50%, -50%);
        background-color: $background-white;
        max-height: 93vh;
        min-height: 200px;
        overflow-y: auto;

        @include tablet {
            max-height: 630px;
        }

        @include desktop {
            width: 70%;
            height: 76%;
            min-width: 840px;
        }
    }

    &__inner-container {
        position: relative;
        background-color: $background-light-grey;
        margin: 17px;
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 34px);
        height: 100%;
        overflow-y: auto;

        @include mobiletablet{            
            background-image: none !important;
        }

        @include desktop {            
            background-position: right;
            background-repeat: no-repeat;
            background-size: contain;
            align-items: start;
            justify-content: center;
            max-height: unset;
            height: calc(100% - 34px);
            overflow-y: unset;
        }

        & .newsletter-modal__image-container {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            min-height: 250px;

            & .newsletter-modal__image {
                flex: 1 0 auto;
                background-size: cover;
            }
        }
    }

    &__msg {
        @include center-x;
        z-index: 1;
        top: 50px;
        left: 50%;
        box-sizing: border-box;
        font-size: $font-size-default;
        border-radius: 3px;
        @include tablet{
            max-width: 500px;
            top: -45px;
        }

        @include desktop{
            max-width: 500px;
            top: 20px;
        }

        &--success {
            color: rgb(8, 82, 41);
            background-color: rgb(231, 250, 240);
            border-color: rgb(19, 206, 102);
        }

        &--error {
            color: #661d1d;
            background-color: #ffeded;
            border-color: #ff4949;
        }
    }

    &__header {
        color: $text-green;
        font-family: NewRailAlphabet-bold;
        text-align: left;
        padding-top: 20px;
        font-size: 50px;
        line-height: 1.0;

        @include desktop {
            font-size: 60px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__image {
        background-image: url("~@/assets/tapwell/img/newsletterTransparent.png");
        min-height: 120px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: $background-light-grey;
    }

    &__text-container {
        padding-top: 10px;
    }

    &__close-btn {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        color: $text-green;
        position: absolute;
        top: 0;
        right: 7px;

        @include desktop {
            top: 50px;
            right: 47px;
            font-size: $font-size-default;
        }
    }

    & .link {
        text-decoration: underline;
    }

    & .checkbox-container {
        & span {
            text-transform: uppercase;
        }
    }

    &__content {
        padding: 0 10px 0 10px;
        @include desktop {
            padding-left: 60px;
            max-width: 36%;
        }
    }
}

.newsletter-form {
    &__input {
        > label {
            padding-bottom: 10px;
            display: block;
            text-transform: uppercase;
        }
        > input {
            width: 100%;
            line-height: 1.9;
            padding-left: 5px;
            background-color: $background-light-grey;
            box-sizing: border-box;
            border-width: 1px;
            outline: none;
        }
    }

    &__checkboxes {
        padding-top: 20px;
    }

    &__submit-btn {
        background-color: $background-grey;
        margin-top: 20px;
        width: 100%;
        text-align: left;
        font-weight: 700;
        color: black;
        border: none;
        line-height: 3;
        font-size: 16px;
        margin-bottom: 7px;
        padding-left: 10px;
        cursor: pointer;

        &--disabled {
            opacity: 0.2;
            cursor: default;
        }

        &-icon{
            //It's required to have the icon for the sendinblue script to work
            // hide the icon
            display: none;
        }
    }

    & .error {
        color: $text-red;
    }
}
