@import "~@/styles/constants.scss";











div {
    align-items: baseline;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 700;

    label {
        font-weight: normal;
        margin-left: 10px;
        white-space: nowrap;
    }

    p {
        color: var(--color-white);
        margin: 2px 0;
        padding: 0 2px;

        &.checked {
            color: var(--color-black);
        }
    }

    &:hover > p {
        color: var(--color-light-gray);
    }

    &.large {
        font-size: 32px;
        line-height: 32px;

        p.checked {
            color: var(--color-green);
        }

        &:hover > p {
            color: var(--color-light-gray);
        }
    }
}
