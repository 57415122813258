@import "~@/styles/constants.scss";












































@import "../../scss/mixins";
.selected-images {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: solid 1px black;
    background: var(--color-white);
    padding: 20px;
    z-index: 999;
    max-height: 90vh;
    display: grid;
    grid-template-rows: auto 1fr auto auto;

    @include tabletdesktop {
        bottom: 55px;
        right: calc((100% - 1600px) / 2);
        left: auto;
        border: solid 1px black;
        width: 448px;
        max-height: 70vh;
    }

    &__overlay {
        background: black;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        position: relative;
    }

    &__left-group {
        display: flex;
        content: "";
    }

    &__right-group {
        display: flex;
        cursor: pointer;
    }

    &__checkbox {
        font-size: 16px;
        font-weight: 700;
        margin-left: 10px;
    }

    &__delete-all {
        font-weight: 700;
        padding: 0 2px;
        cursor: pointer;
        border: none;
        background: none;
        align-self: flex-start;

        p.checked {
            color: var(--color-green);
        }

        &:hover {
            color: var(--color-gray);
        }
    }

    &__title {
        font-family: var(--font-family-bold);
        font-size: 18px;
    }

    &__show {
        font-weight: 500px;
        width: 40px;
        display: inline-flex;
        align-items: center;
    }

    &__icon {
        height: 22px;
        width: 28px;
        display: inline-block;
    }

    &__icon-show {
        background-image: url("../../assets/originalIcons/show.svg");
    }

    &__icon-hide {
        background-image: url("../../assets/originalIcons/hide.svg");
    }

    &__figure {
        width: 83px;
        height: 83px;
        background: var(--color-light-gray);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image {
        max-width: 100%;
        max-height: 100%;
    }

    &__list {
        border-bottom: solid 1px black;
        margin-bottom: 10px;
        overflow: auto;
        @include custom-scrollbar;
    }

    &__list-item {
        display: flex;
        column-gap: 10px;
        margin-bottom: 10px;
    }

    &__item-info {
        flex: 1;
    }

    &__item-name {
        font-family: var(--font-family-bold);
    }

    &__delete-item {
        cursor: pointer;
        border: none;
        align-self: flex-start;
        height: 14px;
        width: 13.22px;
        background-image: url("../../assets/originalIcons/trashcan.svg");
        background-repeat: no-repeat;
    }

    &__button {
        margin-bottom: 10px;
    }
}
