.search-modal {
  display: none;
  a {
    color: $text-black !important;
  }
  > div {
    height: 100%;
    display: block;
  }
  &__loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    background-color: rgba(255,255,255,0.5);
  }

  &-open {
    .search-modal {
      position: fixed;
      right: 0;
      height: 100vh !important;
      z-index: 99999;
      top: 0px;
      display: block;
      background: $background-white;
      padding: $body-padding;
      left: 0;

      @include tablet {
        padding: $column-margin $column-gap;
      }
      @include mobile {
        padding: $column-margin 0 $column-margin $column-gap;
      }
      .container-padding {
        > div {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          width: 100%;
        }
      }
      &__close {
        background-color: transparent;
        border: 0;
        z-index: 999;
        padding: 0;
        cursor: pointer;
        margin-left: $column-margin;
        @include mobile {
          position: absolute;
          top: 0;
          right: $column-gap;
        }
        p {
          order: 2;
          margin: 0;
          font-size: $font-size-menu;
        }

        &:hover,
        &:focus,
        &:active {
          outline: 0;
        }
      }
      &__result {
        margin-top: calc(#{$line-margin} * 8);
        @include mobile {
          margin-top: calc(#{$line-margin} * 5);
        }

        &__list {
          display: flex;
          flex-flow: row;
          width: 100%;
          @include mobile {
            display: grid;
            // grid-template-columns: 80% auto; // Uncomment when pages will be presented
            grid-template-columns: 100% auto;
            will-change: transform;
            // align-content: center;
            // overflow-x: auto;
            scroll-snap-coordinate: 0 0;
            scroll-snap-points-x: repeat(100%);
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
              height: 1px;
              width: 1px;
              margin: 10px;
            }

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: none;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $background-black;
            }
            > div {
              scroll-snap-align: start;
            }
          }
          > div {
            @include mobiletablet {
              margin-top: 0;
            }
          }
          &--items {
            .row {
              overflow-y: auto;
              max-height: calc(100vh - 260px);

              @include mobiletablet {
                max-height: calc(100vh - 150px);
              }
              &::-webkit-scrollbar {
                width: 1px;
                height: 1px;
                margin: 10px;
              }

              &::-webkit-scrollbar-track {
                -webkit-box-shadow: none;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $background-black;
              }
            }
          }
        }
        &__column {
          > h3 {
            font-family: $font-bold;
            font-size: $font-size-heading;
            margin: 0 0 calc(#{$line-margin} * 2);
            @include mobiletablet {
              font-size: $font-size-heading-mob;
            }
          }
          &--big {
            width: calc(75% - #{$column-margin});
            margin-right: $column-margin;
            @include mobile {
              width: calc(100% - #{$column-margin});
            }
          }
          &--small {
            width: calc(25% - #{$column-margin});
            margin-left: $column-margin;
            @include mobile {
              width: calc(100% - #{$column-margin});
              padding-right: 30px;
            }
          }
        }
      }
      &__page-list {
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
      }
      &__search {
        display: flex;
        justify-content: flex-start;
        flex: 0 0 75%;
        max-width: 75%;
        @include mobiletablet {
        }
        @include mobile {
          margin-left: 0;
          margin-right: $column-gap;
          justify-content: space-between;
          align-items: center;
          flex: 1 0 auto;
        }

        input {
          border: 0;
          outline: 0;
          box-shadow: none;
          color: $text-black;
          font-size: $font-size-menu;
          width: calc(75% - #{$column-margin});
          padding: 0;
          margin-top: -10px;
          @include mobile {
            font-size: $font-size-menu-mob;
            margin-top: 0;
            width: 100%;
          }
          &::-webkit-input-placeholder {
            color: rgba($text-black, 0.5);
            font-size: $font-size-menu;
            opacity: 1;
            @include mobile {
              font-size: $font-size-menu-mob;
            }
          }
          &:-moz-placeholder {
            color: rgba($text-black, 0.5);
            font-size: $font-size-menu;
            opacity: 1;
            @include mobile {
              font-size: $font-size-menu-mob;
            }
          }
          &::-moz-placeholder {
            color: rgba($text-black, 0.5);
            font-size: $font-size-menu;
            opacity: 1;
            @include mobile {
              font-size: $font-size-menu-mob;
            }
          }

          &:-ms-input-placeholder {
            color: rgba($text-black, 0.5);
            font-size: $font-size-menu;
            opacity: 1;
            @include mobile {
              font-size: $font-size-menu-mob;
            }
          }

          &::placeholder {
            color: rgba($text-black, 0.5);
            font-size: $font-size-menu;
            opacity: 1;
            @include mobile {
              font-size: $font-size-menu-mob;
            }
          }
        }
        input + svg {
          visibility: visible;
          margin-left: -15px;
          width: 16px;
          pointer-events: none;
          cursor: pointer;
          @include mobile {
            width: 10px;
          }
        }
        input:placeholder-shown + svg {
          visibility: hidden;
        }
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
          opacity: 0;
        }
      }
    }
    .product-page__product-list {
      padding-top: 0;
      margin-top: calc(#{$line-margin} * 1);
      border-top: 0;
      font-size: $font-size-default;
      .row {
        grid-template-columns: repeat(6, 1fr);
        @include mobile {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    .product-page__product-list__column {
      grid-column: auto/span 2;
      @include mobile {
        grid-column: auto/span 1;
      }
      &.Content {
        display: none;
      }
      &.Highlight {
        grid-row: auto/1 span;
        grid-column: auto/span 2;
        @include mobile {
          grid-column: auto/span 1;
        }
        .product-page__product__img {
          height: 250px;
          @include mobile {
            height: 250px;
          }
          div {
            min-height: auto;
          }
        }
      }
      &:first-of-type,
      &:nth-child(14n) {
        grid-row: auto/2 span;
        grid-column: auto/span 4;
        @include mobile {
          grid-row: auto/1 span;
          grid-column: auto/span 1;
        }
        > div {
          height: 100%;
          display: flex;
          flex-flow: column;
          .product-page__product__img {
            height: 100%;
            @include mobile {
              height: 250px;
            }

            div {
              min-height: 614px;
              @include mobiletablet {
                min-height: 614px;
              }
              @include mobile {
                min-height: auto;
              }
            }
          }
          .product-page__product__product-info {
            height: auto;
          }
        }
        &.Content {
          + .product-page__product-list__column {
            grid-row: auto/2 span;
            grid-column: auto/span 4;
            @include mobile {
              grid-row: auto/1 span;
              grid-column: auto/span 1;
            }
            > div {
              height: 100%;
              display: flex;
              flex-flow: column;
              .product-page__product__img {
                height: 100%;
                @include mobile {
                  height: 250px;
                }
              }
              .product-page__product__product-info {
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
