.productlistning {
  &__list {
    margin-top: calc(#{$line-margin} * 3);
    @include mobiletablet {
      margin-top: $line-margin;
    }
    > div > div {
      &:first-child {
        .row {
          .productlistning {
            &__column {
              &--first {
                &:before {
                  border-top: 1px solid $border-black;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  &__stock {
    padding-top: $line-margin;
    padding-bottom: $line-margin;
    position: relative;
    @include mobiletablet {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      padding-bottom: 0;
      > div {
        align-items: flex-end;
      }
    }
    @include desktop {
      display: flex;
      flex-flow: row;
      padding: 0 $column-margin;
      &:before {
        display: none !important;
      }

      > div {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        padding: 0;
      }
    }

    p {
      margin: 0;
      font-family: "NewRailAlphabet-light";
    }
    span {
      margin: 0;
      display: block;
      line-height: $line-height;
    }
  }
  &__price {
    padding-top: $line-margin;
    position: relative;

    @include desktop {
      display: flex;
      flex-flow: row;
      padding: 0 $column-margin;
      &:before {
        display: none !important;
      }

      > div {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        padding: 0;
      }
    }

    p {
      margin: 0;
      font-family: "NewRailAlphabet-light";
    }
    text-align: right;

    p {
      @include mobiletablet {
        text-align: right;
      }
    }
  }
  &__column {
    padding-top: $line-margin;
    padding-bottom: $line-margin;
    position: relative;
    @include desktop {
      display: flex;
      flex-flow: row;

      > div {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        padding: 0;
      }
    }

    p {
      margin: 0;
      font-family: "NewRailAlphabet-light";
    }
    &--category {
      > div {
        &:first-child {
          width: calc(50% - #{$column-margin});
          margin-right: $column-margin;
        }
      }
    }
    &--first {
      display: flex;
      flex-flow: row;

      > div {
        @include desktop {
          justify-content: flex-start;
          min-height: 150px;
        }
      }
      @include mobiletablet {
        justify-content: space-between;
      }
    }

    &:before {
      content: "";
      border-top: 1px solid $border-black;
      display: block;
      width: 100%;
      left: $column-margin;
      top: 0;
      position: absolute;
      @include mobiletablet {
        width: calc(100% - #{$column-gap}) !important;
        margin-bottom: $column-margin;
      }
    }
    &--first {
      &:before {
        width: 50%;
        left: auto;
        right: -$column-margin;
        @include mobiletablet {
          right: $column-margin;
          width: calc(100% - #{$column-gap}) !important;
        }
      }
    }
    &:last-child {
      &:before {
        width: calc(100% - #{$column-gap});
      }
    }
  }
  &__row {
    @include desktop {
      display: flex;
      flex-flow: row;
    }
  }

  &__details {
    width: 100%;
  }

  &__info {
    width: calc(50% - #{$column-margin});
    margin: 0 $column-margin 0 0;
    @include mobiletablet {
      width: calc(60% - #{$column-margin});
      min-height: 134px;
    }
  }
  &__serie {
    font-family: $font-bold;
    font-size: $font-size-default;
    margin: 0;
    @include mobiletablet {
      font-size: $font-size-default-mob;
    }
  }
  &__category {
    font-family: $font-bold;
    margin: 0;
    font-size: $font-size-default;
    font-weight: normal;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    @include mobiletablet {
      font-size: $font-size-default-mob;
    }
  }
  &__variant-name {
    margin: 0;
    font-family: "NewRailAlphabet-light";
    font-weight: normal;
    font-size: $font-size-default;
    @include mobiletablet {
      font-size: $font-size-default-mob;
    }
  }
  &__img {
    width: calc(50% - #{$line-margin} - 2em);
    height: calc(150px - #{$line-margin});
    margin-right: 0;
    margin-left: $column-margin;
    background-color: $background-light-grey;
    padding: 5px 1em;
    @include tablet {
      width: calc(20% - #{$column-margin});
      height: 124px;
    }
    @include mobile {
      width: calc(40% - #{$column-margin});
      height: 124px;
    }

    > div {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 100%;
      width: auto;
    }
  }

  &__amount-picker {
    @include mobiletablet {
      border-bottom: 1px solid $border-black;
      padding-bottom: $line-margin;
    }
  }
  &__amount-label {
    padding-top: $line-margin;
    display: block;
    line-height: $line-height;
    border-top: 1px solid $border-black;

    @include mobiletablet {
      border-top: 0;
    }
  }

  &__calculatedbox-label {
    display: block;
    line-height: $line-height;
    color:$text-grey;
    padding-left: $line-margin;
    @include mobiletablet {
      border-top: 0;
     
    }
  }

 &__box-label {
    padding-top: $line-margin;
    display: block;
    line-height: $line-height;
    color: $text-grey;

    @include mobiletablet {
      border-top: 0;
    }
  }

  &__art-nr {
    padding-top: $line-margin;
    display: block;
    line-height: $line-height;
    

    @include mobiletablet {
      border-top: 0;
    }
  }
  &__variant-list {
    padding-bottom: $line-margin;
    @include mobiletablet {
      border-bottom: 1px solid $border-black;
    }
  }
  &__stock-text {
    @include desktop {
      margin-bottom: $line-margin;
    }
    @include mobiletablet {
      padding-right: $column-margin;
    }
  }
  &__status-icon {
    width: 100%;
    border-top: 1px solid $border-black;
    padding-top: calc(#{$line-margin} + 1px);
    @include mobiletablet {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      border: 0;
      padding-top: 0;
    }

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 50%;
      background-color: $text-red;
      @include mobiletablet {
        margin-top: -4px;
      }
    }
    &--true {
      &:before {
        background-color: $text-green;
      }
    }
  }
  &__status-blank {
    width: 100%;
    border-top: 1px solid $border-black;
    padding-top: calc(#{$line-margin} + 1px);
    @include mobiletablet {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      border: 0;
      padding-top: 0;
    }
  }

  &__clear {
    font-family: $font-bold !important;
    @include desktop {
      min-height: 16.5px;
    }
  }
  &__volume-discount {
    display: inline-block;
    position: relative;
    color: $text-green;
    text-decoration: underline;
    cursor: pointer!important;
    margin: 6px 0;
    // &:hover {
    //   .tool-tip {
    //     display: block;
    //   }
    // }
    .tool-tip {
      display: none;
      position: absolute;
      background-color: $background-black;
      color: $text-white;
      padding: 5px;
      right: 50%;
      padding: 8px 25px;
      list-style: none;
      margin: 16px 0 0;
      z-index:1;
      &:after {
        content: "";
        display: block;
        border-top: 12px solid transparent;
        border-right: 12px solid $background-black;
        position: absolute;
        top: -12px;
        right: 0;
      }
      li {
        white-space: nowrap;
        font-size: 13px;
        line-height: 150%;
      }
    }
  }
  &__btn {
    cursor: pointer;
    @include mobiletablet {
      margin-bottom: $line-margin;
    }
  }

  &__show-more {
    display: block;
    padding: $line-margin 0;
    cursor: pointer;
    position: relative;
    &:after {
      content: "\2192";
      font-family: $font-icon;
      margin-left: 3px;
    }
  }
  &__variant-container {
    @include desktop {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
    }
  }
  &__stock-container {
    justify-content: flex-end !important;
  }
  &__price-container {
    justify-content: flex-end !important;
    > div {
      margin-bottom: $line-margin;
    }
    @include mobiletablet {
      padding-top: $line-margin;
      border-top: 1px solid $border-black;
    }
  }
  .show-more {
    @include mobiletablet {
      display: block !important;
    }
  }

  .show-more-flex {
    @include mobiletablet {
      display: flex !important;
    }
  }
}

.no-padding{
  padding: 0px;
}
