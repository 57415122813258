.variant-picker, .sparepart-fits {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  margin: 0;

  li {
    margin: 0 $line-margin-half 0 0;
    line-height: $line-height;
    &:only-child {
      visibility: hidden;
    }
    &.active {
      font-family: $font-bold;
    }
    a {
      white-space: nowrap;
      cursor: pointer;
      font-size: $font-size-default;
      &.selected {
        font-family: $font-bold;
        cursor: default;
      }
    }
    &:after {
      font-family: "NewRailAlphabet-light";
      content: "|";
      margin-left: $line-margin-half;
      font-weight: normal;
      font-size: $font-size-default;
    }
    &:last-of-type {
      &:after {
        content: "";
      }
    }
  }
  .selected-variant-color {
    display: flex;
    justify-content: space-between;
    flex: 1 1 100%;
    margin-bottom: 10px;
    .color-name {
      font-weight: 700;
      margin-left: 0.5rem;
    }
  }
}

.sparepart-fits li:only-child{
  visibility: visible;
}
