.richtext-block {

  &__column {
     &--white-space {
        max-width: 12.6%;
        @include mobiletablet {
            display: none;
        }
    }
    &--content {
      min-width: 55%;
      flex-basis: auto;
      padding: 0 $column-margin;
      @include mobiletablet {
        padding: 0 $column-margin;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    p {
      font-size: $font-size-menu;
      margin: $line-margin 0;
      @include mobiletablet {
        font-size: $font-size-menu-mob;
      }
    }

    h1 {
      color: $text-green;
      &.green {
        color: $text-green;
      }
      &.black {
        color: $text-black;
      }
    }
    h1,
    h2 {
      margin: $line-margin 0;
      font-size: $font-size-heading;
      font-family: $font-bold;
      line-height: 1;
      @include mobiletablet {
        font-size: $font-size-heading-mob;
      }
    }
    h3,
    h4,
    h5,
    h6 {
      margin: $line-margin 0;
      line-height: 1;
      font-family: $font-bold;
    }

    a {
      color: $text-green;
      text-decoration: none;
    }
    .font-icon {
      font-family: $font-icon !important;
      font-weight: normal !important;
    }
    .font-light {
      font-family: "NewRailAlphabet-light" !important;
      font-weight: normal !important;
    }
    .arrow-right:after {
      content: "\2192";
      font-family: "NewRailAlphabet-medium" !important;
      font-weight: normal !important;
  }
  
  .arrow-left:before {
      content: "\2190";
      font-family: "NewRailAlphabet-medium" !important;
      font-weight: normal !important;
  }
    a {
      color: $text-green;
    }
    ol {
      font-size: $font-size-menu;
      margin: $line-margin 0;
      @include mobiletablet {
        font-size: $font-size-menu-mob;
      }
    }
    ul {
      font-size: $font-size-menu;
      margin: $line-margin 0;
      @include mobiletablet {
        font-size: $font-size-menu-mob;
      }
    }
    .white {
      color: white !important;
  }
  .black  {
      color: black !important;
  }
  }
}
