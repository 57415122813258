
#Search::-webkit-search-cancel-button{
         
    -webkit-appearance: none;
 height: 15px;
 width: 15px;
 background-image: url("~@/assets/tapwell/img/close.svg") !important;
 background-repeat:no-repeat;
}
.search-option
{
    @include desktop {
        border-bottom: 1px solid black;
      
      }
      @include tablet {
        border-bottom: 1px solid black;
      }
      @include mobile {
        margin-bottom: 20px;
       width:100%;
      }
      
}
.search-row
{
    @include desktop {
        padding: 0px 0px 0px 15px;  
     }
     @include tablet     {
        padding: 0px 0px 0px 15px;  
     }
     @include mobile {
        padding: 0px 15px;  
      }
    
}

.anchor {
        border:none;
        background-color: $background-light-grey2;
        display: flex;
        align-items: center;
        justify-content: center;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;       
        padding: 10px 0px;
        font-weight: bold;
        font-family: "NewRailAlphabet-light";
        font-size: 14px;
}

.anchor::after {
	display: inline-block;
    width: 0;
    height: 0;
    margin-left: .5em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .28em solid transparent;
    border-bottom: 0;
    border-left: .28em solid transparent;
    font-family: "NewRailAlphabet-light";
    font-size: 14px;    
}

.anchor:hover {	
	cursor: pointer;
}

.menu {	
	border-radius: .25rem;
	color: #000000;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	list-style: none;
	margin: .125rem 0 0;
    @include mobile {
        padding: 0px 0px;
      }
	padding: 3px 0px;
	position: absolute;
	text-align: left;
	font-family: "NewRailAlphabet-light";
    font-weight: bold;

}

.menu-item {
	padding: 6px 0px;	
    @include mobile {
        padding: 0px 0px;
      }
}

.menu-item:hover {
	
	cursor: pointer;
}

.search-modal__sparepart-loader-container
{
	display: flex;
    justify-content: center;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: -90px;
    margin-left: -90px;
    z-index: 100;
}

.search-panel-heading{
    margin: 10px 0 15px 0;
    font-size: 30px;
    font-family: NewRailAlphabet-bold;
    line-height: 1;
}