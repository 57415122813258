.header {
  height: $header-height;
  top: 0;
  padding-top:  $body-padding;
  position: fixed;
  width: calc(100% - (#{$body-padding} * 2));
  max-width: $body-width;
  z-index: $header-z-index;
  margin: 0 auto;
  display: block;
  left: 0;
  right: 0;
  
  @include mobiletablet {
    height: 12px;
    top: calc(#{$line-margin} * 2);
    width: calc(100% - (#{$column-gap} * 2));
    padding-top: 0;
  }

  &.scroll-up {
    top: 0px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 0px 7px 0 rgba(0, 0, 0, 0.1);
    padding: calc(#{$line-margin} * 2) $body-padding;
    left: 0;
    width: calc(100% - calc(#{$body-padding} * 2));
    height: 50px;
    transition: top 0.5s;
    max-width: none;
    background: $background-white;
    .header {
     
      &__row {
        @include desktop {
          height: 70px;
          > div {
            height: 70px;
          }
        }
        @media (min-width: 1600px) {
          max-width: calc(#{$body-width} + #{$column-gap});
          margin: 0 auto;
          display: flex;
          flex-flow: row;
          justify-content: center;
          
        }
      }
      &__heading {
        ul {
          li {
            @include desktop {
              font-size: $font-size-menu;
            }
          }
        }
      }
 
    }
    @include mobiletablet {
      height: 12px;
      width: calc(100% - calc(#{$column-gap} * 2));
      height: 12px;
      padding: $line-margin $column-gap;
      left: auto;

      .hamburger {
        right: $column-gap;
        top: 8px;
      }
      &.open {
        top: 10px;
        transition: 0;
        .hamburger  {
          right: 60px;
          top: calc(#{$line-margin} * 2); 
        }
      }
    }

  }
  &.scroll-down {
    top: -90px;
    transition: top 0.5s;
    padding-top: 0;
    @include mobiletablet {
      top: -50px;
      left: 0;
      width: calc(100% - calc(#{$line-margin} * 2));
      padding: $line-margin $column-gap;
      .hamburger  {
        right: 70px;
        top: $line-margin;
      }
      &.open {
        top: 0;
        transition: 0;
        .header__row {
          margin-top: 25px;
        }
        .hamburger {
          right: 60px;
          top: 30px;
        }

      }
    }
 
    .header {
      &__heading {
        @include desktop {
          top: - 100%;
        }
      }
    }

  }


  &__row {
    height: $header-height;
    @include mobiletablet {
      height: 12px;
    }

    > div {
      height: $header-height;
      position: relative;
      @include mobiletablet {
        height: auto;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
  &__logotype {
    @include mobiletablet {
      z-index: 999;
      height: 12px !important;
      width: 97px;
      max-width: none;
      flex-basis: auto;
    }
  }
  &__heading {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      margin-left: calc(-12.4% + #{$column-gap});
      margin-left: calc(-35% + #{$column-margin});
      li {
        margin: 0;
        font-size: 45px;
        font-family: NewRailAlphabet-bold;
        line-height: 1;
        a {
          color: $text-black;

          &:focus, &:hover, &:active {
            outline: 0;
          }
        }
        &.active {
          a {
            color: var(--bannerHeadingColor);
          }
        }
      }
    }
    @include mobiletablet {
      display: none;
    }
  }

  &__logo {
    width: 100%;
    max-width: 97px;
    display: block;
    @include desktop {
      max-width: 70px;
      margin-top: 2px;
    }
    @include mobiletablet {
      height: 100%;
      width: auto;
    }
    &--light {
      display: none;
    }
  }
  &__links {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    @include mobiletablet {
      display: none;
    }
  }
  &__link-list {
    width: calc(100% - #{$column-margin});
    margin-right: $column-margin;

    ul {
      padding-left: 0;
      list-style: none;
      margin: 0;
      li {
        a {
          white-space: nowrap;
          font-size: $font-size-menu;
          @include mobiletablet {
            font-size: $font-size-menu-mob;
          }
        }
      }
    }
  }
  &__profile {
    width: calc(100% - #{$column-margin});
    margin-left: $column-margin;
    z-index: 1;
    @include mobiletablet {
      display: none;
    }
    a {
      font-size: $font-size-menu;
      white-space: nowrap;
      display: block;
      @include mobiletablet {
        font-size: $font-size-menu-mob;
      }
    }
  }
  &__cart {
    @include mobiletablet {
      display: none;
    }
    > div {
      font-size: $font-size-menu;
      display: flex;
      justify-content: flex-end;
      @include mobiletablet {
        font-size: $font-size-menu-mob;
      }
      a {
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
  &__cart-title {
    display: inline;
    position: relative;
  }
  &__cart-amount {
    position: absolute;
    top: 19px;
    left: 0;
  }
  img {
    width: 100%;
    display: block;
    @include mobiletablet {
      height: 100%;
      width: auto;
    }
  }

  .active {
    font-family: $font-bold;
  }
  .hamburger {
    background-color: transparent;
    position: absolute;
    border: 0;
    height: 17px;
    width: 20px;
    z-index: 999;
    right: 0;
    top: -3px;
    padding: 0;
    cursor: pointer;
  
    .line {
      background-color: $border-black;
      height: 1px;
      width: 20px;
      display: block;
      margin: 5px 0;
    }
    &.open {
      .line {
        &:nth-child(1) {
          transform: translateY(6px) rotate(45deg);
        }
        &:nth-child(2) {
          transform: translateY(-6px) rotate(-45deg);
          margin-top: 11px;
        }
      }
    }
    &:focus,
    &:hover,
    &:active {
      outline: 0;
    }
  }

  &.open {
    width: 100vw;
    background: $background-white;
    margin-top: -$column-margin;
    margin-left: -$column-gap;
    height: 100%;
    position: fixed;
    z-index: $header-z-index;

    @include mobiletablet {
      left: auto;
    }
    .header {
      &__row {
        margin-top: $column-margin;
        margin-left: $column-margin;
        margin-right: $column-margin;
        height: auto;    
        display: flex;
        flex-flow: column;
      }
      &__heading {
        display: block;
        order: 3;
        ul {
          margin-left: 0;
          margin-bottom: calc(#{$line-margin} * 5);
          border-top: 1px solid $border-black;
          padding-top: $line-margin;
          li {
            font-size: $font-size-menu-mob;
            font-family: "NewRailAlphabet-light";
            a {
              color: $text-black;
            }
            &.active {
              font-family: $font-bold;
            }
          }
        }

      }
      &__logotype {
        margin-bottom: $line-margin;
        order: 1;
      }
      &__links {
        display: flex;
        flex-flow: wrap;
        padding: 0;
        padding-top: $line-margin;
        padding-bottom: $line-margin;
        margin: 0 $column-margin;
        width: calc(100% - #{$column-gap});
        flex-basis: auto;
        overflow: auto;
        max-height: calc(100vh - 260px);
        order: 2;
        .header__profile {
          display: none;
        }
      }
      &__link-list {
        width: 100%;
        margin-right: 0;
      
        ul {
          display: flex;
          flex-flow: wrap;
          li {
            width: 100%;
            
            a {
              line-height: $line-height;
              font-family: $font-bold;
            font-size: $font-size-heading-mob;
            line-height: 1;
            }
            &.active {
             
              a {
                color: $text-green;
              }
            }
          }
        }
      }
      &__profile {
        display: flex;
        flex-flow: wrap;
        width: 100%;
        margin: 0;
        padding: $line-margin 0 0;
        border-top: 1px solid $border-black;    
        width: calc(100% - 30px);
        margin: 0 15px;
        order: 5;

        a {
          width: 100%;
          display: block;
          line-height: $line-height;
        }
      }
      &__cart {
        display: flex;
        border-top: 1px solid $border-black;
        padding: $line-margin 0;
        margin: 0 $column-margin;
        flex-basis: auto;
        width: calc(100% - #{$column-gap});
        order: 4;
        height: 53px;
      }
    }
    .hamburger {
      right: $column-gap;
      top: 10px;

    }
  }
  &.logged-out {
    @include mobiletablet {
      &.open .header__links {
        border-top: 0;
      }
    }
  }
  &.lightmode {
    .hamburger {
      .line {
        background-color: $text-white;
      }
    }
    .header__heading ul li a {
      color: $text-white;
    }
    .header {
      &__logo {
        &--light {
          display: block;
        }
        &--dark {
          display: none;
        }
      }
    }
    a {
      color: $text-white;
    }
    &.scroll-up {
      .hamburger {
        .line {
          background-color: $text-black;
        }
      }
      .header__heading ul li a {
        color: $text-black;
      }
      a {
        color: $text-black;
      }
      .header {
        &__logo {
          &--light {
            display: none;
          }
          &--dark {
            display: block;
          }
        }
      }
      
    }
    &.open {
      .hamburger {
        .line {
          background-color: $text-black;
        }
      }
      .header__heading ul li a {
        color: $text-black;
      }
      a {
        color: $text-black;
      }
      .header {
        &__logo {
          &--light {
            display: none;
          }
          &--dark {
            display: block;
          }
        }
      }
     
    }
  }
}
