.checkbox-container {
    display: inline-block;
    position: relative;
    margin-bottom: 6px;
    margin-top: 6px;
    cursor: pointer;
    padding-left: 20px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ span{
            opacity: 0.5;
        }
    }

    & input:checked ~ .checkbox__checkmark {
        &:after {
            display: block;
        }
    }

}

.checkbox__checkmark {
    left: 0;
    @include center-y;
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 1px solid $text-black;

    @include desktop {
        right: 0;
    }

    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 3px;
        top: 0px;
        width: 4px;
        height: 7px;
        border: solid black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}
