.modal {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  min-height: 100%;
  margin-left: 0;
  border: 0;
  border-radius: 0;
  position: fixed;
  z-index: 999999 !important;
  background: #0a0a0a !important;
  color: #fefefe;

  &__inner-container {
    max-width: 520px;
    position: relative;
    top: 45%;
    transform: translateY(-50%);
    padding: 2rem;
    margin: 0 auto;
  }
}

.language-modal {
  .select-container {
    position: relative;
    margin: 0 0 1rem;
    &:after {
      content: "⯆";
      position: absolute;
      right: 10px;
      color: black;
      font-size: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  select {
    height: 2.4375rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #cacaca;
    border-radius: 2px;
    background-color: #fefefe;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #121212;
    background-origin: content-box;
    background-position: right -1rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  }
  .button {
    border: 1px solid white;
    display: block;
    margin-left: auto;
    color:white;
    padding: 10px 10px 10px 90px;
    text-align: right;
    background-color: transparent;
    font-size: 14px;
    cursor: pointer;
  }
}
