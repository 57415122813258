.banner {
  &__text {
    position: absolute;
    top: -$header-height;
    left: calc(12.4% + #{$column-gap});
    z-index: 99998;
    
    @media screen and (-ms-high-contrast: none) {
     top: -35px
   }

    @include mobiletablet {
      top: -20px;
      left: $column-margin;
      width: calc(100% - #{$column-gap});
      margin-bottom: $line-margin;
      word-break: break-word;
      z-index: 5;

      &--relative {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        max-width: 470px;
        @media screen and (-ms-high-contrast: none) {
          top: 70px
        }
      }
    }
    &.scroll-down {
      z-index: 5;
    }
    
  h1 {
    color:var(--bannerHeadingColor);
    &.green {
      color: $text-green;
    }
    &.black {
      color: $text-black;
    }


  }
    h1, h2 {
      margin: 0;
      font-size: $font-size-heading;
      font-family: $font-bold;
      line-height: 1;
      @include mobiletablet {
        font-size: $font-size-heading-mob;
      }
     
    }
    h3,h4,h5,h6 {
      margin: 0;
      line-height: 1;
      font-family: $font-bold;
    }
    p {
      line-height: 1;
      margin: 0;
    }
    a {
      color: $text-green;
      text-decoration: none;
    }
    .font-icon {
      font-family: $font-icon !important;
      font-weight: normal !important;
    }
    .font-light {
      font-family: "NewRailAlphabet-light" !important;
      font-weight: normal !important;
    }

  .arrow-right:after {
      content: "\2192";
      font-family: "NewRailAlphabet-medium" !important;
      font-weight: normal !important;
  }
  
  .arrow-left:before {
      content: "\2190";
      font-family: "NewRailAlphabet-medium" !important;
      font-weight: normal !important;
  }
  .white {
    color: white !important;
  }
  .black  {
      color: black !important;
  }

  }
  &__img {
    display: block;
    max-height: $banner-height;
    height: calc(100vh - (#{$header-height} + #{$body-padding} + 120px));
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include mobiletablet {
      background-repeat: no-repeat;
      width: calc(100% + (#{$column-gap} * 2));
      height: calc(100vh - 102px);
      margin-left: -$column-gap;
    }
    &--mobile {
      margin-top: calc(#{$line-margin} * 4);
      height: calc(100vh - (#{$line-margin} * 6) - 42px);
    }
  }
}