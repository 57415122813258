.checkout {
  
   
    > div {
        &:last-child {
            margin-bottom: calc(#{$line-margin} * 10);
        }
    }
      &__lead {
        max-width: calc(26.5% - 7px); 
        margin-left: 12.4%;
        padding-left: 7px;
        font-size: $font-size-menu;
        position: relative;
    
        @include mobiletablet {
          margin-top: 0;
          margin-left: 0;
          padding-left: 0;
          padding-right: 0;
          font-size: $font-size-menu-mob;
          max-width: 100%;
        }
      }
      &__lead-section {
      
          padding-bottom: calc(#{$line-margin} * 4);
  
        p {
          margin: 0;
          width: 100%;
        }
      }
    
  
    &__footer {
        .checkout {
            &__delivery {
                list-style: none;
                margin-top: $line-margin;
                padding: $line-margin 0;
                border-top: 1px solid $border-black;
                display: flex;
                flex-flow: row;
                > li {
                    
                    &:first-child {
                        margin: 0;
                        width: 100%;
                    }
                    &:last-child {
                        width: calc(50% - #{$column-margin});
                        margin: 0;
                        margin-right: $column-margin;
                        @include mobiletablet {
                          margin-right: 0;
                          text-align: right;
                        }
                    }
                }
                ul {
                    padding: 0;
                    list-style: none;
                    li {
                        margin: 0;
                        margin-bottom: calc(#{$line-margin} * 2);
                    }
                }
            }
            &__variant {
              &:before {
                @include mobiletablet {
                  border-top: 0;
                }
              }
              &--about {
                @include mobiletablet {
                  order: 2;
                  > div {
                   &:first-child {
                     width: 100%;
                     margin-right: 0;
                   }
                  }
                
                }
              }
              &--total-price {
                @include mobiletablet {
                  order: 1;
                  
                  &:before {
                    border-top: 1px solid $border-black !important;
                  }
                }
              
              }
            }
        }
    }
    &__list {
      top: calc(#{$line-margin} * 8);
      @include mobiletablet {
        top: $line-margin;
        margin-top: calc(#{$line-margin} * 5);
      }
      > div {
        &:first-child {
          .row {
            .checkout {
              &__variant {
                &:first-child {
                  &:before {
                    border-top: 1px solid $border-black;
                    width: 100%;
                    @include mobiletablet {
                      border-top: 0;
                    }
                  }
                  h3 {
                    font-size: $font-size-default;
                  }
                 
                }
                &--info {
                  @include mobiletablet {
                    display: block;
                  }
                }
                
              }
            }
          }
        }
      }
    }
 
    &__variant {
      padding-top: $line-margin;
      padding-bottom: $line-margin;
      position: relative;
      p {
        margin: 0;
        font-family: "NewRailAlphabet-light";
      }

      &--info {
        display: flex;
        flex-flow: row;
        @include mobiletablet {
          display: none;
        }
      }
      &--about {
        @include mobiletablet {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          > div {
            &:first-child {
              margin-right: $column-margin;
              width: calc(50% - #{$column-margin});
           
            }
          }
        }
      }
     
      &--total-price {
          > div {
            flex-flow: row;
            display: flex;
            justify-content: space-between;
            margin-left: 35%;
            @include mobiletablet {
              margin-left: 0;
            }
          }
          p {
              font-size: $font-size-menu;
              @include mobiletablet {
                  font-size: $font-size-menu-mob;
              }
          }
     
      }
      &--price {
        text-align: right;
        p {
            font-size: $font-size-menu;
          @include mobiletablet {
            font-size: $font-size-menu-mob;
            text-align: right;
          }
        }
        @include mobiletablet {
          padding-bottom: calc(#{$line-margin} * 2);
        }
        
      }
      &:before {
        content: "";
        border-top: 1px solid $border-black;
        display: block;
        width: 100%;
        left: $column-margin;
        top: 0;
        position: absolute;
        @include mobiletablet {
          width: calc(100% - #{$column-gap}) !important;
          margin-bottom: $column-margin;
        }
      }
      &:first-child {
        &:before {
          width: 50%;
          left: auto;
          right: -15px;
          @include mobiletablet {
            right: 15px;
            width: calc(100% - #{$column-gap}) !important;
          }
        }
      }
      &:last-child {
        &:before {
          width: calc(100% - #{$column-gap});
          @include mobiletablet {
            border-top: 0;
          }
        }
      }
    }
    &__img-container {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      
    }
  
    &__info {
      width: calc(50% - #{$column-margin});
      margin: 0 $column-margin 0 0;
      @include mobiletablet {
        width: calc(60% - #{$column-margin});
      }
      h3 {
         font-family: "NewRailAlphabet-light";
         margin: 0;
         font-weight: normal;
         font-size: 0;
      }
    }
    &__variant-name {
      margin: 0;
      font-size: $font-size-default;
      font-family: $font-bold;
      @include mobiletablet {
        font-size: $font-size-default-mob;
      }
    }
    &__img {
      background-color:  $background-light-grey;
      width: calc(50% - #{$column-margin});
      height: 150px;
      margin-right: 0;
      margin-left: 15px;
      padding: 5px 1em;
      @include mobile {
        width: calc(40% - #{$column-margin});
        height: 124px;
        display: block;
      }
      @include tablet {
        width: calc(20% - #{$column-margin});
        height: 124px;
        display: block;
      }
      > div {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 100%;
        
  
      }
    }
 
    &__variant-description {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-flow: row wrap;
      margin: 0;
      padding-bottom: $line-margin-half;
  
      li {
        margin: 0 $line-margin-half 0 0;
        line-height: $line-height;
        width: 100%;
        &.active {
          font-family: $font-bold;
        }
        a {
          white-space: nowrap;
          cursor: pointer;
        
        }
        span {
            font-family: $font-bold;
        }
      }
    }
    &__btn {
      margin-top: calc(#{$line-margin} * 6);
      cursor: pointer;


      @include mobiletablet {
        margin-top: calc(#{$line-margin} * 2);
      }
    }
 
  }
  