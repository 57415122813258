@import "~@/styles/constants.scss";






















































































































.scroll-shadow {
  &__wrap {
    overflow: hidden;
    position: relative;
  }

  &__scroll-container {
    overflow: auto;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    // //hide scrollbar in chrome, safari and Opera
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  &__shadow-top,
  &__shadow-right,
  &__shadow-bottom,
  &__shadow-left {
    position: absolute;
    border-radius: 6em;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &__shadow-top,
  &__shadow-bottom {
    right: 0;
    left: 0;
    height: 1em;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-image: linear-gradient(rgba(#555, 0.1) 0%, rgba(#fff, 0) 100%);
  }

  &__shadow-top {
    top: 0;
  }

  &__shadow-bottom {
    bottom: 0;
    transform: rotate(180deg);
  }

  &__shadow-right,
  &__shadow-left {
    top: 0;
    bottom: 0;
    width: 1em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: linear-gradient(
      90deg,
      rgba(#555, 0.1) 0%,
      rgba(#fff, 0) 100%
    );
  }

  &__shadow-right {
    right: 0;
    transform: rotate(180deg);
  }

  &__shadow-left {
    left: 0;
  }

  &__scroll-container {
    & .is-active {
      opacity: 1;
    }
  }
}
