@import "~@/styles/constants.scss";












.list-enter-active {
    opacity: 1;
    transition: opacity 1.5s;
}
.list-enter {
    opacity: 0;
}
