@import "~@/styles/constants.scss";















































div.image {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & > div {
        padding: 0 20px;
        border-left: 1px solid black;

        &:first-child {
            border: none;
        }

        > div {
            margin-bottom: 40px;

            li {
                cursor: pointer;
                border-bottom: 1px solid;
                padding: 10px 0;

                &:last-child {
                    border: none;
                }

                &:hover {
                    font-weight: bold;
                }
            }

            svg {
                height: 14px;
                margin-left: 10px;
                margin-top: 1px;
                width: 14px;
            }
        }
    }
}
