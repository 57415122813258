.Cookie--tapwell {
  background: #000;
  color: #fff;
  padding: 1.250em;

  a {
    color: #fff;
    text-decoration: underline;
  }

  .Cookie__button {   
    padding: 10px 10px 10px 150px;
    border: 1px solid #fff;
    text-align: right;
    background: #000;
    color: #fff;
    cursor: pointer;

    &:hover {
      background: #fff;
      color: #000;
      border: 1px solid #fff;
      border-radius: 0;
      border-color: #000;
      outline: 0;
    }
  }
}