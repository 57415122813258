.product-search {

  @include mobiletablet {
    position: absolute;
    top: 5px;
    right: 0;
    width: calc(40% + #{$column-margin});
    max-width: none;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }
  &__form-control {
    font-size: $font-size-menu;
    display: flex;
    flex-flow: column;
    text-align: right;

    @include mobiletablet {
      flex-flow: row;
      margin-top: 0;
    }

    input {
      width: 130px;
      padding: 0 0 $line-margin-half $column-gap;
      box-sizing: border-box;
      border: 0;
      border-radius: 0;
      transition: width 0.4s ease-out;
      order: 2;
      text-align: right;
      font-size: $font-size-menu;
      font-family: "NewRailAlphabet-light" !important;
      border-bottom: 1px solid transparent;
      @include mobiletablet {
        font-size: $font-size-menu-mob;
        text-align: left;   
        order: 1;
        width: 100%;
        transition: width 0.4s ease-out;
        padding: 0 $column-gap 0 0 ;

      }
      &::-webkit-input-placeholder {
        font-size: $font-size-menu;
        font-family: "NewRailAlphabet-light" !important;
        color: $text-black;
        text-align: right;
        opacity: 1;
        @include mobiletablet {
          font-size: $font-size-menu-mob;
          text-align: left;
        }
      }
      &:-moz-placeholder {
        font-size: $font-size-menu;
        font-family: "NewRailAlphabet-light" !important;
        color: $text-black;
        text-align: right;
        opacity: 1;
        @include mobiletablet {
          font-size: $font-size-menu-mob;
          text-align: left;
        }
      }
      &::-moz-placeholder {
        font-size: $font-size-menu;
        font-family: "NewRailAlphabet-light" !important;
        color: $text-black;
        text-align: right;
        opacity: 1;
        @include mobiletablet {
          font-size: $font-size-menu-mob;
          text-align: left;
        }
      }

      &:-ms-input-placeholder {
        font-size: $font-size-menu;
        font-family: "NewRailAlphabet-light" !important;
        color: $text-black;
        text-align: right;
        opacity: 1;
        @include mobiletablet {
          font-size: $font-size-menu-mob;
          text-align: left;
        }
      }

      &::placeholder {
        font-size: $font-size-menu;
        font-family: "NewRailAlphabet-light" !important;
        color: $text-black;
        text-align: right;
        opacity: 1;
        @include mobiletablet {
          font-size: $font-size-menu-mob;
          text-align: left;
        }
      }
      &[type="search"]::-webkit-search-decoration,
      &[type="search"]::-webkit-search-cancel-button,
      &[type="search"]::-webkit-search-results-button,
      &[type="search"]::-webkit-search-results-decoration {
        display: none;
      }

      &:not(:placeholder-shown), &:focus , &.focus {
        width: 100%;
        border-bottom: 1px solid $border-black;
        outline: 0;

        @include mobiletablet {
          width: 100%;
        }
      }
    }
    button {
      position: relative;
      z-index: 2;
      left: 20px;
      background: transparent;
      order: 1;
      border: 0;
      height: 20px;
      width: 20px;
      cursor: pointer;
      background-image: url("~@/assets/tapwell/img/search.svg");
      background-repeat: no-repeat;
      background-size: cover;

      @include mobiletablet {
        position: absolute;
        bottom: 4px;
        left: auto;
        right: 0;
      }
      &:focus {
        outline: 0;
      }
      &:after {
        // content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 20px;
        width: 20px;
        background-image: url("~@/assets/tapwell/img/search.svg");

        @include mobiletablet {
          top: -1px;
          height: 14px;
          width: 14px;
        }
      }
    }
    > div {
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      margin-bottom: 20px;
      position: relative;
      @include mobiletablet {
        margin-bottom: 0;
        margin-top: -5px;
        position: relative;
      }
    }
  }
}
