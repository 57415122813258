.product-offer {
    margin: 0 0 calc(#{$line-margin} * 20);
    @include mobiletablet {
      margin: 0 0 calc(#{$line-margin} * 8);
    }
    &__heading {
      h3 {
        font-size: $font-size-heading;
        color: $text-green;
        font-family: $font-bold;
        margin: 0;
        line-height: 1;
        @include mobiletablet {
          font-size: $font-size-heading-mob;
        }
      }
    }
    &__intro {
      padding-left: calc(12.4% + #{$column-margin});
      @include mobiletablet {
        padding-left: $column-margin;
        word-break: break-all
      }
      h3 {
        font-size: $font-size-heading;
        color: $text-black;
        font-family: $font-bold;
        margin: 0;
        line-height: 1;
        @include mobiletablet {
          font-size: $font-size-heading-mob;
        }
      }
    }
    &__product-list {
      justify-content: flex-start;
    }
    &__product {
     margin: $column-gap 0 calc(#{$column-gap + 8px});
     @include mobiletablet {
      margin: $column-gap 0 0;
    }
      > div {
        height: 100%;
        position: relative;
      }
    }
    &__img {
      height: 250px;
      display: block;
      background-color: $background-light-grey;
      padding: $line-margin-half 3em;
      div {
        background-size: contain;
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
}