.order-detail {

  .banner {
    margin-bottom: calc(#{$line-margin} * 13);
    margin-top: -50px;

    @include mobiletablet {
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
    }

    &__text {
      position: relative;
      top: 0;
      h1 {
        color: $text-black;
        @include mobiletablet {
          padding-right: 35px;
        }
      }
      @include desktop {
        left: auto;
        * {
          margin-left: calc(-35% + 15px) !important;
        }
       
      }
      @include mobiletablet {
        top: 0;
        width: 100%;
        padding: 0;
        max-width: 470px;
      }
    }
    &__back {
      position: absolute;
      top: 0;
      right: $column-gap;
    }
  }
    &__footer {
        .order-detail {
            &__delivery {
                list-style: none;
                margin-top: $line-margin;
                padding: $line-margin 0;
                border-top: 1px solid $border-black;
                display: flex;
                flex-flow: row;
                > li {
                    
                    &:first-child {
                        margin: 0;
                        width: 50%;
                        @include mobiletablet {
                          width: 100%;
                        }
                    }
                    &:last-child {
                        width: calc(50% - #{$column-margin});
                        margin: 0;
                        margin-left: $column-margin;
                        @include mobiletablet {
                          margin-right: 0;
                          text-align: right;
                        }
                    }
                }
                ul {
                    padding: 0;
                    list-style: none;
                    li {
                        margin: 0;
                        margin-bottom: calc(#{$line-margin} * 2);
                    }
                }
            }
            &__variant {
              &:before {
                @include mobiletablet {
                  border-top: 0;
                }
              }
              &--about {
                @include desktop {
                  > div {
                    margin-left: calc(-35% - 60px);
                  }
                
                }
                @include mobiletablet {
                  order: 2;
                  > div {
                   &:first-child {
                     width: 100%;
                     margin-right: 0;
                   }
                  }
                
                }
              }
              &--total-price {
                @include mobiletablet {
                  order: 1;
                  
                  &:before {
                    border-top: 1px solid $border-black !important;
                  }
                }
              
              }
            }
        }
    }
    &__list {
      top: calc(#{$line-margin} * 8);
      @include mobiletablet {
        top: $line-margin;
        margin-top: calc(#{$line-margin} * 5);
      }
      > div {
        &:first-child {
          .row {
            .order-detail {
              &__variant {
                &:first-child {
                  &:before {
                    border-top: 1px solid $border-black;
                    width: 100%;
                    @include mobiletablet {
                      border-top: 0;
                    }
                  }
                  h3 {
                    font-size: $font-size-default;
                  }
                 
                }
                &--info {
                  @include mobiletablet {
                    display: block;
                  }
                }
                
              }
            }
          }
        }
      }
      > div:nth-child(2) .row .order-detail__variant--info {
        @include mobiletablet {
          display: block;
        }
        &:first-child {
          &:before {
            width: 100%;
          }
        }
   
        &:before {
          @include mobiletablet {
            display: none;
          }
       
        }
        h3 {
          font-size: $font-size-default;
        }
    }
    .order-detail__status-icon {
      top: 0;
    }
    .order-detailt__stock {
      border: 0;
    }
    > div:last-of-type .row .order-detail__variant:after {
      content: "";
      border-bottom: 1px solid $border-black;
      width: 100%;
      left: 0;
      bottom: 0;
      position: absolute;
      @include mobiletablet {
        display: none;
      }
    }
     > div:last-of-type .row .order-detail__variant:last-child:after {
      width: calc(100% - #{$column-margin});
     }
     > div:last-of-type .row .order-detail__variant:first-child:after {
      width: calc(50% - #{$column-margin});
      left: auto;
      right: 0;
  }
    }
    &__header {
      h3 {
        margin: 0;
        font-size: $font-size-default;
        font-family: "NewRailAlphabet-light";
        font-weight: normal;
      }
      .checkout {
        &__variant {
          &:before {
            display: none;
          }
        }
      }
    }
    &__status {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin: 0;
      @include mobiletablet {
        flex-flow: wrap;
        p {
          width: 100%;
        }
      }
    }
    &__stockstatus-icon {
      width: 20px;
      margin-top: -7px;
      @include mobiletablet {
        display: flex;
        justify-content: flex-end;
        padding-top: 0;
      }
  
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 50%;
        background-color: $text-red;
        @include mobiletablet {
          margin-top: $line-margin;
        }
      }
      &--true {
        &:before {
          background-color: $text-green;
        }
      }
    }
    &__button {
        &:after {
          @include mobiletablet {
            content: "\2190";
              font-size: $font-size-heading-mob;
              font-family: "NewRailAlphabet-medium";
          }
        }
    }
    &__variant {
      padding-top: $line-margin;
      padding-bottom: $line-margin;
      position: relative;
      p {
        margin: 0;
        font-family: "NewRailAlphabet-light";
        word-break: break-word;
      }

      &--info {
        display: flex;
        flex-flow: row;
        @include mobiletablet {
          display: none;
        }
      }
      &--about {
      
        @include mobiletablet {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          > div {
            &:first-child {
              margin-right: $column-margin;
              width: calc(50% - #{$column-margin});
           
            }
          }
        }
      }
      &--a-price {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        > * {
          width: 28%;
        }
        span {
          &.strike {
            text-decoration: line-through;
          }
         
        }
        ul {
          list-style: none;
          padding-left: 0;
          margin: 0;
          li {
            white-space: nowrap;
          }
        }
      }
     
      &--total-price {
          > div {
            flex-flow: row;
            display: flex;
            justify-content: space-between;
            margin-left: 35%;
            @include mobiletablet {
              margin-left: 0;
            }
          }
          p {
              font-size: $font-size-menu;
              @include mobiletablet {
                  font-size: $font-size-menu-mob;
              }
          }
     
      }
      &--price {
        text-align: right;
        p {
            font-size: $font-size-menu;
            font-family: $font-bold;
          @include mobiletablet {
            font-size: $font-size-menu-mob;
            text-align: right;
          }
        }
        @include mobiletablet {
          padding-bottom: calc(#{$line-margin} * 2);
        }
        
      }
      &:before {
        content: "";
        border-top: 1px solid $border-black;
        display: block;
        width: 100%;
        left: $column-margin;
        top: 0;
        position: absolute;
        @include mobiletablet {
          width: calc(100% - #{$column-gap}) !important;
          margin-bottom: $column-margin;
        }
      }
      &:first-child {
        &:before {
          width: 50%;
          left: auto;
          right: -15px;
          @include mobiletablet {
            right: 15px;
            width: calc(100% - #{$column-gap}) !important;
          }
        }
      }
      &:last-child {
        &:before {
          width: calc(100% - #{$column-gap});
          @include mobiletablet {
            border-top: 0;
          }
        }
      }
    }
    &__img-container {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      
    }
  
    &__info {
      width: calc(50% - #{$column-margin});
      margin: 0 $column-margin 0 0;
      @include mobiletablet {
        width: calc(60% - #{$column-margin});
      }
      h3 {
         font-family: "NewRailAlphabet-light";
         margin: 0;
         font-weight: normal;
         font-size: 0;
      }
    }
    &__variant-name {
      margin: 0;
      font-size: $font-size-default;
      font-family: $font-bold;
      display: flex;
      flex-flow: row wrap;
      -webkit-box-pack: justify;
      justify-content: space-between;
      @include mobiletablet {
        font-size: $font-size-default-mob;
        flex-flow: column;
      }
      p {
        display: flex;
        flex-flow: row;
        font-weight: normal;
      }
    }
    &__img {
      background-color:  $background-light-grey;
      width: calc(50% - #{$column-margin});
      height: 150px;
      margin-right: 0;
      margin-left: 15px;
      padding: 5px 1em;
      @include mobile {
        width: calc(40% - #{$column-margin});
        height: 124px;
        display: block;
      }
      @include tablet {
        width: calc(20% - #{$column-margin});
        height: 124px;
        display: block;
      }
      > div {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 100%;
        width: auto;
        
  
      }
    }
 
    &__variant-description {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-flow: row wrap;
      margin: 0;
      padding-bottom: $line-margin-half;
  
    
  
      li {
        margin: 0 $line-margin-half 0 0;
        line-height: $line-height;
        width: 100%;
        &.active {
          font-family: $font-bold;
        }
        a {
          white-space: nowrap;
          cursor: pointer;
        
        }
      }
      li.hide-desktop {
        display: flex;
        flex-flow: row wrap;
        ul {
          padding-left: 5px;
          list-style: none;
        }
      } 
    }
    .delivery {
      &__column {
        &--3 {
          @include desktop {
            max-width: 61%;
            min-width: 61%;
            border-top: 1px solid $border-black;
          }
          @include mobiletablet {
            min-width: calc(100%);
            max-width: calc(100%);
            padding-left: 0;
            order: 3;
          }
       
        }
      }
    }

    &__btn {
      margin-top: calc(#{$line-margin} * 4);
      display: block;
      width: calc(100% - 7px);
      cursor: pointer;
      @include mobiletablet {
        width: calc(100% - (#{$column-gap} + 7px));
        margin: calc(#{$line-margin} * 2) $column-margin 0;
        &:hover {
          width: calc(100% - (#{$column-gap} + 7px));
        }
      }
    }
  
    .delivery .form-group span {
      color: $text-black;
    }
 
  }
  